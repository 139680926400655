import React, { Component, Link, useState } from 'react';
// import { SRLWrapper } from "simple-react-lightbox";
import NavbarST from '../../components/NavST'
import axios from 'axios';
// import ReactPaginate from "react-paginate";

class Upload extends Component {
	state = {
		filename: "",
		script: "",
		responseText: ""
	}
	
	changeHandler = (e) => {
		let val = e.target.value;
		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		this.setState(stateObject);
	}

	submitHandler = async(e) => {
		e.preventDefault();
		console.log(this.state)
		let api_key = process.env.REACT_APP_STESTING_API_KEY;
		let api_obj = this.state
		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		const url = 'https://15dkkchofb.execute-api.us-east-2.amazonaws.com/Prod/s3-upload';
		
		const apiData = await axios.post(url, api_obj, axiosConfig)
      	.then((data) => 
			// console.log(data)
			data['data']
			 
		).then((apiData) => 			
			// console.log(apiData)
			this.setState({responseText: apiData})
		);
	}
	
	render() {
		const {filename, script, responseText} = this.state
		
		return(
			<div className="stest-upload">
			
				<NavbarST />
				<h3>Upload File</h3>
				
				<form onSubmit={this.submitHandler} className="container">

					  <div className="form-group">
						<label>File Name</label>
						<input type="text" name="filename" className="form-control" id="filename" placeholder="S3 file name" value={filename} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Test Script</label>
						<textarea name="script" className="form-control" id="script" placeholder="put your code here" rows="20" value={script} onChange={this.changeHandler}></textarea>
					  </div>					  
					
					{<button type="submit" className="btn btn-primary">Submit</button>}

				</form>						
				{responseText && <p className="alert alert-success"> Response: {this.state.responseText} </p> }
			</div>
		);
	}
}

export default Upload;
