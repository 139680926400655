import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class CreatePerm extends Component {
	state = {
			group: "",
			customer: '',
			version: '',
			lbRegion: '',
			cloudRegion: '',
			cloudProvider: '',
			tableName: "perm_launches",
			number: "",
			serverType: "",
			image: "",
			environment: 'dev',
			submit: false
	}
	
	changeHandler = (e) => {

		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		//console.log(e.target.name);
		this.setState(stateObject);

	}
	
	submitHandler = (e) => {
		e.preventDefault();
		
		//console.log(this.state)
		this.setState({
			submit: true
		})
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/create-tabledata';
		const url = api_url + "create-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/create-tabledata';
		//axios.post(url, this.state)
		axios.post(url, this.state, axiosConfig)
		.then(response => {

			response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: response['data']['data'] });
			
			
		})
		
		this.setState({
			group: "",
			customer: '',
			version: '',
			lbRegion: '',
			cloudRegion: '',
			cloudProvider: '',
			tableName: "perm_launches",
			number: "",
			image: "",
			environment: 'dev',
			submit: false
		})
		
	}
	
	render() {
		const {customer, version, group, lbRegion, cloudRegion, cloudProvider, number, serverType, image, environment} = this.state
		return(
			<div className="pageBody">
				<Navbar />
				<h3>Create new entry for the Perm Schedule</h3>

				<form onSubmit={this.submitHandler} className="container">
				  
				  	  <div className="form-group">
						<label>Customer</label>
						<select name="customer" className="form-control" id="customer"  value={customer} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose customer</option>
							<option>blindsidenetworks</option>
						</select>
					  </div>
					  
					  <div className="form-group">
						<label>Version</label>
						<select name="version" className="form-control" id="version"  value={version} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose BBB-build version</option>
							<option>2_6</option>
							<option>2_7</option>
							<option>3_0</option>
						</select>
					  </div>
					  
					  <div className="form-group">
						<label>Region</label>
						<select name="lbRegion" className="form-control" id="lbRegion"  value={lbRegion} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose LB</option>
							<option>rna1</option>
							<option>reu1</option>
							<option>roc2</option>
							<option>rna2</option>
						</select>
					  </div>
					  
					  <div className="form-group">
						<label>Cloud Provider</label>
						<select name="cloudProvider" className="form-control" id="cloudProvider"  value={cloudProvider} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose cloud provider</option>
							<option>do</option>
							<option>aws</option>
						</select>
					  </div>
					  
					  {this.state.lbRegion === "rna1" && this.state.cloudProvider === "do" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>nyc3</option>
							<option>nyc1</option>
							<option>sfo1</option>
							<option>sfo2</option>
							<option>sfo3</option>
							<option>tor1</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "rna1" && this.state.cloudProvider === "aws" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>us-east-2</option>
							<option>us-west-2</option>
						</select>
					  </div>}

					  {this.state.lbRegion === "reu1" && this.state.cloudProvider === "do" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>ams3</option>
							<option>fra1</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "reu1" && this.state.cloudProvider === "aws" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>eu-west-2</option>
							<option>eu-central-1</option>
						</select>
					  </div>}				  

					  {this.state.lbRegion === "roc2" && this.state.cloudProvider === "do" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>sgp1</option>
							<option>blr1</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "roc2" && this.state.cloudProvider === "aws" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>ap-southeast-1</option>
							<option>ap-southeast-2</option>
						</select>
					  </div>}

					  {this.state.lbRegion === "rna2" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose cloud region</option>
							<option>tor1</option>
						</select>
					  </div>}

					  {this.state.lbRegion === "rna2" && this.state.cloudProvider === "aws" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>ca-central-1</option>
						</select>
					  </div>}

					  <div className="form-group">
						<label>Group</label>
						<input type="text" name="group" className="form-control" id="group" placeholder="Group name" value={group} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Number</label>
						<input type="text" name="number" className="form-control" id="number" placeholder="Number" value={number} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Server type</label>
						<select name="serverType" className="form-control" id="serverType"  value={serverType} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose server type</option>
							<option>perm</option>
							<option>whitelistperm</option>
						</select>
					  </div>
					  
					  <div className="form-group">
						<label>Image</label>
						<input type="text" name="image" className="form-control" id="image" placeholder="Image name if using latest leave blank" value={image} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Environment</label>
						<select name="environment" className="form-control" id="environment"  value={environment} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>Choose env to launch in?</option>
							<option>dev</option>
							<option>prod</option>
						</select>
					  </div>

					{customer && version && group && lbRegion && cloudRegion && cloudProvider && number && serverType && <button type="submit" className="btn btn-primary">Submit</button>}

				</form>
				
				{this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
			</div>
		);
	}
}

export default CreatePerm;
