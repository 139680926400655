import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class ZabbixDisabled extends Component {
	
	state = {
		api_data: []
	}

	async componentDidMount() {
		var tableObj = {tableName: "zabbix_disables"}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/get-tabledata';
		const url = api_url + "get-tabledata"
    	//const apiData = await axios.post(url, tableObj)
		const apiData = await axios.post(url, tableObj, axiosConfig)
      	.then((data) =>
			//console.log(data['data']['body'])	
			//JSON.parse(data['data']['body'])
			data['data']
		);
		this.setState({ api_data: apiData})
	}


	deleteEntry(hostname, ipv4, id) {
		var tableObj = {tableName: "zabbix_disables", id: id}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/delete-tabledata';
		const url = api_url + "delete-tabledata"
		axios.post(url, tableObj, axiosConfig)
      	.then(response => {
			console.log(response)	
			
			response['status'] != 200 ? this.setState({ response: false,responseText: response['status'].toString() }) : this.setState({ response: true, responseText: response['status'].toString() });
			
			var index = this.state.api_data.findIndex(x => x.id === id);
			  
			if (index >= 0) {
			  var array = this.state.api_data.splice(index, 1);
			  this.setState({api_data: this.state.api_data})
			}
		});

		//var zbx_job_url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/spinner-serverless';
		var zbx_job_url = api_url + "spinner-serverless"
			
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/moveall-servers';
		
		//axios.post(url, {data: serverArray, group: this.state.group})
		axios.post(zbx_job_url, {"job": "zabbix_delete", "hostname": hostname, "ipv4": ipv4, "bn_email": this.state.bnEmail}, axiosConfig)
		.then(response => {
			console.log('printing your response')
			console.log(response)
			//response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: response['data']['body'] });
			response['status'] != 200 ? this.setState({ response: false,responseText: response['status'].toString() }) : this.setState({ response: true, responseText: response['status'].toString() });


		})
	}

	render() {
		
		return (
		
			<div className="zabbix_failed">
				<Navbar />
				<h4> Zabbix Disabled Servers</h4>
				<table className="table table-striped table-hover table-gap container">
				  <thead className="table-dark">
					<tr>
				      <th scope="col">Hostname</th>
				      <th scope="col">Ipv4</th>
				      <th scope="col">Updated at</th>
					  <th scope="col">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (<tr key={entry.server_id}>
									<td className="col tr">{entry.hostname}</td>
									<td className="col tr">{entry.ipv4}</td>
									<td className="col tr">{entry.updated_at}</td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.hostname, entry.ipv4, entry.id)};}}>Delete</button> </td>
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
			
			</div>)

	}
}

export default ZabbixDisabled;