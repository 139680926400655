import React from "react";
import Navbar from '../components/Nav'
import MoveUserSchedule from '../components/MoveUserSchedule'

const MoveUserSchedulePage = (props) => {
	return(
		<div className="pageBody">
			<Navbar />
			
			<div className="container">
				<MoveUserSchedule bnEmail={props.bnEmail}/>
			</div>
			
			
		</div>
	)
}

export default MoveUserSchedulePage;