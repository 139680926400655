import React, { Component } from 'react';
import axios from 'axios';
import {
  Link,
} from "react-router-dom";

class AutoscaleSchedule extends Component {
	state = {
		api_data: []
	}
	
	componentWillMount() {
		
	}
	
	async componentDidMount() {
		var tableObj = {tableName: "autoscales"}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/get-tabledata';
		const url = api_url + "get-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/get-tabledata';
    	//const apiData = await axios.post(url, tableObj)
		const apiData = await axios.post(url, tableObj, axiosConfig)
      	.then((data) =>
			//console.log(data['data']['body'])	
			//JSON.parse(data['data']['body'])
			data['data']
		);
		
		this.setState({ api_data: apiData})
	}

	deleteEntry(id) {
		var deleteObj = {
			tableName: "autoscales",
			id: id
		}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}
		const url = api_url + "delete-tabledata"
		axios.post(url, deleteObj, axiosConfig)
		.then(response => {		
			response['status'] != 200 ? this.setState({ response: false,responseText: response['status'] }) : this.setState({ response: true, responseText: response['data']['data'] });
			var index = this.state.api_data.findIndex(x => x.id === id);
			  
			if (index >= 0) {
			  var array = this.state.api_data.splice(index, 1);
			  this.setState({api_data: this.state.api_data})
			}			
		})
	}
	
    render() {
		
        return(
            <div className="autoscale_schedule">
            	<h3>Autoscale Schedule</h3>
            	<a href="/create-autoscale"><button type="button" className="btn btn-dark center-block dd-create">Create new entry</button></a>
            	<h4 className="text-left">Rna2</h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				      <th scope="col">Id</th>
				      <th scope="col">Customer</th>
				  	  <th scope="col">Version</th>
					  <th scope="col">Region</th>
					  <th scope="col">Cloud Region</th>
					  <th scope="col">Cloud Provider</th>
					  <th scope="col">Image</th>
					  <th scope="col">Group</th>
					  <th scope="col">Cap</th>
					  <th scope="col">Min Limit</th>
					  <th scope="col">Scaleup Threshold</th>
					  <th scope="col">Scaledown Threshold</th>
					  <th scope="col">Scaleup Servers</th>
					  
					  <th scope="col" colSpan="2">Actions</th>
					</tr>
				  </thead>
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.lb_region === 'rna2' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.customer}</td>
									<td className="col tr">{entry.version}</td>
									<td className="col tr">{entry.lb_region}</td>
									<td className="col tr">{entry.cloud_region}</td>
									<td className="col tr">{entry.cloud_provider}</td>
									<td className="col tr">{entry.image ? entry.image:"nil"}</td>
									<td className="col tr">{entry.prod_group}</td>
									<td className="col tr">{entry.cap}</td>
									<td className="col tr">{entry.min_limit}</td>
									<td className="col tr">{entry.autoscale_threshold}</td>
									<td className="col tr">{entry.downscale_threshold}</td>
									<td className="col tr">{entry.scaleup_numbers}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-autoscale",
												state: {id: entry.id,
														customer: entry.customer,
														version: entry.version,
														prodGroup: entry.prod_group,
														lbRegion: entry.lb_region,
														cloudRegion: entry.cloud_region,
														cloudProvider: entry.cloud_provider,
														cap: entry.cap,
														minLimit: entry.min_limit,
														autoscaleThreshold: entry.autoscale_threshold,
														downscaleThreshold: entry.downscale_threshold,
														scaleupNumbers: entry.scaleup_numbers,
														image: entry.image,
														tableName: "autoscale"}
										    }}

								 >Edit</Link> </button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
           	
           		
           		
           		<h4 className="text-left">Reu1</h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				      <th scope="col">Id</th>
				      <th scope="col">Customer</th>
				  	  <th scope="col">Version</th>
					  <th scope="col">Region</th>
					  <th scope="col">Cloud Region</th>
					  <th scope="col">Cloud Provider</th>
					  <th scope="col">Image</th>
					  <th scope="col">Group</th>
					  <th scope="col">Cap</th>
					  <th scope="col">Min Limit</th>
					  <th scope="col">Scaleup Threshold</th>
					  <th scope="col">Scaledown Threshold</th>
					  <th scope="col">Scaleup Servers</th>
					  
					  <th scope="col" colSpan="2">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.lb_region === 'reu1' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.customer}</td>
									<td className="col tr">{entry.version}</td>
									<td className="col tr">{entry.lb_region}</td>
									<td className="col tr">{entry.cloud_region}</td>
									<td className="col tr">{entry.cloud_provider}</td>
									<td className="col tr">{entry.image ? entry.image:"nil"}</td>
									<td className="col tr">{entry.prod_group}</td>
									<td className="col tr">{entry.cap}</td>
									<td className="col tr">{entry.min_limit}</td>
									<td className="col tr">{entry.autoscale_threshold}</td>
									<td className="col tr">{entry.downscale_threshold}</td>
									<td className="col tr">{entry.scaleup_numbers}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-autoscale",
												state: {id: entry.id,
														customer: entry.customer,
														version: entry.version,
														prodGroup: entry.prod_group,
														lbRegion: entry.lb_region,
														cloudRegion: entry.cloud_region,
														cloudProvider: entry.cloud_provider,
														cap: entry.cap,
														minLimit: entry.min_limit,
														image: entry.image,
														autoscaleThreshold: entry.autoscale_threshold,
														downscaleThreshold: entry.downscale_threshold,
														scaleupNumbers: entry.scaleup_numbers,
														tableName: "autoscale"}
										    }}

								 >Edit</Link> </button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>

           		<h4 className="text-left">Roc2</h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				      <th scope="col">Id</th>
				      <th scope="col">Customer</th>
				  	  <th scope="col">Version</th>
					  <th scope="col">Region</th>
					  <th scope="col">Cloud Region</th>
					  <th scope="col">Cloud Provider</th>
					  <th scope="col">Image</th>
					  <th scope="col">Group</th>
					  <th scope="col">Cap</th>
					  <th scope="col">Min Limit</th>
					  <th scope="col">Scaleup Threshold</th>
					  <th scope="col">Scaledown Threshold</th>
					  <th scope="col">Scaleup Servers</th>
					  
					  <th scope="col" colSpan="2">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.lb_region === 'roc2' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.customer}</td>
									<td className="col tr">{entry.version}</td>
									<td className="col tr">{entry.lb_region}</td>
									<td className="col tr">{entry.cloud_region}</td>
									<td className="col tr">{entry.cloud_provider}</td>
									<td className="col tr">{entry.image ? entry.image:"nil"}</td>
									<td className="col tr">{entry.prod_group}</td>
									<td className="col tr">{entry.cap}</td>
									<td className="col tr">{entry.min_limit}</td>
									<td className="col tr">{entry.autoscale_threshold}</td>
									<td className="col tr">{entry.downscale_threshold}</td>
									<td className="col tr">{entry.scaleup_numbers}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-autoscale",
												state: {id: entry.id,
														customer: entry.customer,
														version: entry.version,
														prodGroup: entry.prod_group,
														lbRegion: entry.lb_region,
														cloudRegion: entry.cloud_region,
														cloudProvider: entry.cloud_provider,
														cap: entry.cap,
														minLimit: entry.min_limit,
														image: entry.image,
														autoscaleThreshold: entry.autoscale_threshold,
														downscaleThreshold: entry.downscale_threshold,
														scaleupNumbers: entry.scaleup_numbers,
														tableName: "autoscale"}
										    }}

								 >Edit</Link> </button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
            	<h4 className="text-left">Rna1</h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				      <th scope="col">Id</th>
				      <th scope="col">Customer</th>
				  	  <th scope="col">Version</th>
					  <th scope="col">Region</th>
					  <th scope="col">Cloud Region</th>
					  <th scope="col">Cloud Provider</th>
					  <th scope="col">Image</th>
					  <th scope="col">Group</th>
					  <th scope="col">Cap</th>
					  <th scope="col">Min Limit</th>
					  <th scope="col">Scaleup Threshold</th>
					  <th scope="col">Scaledown Threshold</th>
					  <th scope="col">Scaleup Servers</th>
					  
					  <th scope="col" colSpan="2">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.lb_region === 'rna1' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.customer}</td>
									<td className="col tr">{entry.version}</td>
									<td className="col tr">{entry.lb_region}</td>
									<td className="col tr">{entry.cloud_region}</td>
									<td className="col tr">{entry.cloud_provider}</td>
									<td className="col tr">{entry.image ? entry.image:"nil"}</td>
									<td className="col tr">{entry.prod_group}</td>
									<td className="col tr">{entry.cap}</td>
									<td className="col tr">{entry.min_limit}</td>
									<td className="col tr">{entry.autoscale_threshold}</td>
									<td className="col tr">{entry.downscale_threshold}</td>
									<td className="col tr">{entry.scaleup_numbers}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-autoscale",
												state: {id: entry.id,
														customer: entry.customer,
														version: entry.version,
														prodGroup: entry.prod_group,
														lbRegion: entry.lb_region,
														cloudRegion: entry.cloud_region,
														cloudProvider: entry.cloud_provider,
														cap: entry.cap,
														minLimit: entry.min_limit,
														image: entry.image,
														autoscaleThreshold: entry.autoscale_threshold,
														downscaleThreshold: entry.downscale_threshold,
														scaleupNumbers: entry.scaleup_numbers,
														tableName: "autoscale"}
										    }}

								 >Edit</Link> </button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>            	
            </div>
        );
    }
}

export default AutoscaleSchedule;
