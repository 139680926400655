import React, { Component, Link, useState } from 'react';
import NavbarRS from '../../components/NavRS'
import axios from 'axios';

class Elad extends Component {
	state = {
		api_data: [],
		filter: "",
		date: ""
	}
	
	async componentDidMount() {
		// e.preventDefault();
        console.log(this.state)
        let api_key = process.env.REACT_APP_ANALYTICS_CALLBACK_API_KEY;
        
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                "X-API-Key": api_key
            },
        }
        axios.defaults.headers.common = {
            "X-API-Key": api_key,
        }

        const url = 'https://kwzkakp229.execute-api.ca-central-1.amazonaws.com/Prod/failed-elad';

        const apiData = await axios.post(url, axiosConfig)
        .then((data) =>
            data['data']
            //JSON.parse(data['data']['body'])
         )//.then((responseData) => this.setState({ data: responseData }));
        this.setState({ api_data: apiData})
    }

    downloadTxtFile = () => {
    	let api_data = [] //JSON.stringify(this.state.api_data)
    	this.state.api_data && this.state.api_data.filter(name => name.bn_userid.includes(this.state.filter)).map((entry) => {
			api_data.push(entry.creation_date,
			entry.internal_meetingid,
			entry.hostname,
			entry.bn_userid,
			entry.external_meetingid,
			entry.meeting_name,
			entry.status,
			entry.step,
			'\r\n'
		)})
    	
	    const file = new Blob([api_data], {type: 'text/plain'});
	    const element = document.createElement("a");
	    element.href = URL.createObjectURL(file);
	    element.download = "analytics-callback-failed-" + Date.now() + ".txt";
	    document.body.appendChild(element);
	    // Required for this to work in FireFox
	    element.click();
	}

	changeHandler = (e) => {
		let val = e.target.value;
		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		this.setState(stateObject);
	}

	dateHandler = async() => {
	    let api_key = process.env.REACT_APP_ANALYTICS_CALLBACK_API_KEY;
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                "X-API-Key": api_key
            },
        }

        axios.defaults.headers.common = {
            "X-API-Key": api_key,
        }
        
        const url = 'https://kwzkakp229.execute-api.ca-central-1.amazonaws.com/Prod/failed-elad';
        
	    let apiObj = {
	        date: this.state.date
	    }

	    const apiData = await axios.post(url, apiObj, axiosConfig)
	    .then((data) =>
	        data['data']
	    )
	    this.setState({ api_data: apiData})
	}

	render() {
		const {filter, date} = this.state
		return(
			<div className="analytics-callback">
			
				<NavbarRS />
				<div className="container">
					<h3>ELAD Failed Data</h3>
					<h6> Failed meetings - {this.state.api_data.length}</h6>
	                <div className="float-left">
		                <p></p>
						<div className="btnDiv">
						     <button className="btn btn-primary" id="downloadBtn" onClick={this.downloadTxtFile} value="download">Download File</button>
						</div>
						<label>Start date: &nbsp;</label>
						<input type="date" name="date" value={date} onChange={(e) => this.changeHandler(e)} /> &nbsp;
						<button className="btn btn-primary" id="downloadBtn" onClick={this.dateHandler} value="submit">submit</button>
						<p></p>
		                <label>Filter by customer: &nbsp;</label>
						<input type="text" name="filter" value={filter} onChange={(e) => this.changeHandler(e)} />
					</div>
	                
	                <table className="table table-striped table-hover table-gap container">
					  <thead className="table-dark">
						<tr>
					      <th className="date" scope="col">Meeting Date (UTC)</th>
					      <th scope="col">Customer </th>
					      <th scope="col">External MeetingId<br/>
									      Meeting Name<br/>
									      Internal MeetingId
					      </th>
						  <th scope="col">Hostname</th>
						  <th scope="col">ELAD Status</th>
						  <th scope="col">Step</th>						
						</tr>
					  </thead>
					  
					  <tbody>		
					
						{this.state.api_data && this.state.api_data.filter(name => name.bn_userid.includes(this.state.filter)).map((entry) => {
							
							return ( <tr key={entry.id}>
										<td className="col tr date">{entry.creation_date}</td>
										<td className="col tr">{entry.bn_userid}</td>
										<td className="col tr">{entry.external_meetingid}<br/>
																{entry.meeting_name}<br/>
																{entry.internal_meetingid}
										</td>
										<td className="col tr">{entry.hostname}</td>
										<td className="col tr">{entry.elad_status}</td>
										<td className="col tr">{entry.step}</td>
								   </tr>)		
							 
						})}
						
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
export default Elad;
