import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';
const AWS = require('aws-sdk');

class CreateImagePage extends Component {
	
	componentDidMount() {
		
	}
	
	state = {
		submit: false,
		//hostnameCheck: false,
		responseText: '',
		response: '',
		job: "create_image",
		imageName: '',
		baseServer: '',
		cloudProvider: '',
		cloudRegions: [],
		bnEmail: this.props.bnEmail,
		imageDescription: '',
		awsRegion: '',
		launchTemplate: 'general_2_7'
	}
	
	changeHandler = (e) => {

		var stateObject = {};
		stateObject[e.target.name] = e.target.value;
		this.setState(stateObject);
	}
	
	addRegion = (e) => {
		let newelement = e.target.value;
		if (this.state.cloudRegions.indexOf(e.target.value) === -1) {
		  this.setState({
		  	cloudRegions: [...this.state.cloudRegions, newelement]
		  })
		}
		else {
		  this.setState({cloudRegions: this.state.cloudRegions.filter(function(r) { 
			return r !== newelement
		  })});
		}
	}

	submitHandler = async(e) => {
		e.preventDefault();
		
		if(this.state.cloudProvider == "aws"){
		    let msg;
		    let self = this;	   
		    if (window.confirm(msg)) {
				
				self.setState({
					submit: true
				})

				let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
				let api_url = process.env.REACT_APP_WORKER_API_URL;

				let axiosConfig = {
				  headers: {
					  "Content-Type": "application/json",
					  "X-API-Key": api_key
				  },
				}
				axios.defaults.headers.common = {
				  "X-API-Key": api_key,
				}

				const url = api_url + "spinner-serverless"
				let obj;
				if(self.state.cloudProvider == "do") {
					obj = {"job": "create_image", "cloud_provider": self.state.cloudProvider, hostname: self.state.baseServer, image: self.state.imageName, cloud_regions: self.state.cloudRegions, "bn_email": self.state.bnEmail}
				} else if(self.state.cloudProvider == "aws") {
					obj = {"job": "create_image", "cloud_provider": self.state.cloudProvider, hostname: self.state.baseServer, image: self.state.imageName, image_description: self.state.imageDescription, aws_region: self.state.awsRegion, cloud_regions: self.state.cloudRegions, launch_template:  self.state.launchTemplate, "bn_email": self.state.bnEmail}
				}

				axios.post(url, obj, axiosConfig)
				.then(response => {
					console.log('printing your response')
					console.log(response)

					response['status'] != 200 ? self.setState({ response: false,responseText: response['status'].toString() }) : self.setState({ response: true, responseText: response['status'].toString() });


				})
				
				this.setState({
					responseText: '',
					response: '',
					job: "create_image",
					imageName: '',
					baseServer: '',
					cloudProvider: '',
					cloudRegions: [],
					imageDescription: '',
					awsRegion: '',
					launchTemplate: ''
				})

		  }
			
		} else if(this.state.cloudProvider == "do") {
			if (window.confirm("Confirm image creation?")) {
				
				this.setState({
					submit: true
				})

				let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
				let api_url = process.env.REACT_APP_WORKER_API_URL;

				let axiosConfig = {
				  headers: {
					  "Content-Type": "application/json",
					  "X-API-Key": api_key
				  },
				}
				axios.defaults.headers.common = {
				  "X-API-Key": api_key,
				}

				const url = api_url + "spinner-serverless"
				let obj;
				if(this.state.cloudProvider == "do") {
					obj = {"job": "create_image", "cloud_provider": this.state.cloudProvider, hostname: this.state.baseServer, image: this.state.imageName, cloud_regions: this.state.cloudRegions, "bn_email": this.state.bnEmail}
				} else if(this.state.cloudProvider == "aws") {
					obj = {"job": "create_image", "cloud_provider": this.state.cloudProvider, hostname: this.state.baseServer, image: this.state.imageName, image_description: this.state.imageDescription, aws_region: this.state.awsRegion, cloud_regions: this.state.cloudRegions, launch_template:  this.state.launchTemplate, "bn_email": this.state.bnEmail}
				}

				//axios.post(url, {data: serverArray, group: this.state.group})
				axios.post(url, obj, axiosConfig)
				.then(response => {
					console.log('printing your response')
					console.log(response)

					response['status'] != 200 ? this.setState({ response: false,responseText: response['status'].toString() }) : this.setState({ response: true, responseText: response['status'].toString() });


				})

				this.setState({
					responseText: '',
					response: '',
					job: "create_image",
					imageName: '',
					baseServer: '',
					cloudProvider: '',
					cloudRegions: [],
					imageDescription: '',
					awsRegion: '',
					launchTemplate: ''
				})
			}
		}
	}
		

	render() {
		
		const {cloudProvider, cloudRegions, imageName, baseServer, bnEmail, imageDescription, awsRegion, launchTemplate} = this.state
		return (
		
			<div>
				<Navbar bnEmail={bnEmail} />
				
				<form onSubmit={this.submitHandler} className="container">
					
				  <div className="form-group">
					<label>Cloud Provider</label>
					<select name="cloudProvider" className="form-control" id="customer"  value={cloudProvider} onChange={this.changeHandler}>
						<option value="" selected disabled hidden>choose cloud Provider</option>
						<option>do</option>
						<option>aws</option>
					</select>
				  </div>
				  
				  <div className="form-group">
					<label>Base Server Hostname</label>
					<input type="text" name="baseServer" className="form-control" id="baseServer" placeholder="Base Server Hostname to create image from" value={baseServer} onChange={this.changeHandler}></input>
					{/*this.state.hostnameCheck && <p className="text-success">Hostname found</p>}
					{!this.state.hostnameCheck && <p className="text-danger">Hostname not found</p>*/}
				  </div>
				  
				  {this.state.cloudProvider == "aws" && 
				  <div className="form-group">
					<label>Aws Region for base server</label>
					<select name="awsRegion" className="form-control" id="awsRegion"  value={awsRegion} onChange={this.changeHandler}>
						<option value="" selected disabled hidden>choose aws region of base server</option>
						<option>us-east-1</option>
						<option>us-east-2</option>
						<option>us-west-2</option>
						
						<option>eu-west-2</option>
						<option>eu-central-1</option>
						
						<option>ap-southeast-1</option>
						<option>ap-southeast-2</option>

						<option>ca-central-1</option>
					</select>
				  </div>}
				  
				  <div className="form-group">
					<label>Image name</label>
					<input type="text" name="imageName" className="form-control" id="imageName" placeholder="Image name to create" value={imageName} onChange={this.changeHandler}></input>
				  </div>
				  
				  {this.state.cloudProvider == "aws" && 
				  	<div className="form-group">
						<label>Image Description</label>
						<input type="text" name="imageDescription" className="form-control" id="imageDescription" placeholder="Image Description" value={imageDescription} onChange={this.changeHandler}></input>
					  </div>
				  }
				  
				  {this.state.cloudProvider == "aws" &&  <div className="form-group">
					<label>Launch Template</label>
					<input type="text" name="launchTemplate" className="form-control" id="launchTemplate" placeholder="general_2_4" value={launchTemplate} onChange={this.changeHandler}></input>
				  </div>
				  }
				  
					{this.state.cloudProvider == "do" && <div className="form-group">
					<label>Cloud Regions (Do not select region that base server is in): &nbsp;</label>
					
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox1 flexSwitchCheckDefault" value="nyc1" onChange={this.addRegion}></input>
					  <label className="form-check-label">nyc1</label>
					</div>
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox2 flexSwitchCheckDefault" value="nyc3" onChange={this.addRegion}></input>
					  <label className="form-check-label">nyc3</label>
					</div>
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox3 flexSwitchCheckDefault" value="sfo1" onChange={this.addRegion}></input>
					  <label className="form-check-label">sfo1</label>
					</div>
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox3 flexSwitchCheckDefault" value="sfo2" onChange={this.addRegion}></input>
					  <label className="form-check-label">sfo2</label>
					</div>
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox3 flexSwitchCheckDefault" value="sfo3" onChange={this.addRegion}></input>
					  <label className="form-check-label">sfo3</label>
					</div>
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox3 flexSwitchCheckDefault" value="tor1" onChange={this.addRegion}></input>
					  <label className="form-check-label">tor1</label>
					</div>
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox3 flexSwitchCheckDefault" value="sgp1" onChange={this.addRegion}></input>
					  <label className="form-check-label">sgp1</label>
					</div>										
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox3 flexSwitchCheckDefault" value="blr1" onChange={this.addRegion}></input>
					  <label className="form-check-label">blr1</label>
					</div>
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox3 flexSwitchCheckDefault" value="ams3" onChange={this.addRegion}></input>
					  <label className="form-check-label">ams3</label>
					</div>
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox3 flexSwitchCheckDefault" value="fra1" onChange={this.addRegion}></input>
					  <label className="form-check-label">fra1</label>
					</div>										
				  </div>}
				  
				  {this.state.cloudProvider == "aws" && 
				  	<div className="form-group">
					<label>Cloud Regions (Do not select region that base server is in): &nbsp;</label>
					
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox1 flexSwitchCheckDefault" value="us-east-1" onChange={this.addRegion}></input>
					  <label className="form-check-label">us-east-1</label>
					</div>
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox2 flexSwitchCheckDefault" value="us-east-2" onChange={this.addRegion}></input>
					  <label className="form-check-label">us-east-2</label>
					</div>
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox3 flexSwitchCheckDefault" value="us-west-2" onChange={this.addRegion}></input>
					  <label className="form-check-label">us-west-2</label>
					</div>
					
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox4 flexSwitchCheckDefault" value="eu-west-2" onChange={this.addRegion}></input>
					  <label className="form-check-label">eu-west-2</label>
					</div>
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox5 flexSwitchCheckDefault" value="eu-central-1" onChange={this.addRegion}></input>
					  <label className="form-check-label">eu-central-1</label>
					</div>
					
					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox6 flexSwitchCheckDefault" value="ap-southeast-1" onChange={this.addRegion}></input>
					  <label className="form-check-label">ap-southeast-1</label>
					</div>

					<div className="form-check form-check-inline form-switch">
					  <input className="form-check-input" type="checkbox" id="inlineCheckbox6 flexSwitchCheckDefault" value="ca-central-1" onChange={this.addRegion}></input>
					  <label className="form-check-label">ca-central-1</label>
					</div>
				  </div>
				  }
				  
				  {cloudProvider && baseServer && imageName && cloudRegions &&
				  <button type="submit" className="btn btn-primary">Create Image</button> }
				  	
				</form>
				
				{this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
				
			</div>)

	}
}

export default CreateImagePage;
