import React from "react";
import Navbar from '../components/Nav'
import PermSchedule from '../components/PermSchedule'

const PermSchedulePage = (props) => {
	return(
		<div className="pageBody">
			<Navbar />
			
			<div className="container">
				<PermSchedule />
			</div>
			
			
		</div>
	)
}

export default PermSchedulePage;