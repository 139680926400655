import React from 'react'

function NavbarRS(){
	return(
		<nav className="navbar navbar-expand-lg navbar-light bg-light">
		  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon"></span>
		  </button>
		  <div className="collapse navbar-collapse" id="navbarSupportedContent">
			<ul className="navbar-nav mr-auto">
			  <li className="nav-item">
				<a className="nav-link" href="/home">Spinner <span className="sr-only"></span></a>
			  </li>
			  
			  <li className="nav-item dropdown">
				<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				  Analytics Callback
				</a>
				<div className="dropdown-menu" aria-labelledby="navbarDropdown">
				  <a className="dropdown-item" href="/rs/analytics-callback">Failed Callback</a>
				  <a className="dropdown-item" href="/rs/analytics-query">Query Callback</a>				  
				  <a className="dropdown-item" href="/rs/retry">Retry Callback</a>
				</div>
			  </li>

			  <li className="nav-item dropdown">
				<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				  Elad
				</a>
				<div className="dropdown-menu" aria-labelledby="navbarDropdown">
				  <a className="dropdown-item" href="/rs/elad">Failed Data</a>
				  <a className="dropdown-item" href="/rs/elad-customers">Customers</a>
				</div>
			  </li>
			</ul>
		  </div>
		  
		  <li className="nav-item">
			<a className="nav-link" href="/help">Help</a>
		  </li>
		</nav>
	);
}

export default NavbarRS;
