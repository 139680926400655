import React, { Component } from 'react';
import NavbarRP from '../../components/NavBBBlogs'
import axios from 'axios';

class BBBLogs extends Component {
	state = {
		hostname: "",
		date: "",
		time: "",
		filter: "",
		response: "",
		responseText: []
	}
	
	changeHandler = (e) => {

		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		//console.log(e.target.name);
		this.setState(stateObject);

	}
	submitHandler = async(e) => {
		e.preventDefault();
		//console.log(this.state)
		let api_key = process.env.REACT_APP_BBBLOGS_API_KEY;
		let api_obj = this.state
		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		const url = 'https://6uu3a2j8tk.execute-api.us-west-2.amazonaws.com/prod/get-logs';
		
		const apiData = await axios.post(url, api_obj, axiosConfig)
      	.then((response) => {
      		return response['data']['body']['Items']
      		//response['data']['body']['Items']
			//response['data']['body'].hasOwnProperty('Items') ? this.setState({ response: true, responseText: response['data']['body']['Items']} ) : this.setState({ response: false, responseText: 'Error' });
			//this.setState({ response: true, responseText: response['data']['body']['Items']})
		}).then((data) => {
			//console.log(data);
			this.setState({ response: true, responseText: data})
		});
	}	
	
	
	render() {
		const {hostname, date, time, filter} = this.state
		return(
			<div className="rpRecId">
				<NavbarRP />
				<h3>Find by hostname &amp; timestamp</h3>

				<form onSubmit={this.submitHandler} className="container">

					<div className="form-group row">
						<label className="col-2 col-form-label">Hostname</label>
						<div className="col-10">
							<input type="text" name="hostname" className="form-control" id="hostname" placeholder="Enter hostname" value={hostname} onChange={this.changeHandler}></input>
						</div>
					</div>

					<div className="form-group row">
					  <label className="col-2 col-form-label">Date (Optional):</label>
					  <div className="col-10">
						  <input name="date" className="form-control" type="date" value={date} id="date" onChange={this.changeHandler}></input>
					  </div>
					</div>
					
					<div className="form-group row">
						<label className="col-2 col-form-label">Time (Optional):</label>
						<div className="col-10">
							<input type="text" name="time" className="form-control" id="time" placeholder="Enter UTC time to the accuracy you seek eg. 17:00:00 or 17:00 or 17" value={time} onChange={this.changeHandler}></input>
						</div>
					</div>

					<button type="submit" className="btn btn-primary">Submit</button>

				</form>
				{hostname}
				{this.state.responseText.length != 0 && <div><label>Filter by userID: &nbsp;</label>
				<input type="text" name="filter" value={filter} onChange={(e) => this.changeHandler(e)} /></div>}

				<div className="container">
					<table className="table table-striped table-hover table-gap">
					  <thead className="thead-dark">
						<tr>
						  <th scope="col">timestamp</th>
						  <th scope="col">user name</th>
						  <th scope="col">user id</th>
						  <th scope="col">mos</th>
						  <th scope="col">quality_percentage</th>
						</tr>
					  </thead>

					  <tbody>		
	
						{this.state.filter && this.state.responseText.filter(name => name.user_id ? name.user_id.includes(this.state.filter) : {"pk": name.pk}).map((entry, i) => {

							return(<tr key={entry.sk}>
								<td className="col tr">{entry.sk.split('#')[0]}</td>
								<td className="col tr">{entry.user_name}</td>
								<td className="col tr">{entry.user_id}</td>
								<td className="col tr">{entry.stats.audio ? entry.stats.audio.in_mos.toString() : "No stats found"}</td>
								<td className="col tr">{entry.stats.audio ? entry.stats.audio.in_quality_percentage.toString() : "No stats found"}</td>
							</tr>)

						})}					  

						{!this.state.filter && this.state.response && this.state.responseText.map((entry) => {
							//console.log(entry)
							return(<tr key={entry.sk}>
								<td className="col tr">{entry.sk.split('#')[0]}</td>
								<td className="col tr">{entry.user_name}</td>
								<td className="col tr">{entry.user_id}</td>
								<td className="col tr">{entry.stats.audio ? entry.stats.audio.in_mos.toString() : "No stats found"}</td>
								<td className="col tr">{entry.stats.audio ? entry.stats.audio.in_quality_percentage.toString() : "No stats found"}</td>
							</tr>)

						})}

						</tbody>
					</table>
				</div>
				
				
				
			</div>
		);
	}
}

export default BBBLogs;


						