import React from "react";
import Navbar from '../components/Nav'
import { Link } from 'react-router-dom'

const MainPage = (props) => {
	return(
		<div className="pageBody">
			<Navbar />
			
			<h3>Portals --- warp at your own risk 🧙‍♂️</h3>
			
			<div className="portal">
				
				<div className="row">
				
					<div className="col-2">
						<button type="button" className="btn btn-dark"><Link to="/rp/meetingid">Recording Process Portal</Link></button>
					</div>
					
					<div className="col-2">
						<button type="button" className="btn btn-dark"><Link to="/lighthouse/home">Lighthouse Portal</Link></button>
					</div>
					
					<div className="col-2">
						<button type="button" className="btn btn-dark"><Link to="/stesting/home">Stress Testing</Link></button>
					</div>
					
					<div className="col-2">
						<button type="button" className="btn btn-dark"><Link to="/bbb-logs/get-logs">BBB Logs</Link></button>
					</div>

					<div className="col-2">
						<button type="button" className="btn btn-dark"><Link to="/rs/analytics-callback">Rs-Inbox</Link></button>
					</div>				
				</div>

			</div>
		</div>
	)
}

export default MainPage;
