import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import { 
	BrowserRouter as Router, 
	Route, 
	Switch,  
	Redirect 
} from "react-router-dom";

/*SPINNER FRONTEND*/
import MainPage from "./pages/index.jsx";

import LaunchServerPage from "./pages/launch_server.jsx";
import MoveServerPage from "./pages/move_servers.jsx";
import DestroyServerPage from "./pages/destroy_servers.jsx";
import MoveUserPage from "./pages/move_users.jsx";

import SchedulePage from "./pages/schedule.jsx";
import CreateDailyDyn from "./pages/create-daily-dyn.jsx"
import UpdateDailyDyn from "./pages/UpdateDailyDyn.jsx"

import MoveSchedulePage from "./pages/move-schedule.jsx"
import CreateMoveDyn from "./pages/create-move-dyn.jsx"
import UpdateMoveDyn from "./pages/update-move-dyn.jsx"

import MoveUserSchedulePage from "./pages/move-user-schedule.jsx"
import CreateMoveUser from "./pages/create-move-user.jsx"
import UpdateMoveUser from "./pages/update-move-user.jsx"

import PermSchedulePage from "./pages/perm-schedule.jsx"
import CreatePerm from "./pages/create-perm.jsx"
import UpdatePerm from "./pages/update-perm.jsx"

import AutoscaleSchedulePage from "./pages/autoscale-schedule.jsx"
import CreateAutoscale from "./pages/create-autoscale.jsx"
import UpdateAutoscale from "./pages/update-autoscale.jsx"

import AsgSchedulePage from "./pages/asg-schedule.jsx"
import CreateAsg from "./pages/create-asg.jsx"
import UpdateAsg from "./pages/update-asg.jsx"

import OvhSchedulePage from "./pages/ovh-schedule.jsx";
import UpdateOvhMove from "./pages/UpdateOvhMove.jsx"
import CreateOvhSchedule from "./pages/create-ovh-schedule.jsx"


import AnalyticsSummaryPage from "./pages/analytics-summary.jsx"
import GroupAnalyticsSummaryPage from "./pages/group-analytics-summary.jsx"
import ServerAnalyticsSummaryPage from "./pages/server-analytics-summary.jsx"

import DailyCheckPage from "./pages/daily-check.jsx"
import CreateDailyCheck from "./pages/create-daily-check.jsx"
import UpdateDailyCheck from "./pages/update-daily-check.jsx"

import DanglingAwsSpinner from "./pages/dangling-aws-spinner.jsx"
import DanglingDOSpinner from "./pages/dangling-do-spinner.jsx"
import DanglingDOExtra from "./pages/dangling-do-extra.jsx"
import StaleServers from "./pages/stale-servers.jsx"
import ZabbixFailed from "./pages/zabbix-failed.jsx"
import ZabbixDisabled from "./pages/zabbix-disabled.jsx"

import ClearDns from "./pages/clear-dns.jsx"

/*HELP*/
import HelpPage from "./pages/help/help.jsx"
import NotFoundPage from "./pages/help/404.jsx";
import StatusPage from "./pages/help/status.jsx";

/*RECORDING PROCESS*/
import rpMeetingId from "./pages/recording/rp_recid.jsx"
import rpStatus from "./pages/recording/rp_status.jsx"

/*Lighthouse*/
import Lighthouse from "./pages/lighthouse/lighthouse.jsx"

/*Stress testing*/
import Stesting from "./pages/stest/stesting.jsx"
import LaunchTest from "./pages/stest/stest-launch.jsx"
import Upload from "./pages/stest/stest-upload.jsx"
import Logs from "./pages/stest/stest-logs.jsx"

/*BBB logs*/
import BBBLogs from "./pages/bbb-logs/get-logs.jsx"

/*Image Creation*/
import CreateImagePage from "./pages/create-image.jsx"

/*Rs-Inbox*/
import RsInbox from "./pages/rs-inbox/rs-inbox.jsx"
import AnalyticsCallback from "./pages/rs-inbox/analytics-callback.jsx"
import AnalyticsQuery from "./pages/rs-inbox/analytics-query.jsx"
import Elad from "./pages/rs-inbox/elad-failed-data.jsx"
import EladCustomers from "./pages/rs-inbox/get-elad-customers.jsx"
import CreateEladCustomers from "./pages/rs-inbox/create-elad-customer.jsx"
import EditEladCustomer from "./pages/rs-inbox/edit-elad-customer.jsx"

import { useEffect, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        'phone',
        'email',
        'openid'
      ],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN_URL,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT_URL,
      responseType: 'code',
    },
  },
});



class App extends Component { 
	state = {
    	isLogin: false,
    	email: ''
  	};

  componentDidMount(){
  	const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log("cognitouser == ",data.signInUserSession.idToken.payload.email);
      
      switch (event) {
        case "signIn":
          this.setState({
					isLogin: true,
					bnEmail: data.signInUserSession.idToken.payload.email
			})
			sessionStorage.setItem("isLogin", true);
			sessionStorage.setItem("email", data.signInUserSession.idToken.payload.email);
          break;
        case "signOut":
          // setUser(null);
        	this.setState({
				isLogin: true,
				bnEmail: ''
			})
			sessionStorage.setItem("isLogin", false);
          break;
        case "customOAuthState":
          // setCustomState(data);
        	this.setState({
				isLogin: true,
				bnEmail: ''
			})
			sessionStorage.setItem("isLogin", false);
      }
    });
  }

  componentWillMount() {
	let isLogin = sessionStorage.getItem("isLogin");
	let email = sessionStorage.getItem("email");

	if (sessionStorage.getItem("isLogin")) {
		this.setState({
			isLogin: isLogin,
			bnEmail: email
		})
	} else {
		this.setState({
			isLogin: false
		})
	}
  }

  render() {
  	if (this.state.isLogin){
  		return (
    	<div className="App">
		    	<Router>
		    		<Switch>
		        	    <Route exact path="/" component={() => <MainPage bnEmail={this.state.bnEmail} />} />
			        	<Route exact path="/home" component={() => <MainPage bnEmail={this.state.bnEmail} />} />
			        	<Route exact path="/status" component={() => <StatusPage bnEmail={this.state.bnEmail} />} />		  				
		  				<Route exact path="/launchserver" component={() => <LaunchServerPage bnEmail={this.state.bnEmail} />} />

		  				<Route exact path="/moveserver" component={() => <MoveServerPage bnEmail={this.state.bnEmail} />} />
		  				<Route exact path="/destroyserver" component={() => <DestroyServerPage bnEmail={this.state.bnEmail} />} />
		  				<Route exact path="/moveuser" component={() => <MoveUserPage bnEmail={this.state.bnEmail} />} />
		  				
		  				<Route exact path="/create-image" component={() => <CreateImagePage bnEmail={this.state.bnEmail} />} />
		  				
		  				<Route exact path="/404" component={() => <NotFoundPage bnEmail={this.state.bnEmail} />} />
		  				
		  				<Route exact path="/schedule" component={() => <SchedulePage bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/create-daily-dyn" component={() => <CreateDailyDyn bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/update-daily-dyn" component={UpdateDailyDyn} />
		  					
		  				<Route exact path="/moveschedule" component={() => <MoveSchedulePage bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/create-move-dyn" component={() => <CreateMoveDyn bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/update-move-dyn" component={UpdateMoveDyn} />
		  					
		  				<Route exact path="/moveuserschedule" component={() => <MoveUserSchedulePage bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/create-move-user" component={() => <CreateMoveUser bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/update-move-user" component={UpdateMoveUser} />
		  					
		  				<Route exact path="/permschedule" component={() => <PermSchedulePage bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/create-perm" component={() => <CreatePerm bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/update-perm" component={UpdatePerm} />
		  				
		  				<Route exact path="/autoscaleschedule" component={() => <AutoscaleSchedulePage bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/create-autoscale" component={() => <CreateAutoscale bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/update-autoscale" component={UpdateAutoscale} />

		  				<Route exact path="/ovhschedule" component={() => <OvhSchedulePage bnEmail={this.state.bnEmail} />} />
							<Route exact path="/create-ovh" component={CreateOvhSchedule} />
							<Route exact path="/update-ovh" component={UpdateOvhMove} />

		  				<Route exact path="/asgschedule" component={() => <AsgSchedulePage bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/create-asg" component={() => <CreateAsg bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/update-asg" component={UpdateAsg} />
		  					
		  				<Route exact path="/dailycheck" component={() => <DailyCheckPage bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/create-daily-check" component={() => <CreateDailyCheck bnEmail={this.state.bnEmail} />} />
		  					<Route exact path="/update-daily-check" component={UpdateDailyCheck} />
		  				
		  				<Route exact path="/danglingAwsSpinner" component={() => <DanglingAwsSpinner bnEmail={this.state.bnEmail} />} />
		  				<Route exact path="/danglingDoSpinner" component={() => <DanglingDOSpinner bnEmail={this.state.bnEmail} />} />
		  				<Route exact path="/danglingDoExtra" component={() => <DanglingDOExtra bnEmail={this.state.bnEmail} />} />
		  				<Route exact path="/staleServers" component={() => <StaleServers bnEmail={this.state.bnEmail} />} />
		  				<Route exact path="/zabbixFailed" component={() => <ZabbixFailed bnEmail={this.state.bnEmail} />} />
		  				<Route exact path="/zabbixDisabled" component={() => <ZabbixDisabled bnEmail={this.state.bnEmail} />} />
		  					
		  				<Route exact path="/analytics-summary" component={() => <AnalyticsSummaryPage bnEmail={this.state.bnEmail} />} />
		  				<Route exact path="/group-analytics-summary" component={() => <GroupAnalyticsSummaryPage bnEmail={this.state.bnEmail} />} />
		  				<Route exact path="/server-analytics-summary" component={() => <ServerAnalyticsSummaryPage bnEmail={this.state.bnEmail} />} />
		  					
		  				<Route exact path="/clear-dns" component={() => <ClearDns bnEmail={this.state.bnEmail} />} />
		  					
		  				<Route exact path="/help" component={HelpPage} />
		  					
		  				
		  				<Route exact path="/rp/meetingid" component={rpMeetingId} />
		  				<Route exact path="/rp/status" component={rpStatus} />
		  				
		  				<Route exact path="/lighthouse/home" component={Lighthouse} />
		  				
		  				<Route exact path="/stesting/home" component={Stesting} />
		  				<Route exact path="/stesting/launch-test" component={LaunchTest} />
		  				<Route exact path="/stesting/upload" component={Upload} />
		  				<Route exact path="/stesting/logs" component={Logs} />

		  				<Route exact path="/bbb-logs/get-logs" component={BBBLogs} />

						  <Route exact path="/rs/retry" component={RsInbox} />
						  <Route exact path="/rs/analytics-callback" component={AnalyticsCallback} />
						  <Route exact path="/rs/analytics-query" component={AnalyticsQuery} />
						  <Route exact path="/rs/elad" component={Elad} />
						  <Route exact path="/rs/elad-customers" component={EladCustomers} />
						  <Route exact path="/rs/create-elad-customer" component={CreateEladCustomers} />
						  <Route exact path="/rs/edit-elad-customer" component={EditEladCustomer} />
		  					
		  				<Redirect to="/404" />
					
		            
			  		</Switch>
			    </Router>
    	</div>
    	)
  	}
  	else
  	{
  		return(
  			<div className="App">
		  	
			<h3> Welcome to Spinner Frontend </h3>
			<button onClick={() => Auth.federatedSignIn()}>Login</button>
			</div>
	    )
  	}
    
  }
}

export default App;
