import React from "react";
import Navbar from '../components/Nav'
import DailyCheck from '../components/DailyCheck'

const DailyCheckPage = (props) => {
	return(
		<div className="pageBody">
			<Navbar />
			
			<div className="container">
				<DailyCheck />
			</div>
			
			
		</div>
	)
}

export default DailyCheckPage;