import React from "react";
import Navbar from '../components/Nav'
import OvhSchedule from '../components/OvhSchedule'

const OvhSchedulePage = (props) => {
	return(
		<div className="pageBody">
			<Navbar />
			
			<div className="container">
				<OvhSchedule />
			</div>
			
			
		</div>
	)
}

export default OvhSchedulePage;