import React, { Component, Link, useState } from 'react';
import NavbarRS from '../../components/NavRS'
import axios from 'axios';

class RsInbox extends Component {
	state = {
		internal_meetingid: null,
		lbRegion: null,
        month: null,
        year: null,
        status: null,
        date: null,
        url: null,
        msg: null
	}
	
	async componentDidMount() {
		
	}
	
	changeHandler = (e) => {
		let val = e.target.value;
		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		this.setState(stateObject);
	}
	
	submitHandler = async(e) => {
		e.preventDefault();
		
        if (window.confirm("Confirm retry?")) {
            console.log(this.state)

            let month = null;
            let year = null;

            if(this.state.date) {
                month = this.state.date.split("-")[1]
                year = this.state.date.split("-")[0]
            }

            let apiObj = {
                internal_meetingid: this.state.internal_meetingid,
                month: month,
                year: year,
                status: this.state.status
            }

			let api_key = process.env.REACT_APP_ANALYTICS_CALLBACK_API_KEY;
            
            let axiosConfig = {
                headers: {
                    "Content-Type": "application/json",
                    "X-API-Key": api_key,
                    'Access-Control-Allow-Origin': 'https://portal.spinner.blindsidenetworks.com',
                },
            }
            axios.defaults.headers.common = {
                "X-API-Key": api_key,
                'Access-Control-Allow-Origin': 'https://portal.spinner.blindsidenetworks.com'
            }
            
            if(this.state.lbRegion == 'rna2') {
            	this.state.url = 'https://kwzkakp229.execute-api.ca-central-1.amazonaws.com/Prod/retry-callback';
            } else if(this.state.lbRegion == 'reu1') {
            	this.state.url = 'https://t1ifklqxqj.execute-api.eu-west-2.amazonaws.com/Prod/retry-callback';
            } else if(this.state.lbRegion == 'roc2') {
            	this.state.url = 'https://d1adb9hu3f.execute-api.ap-southeast-1.amazonaws.com/Prod/retry-callback';
            } else {
            	this.state.url = 'https://owvbl2qmm0.execute-api.us-east-1.amazonaws.com/Prod/retry-callback';
            }

            const url = this.state.url
            const apiData = await axios.post(url, apiObj, axiosConfig)
            .then((data) =>
                // console.log(data['data'])
                data['data']
            ).then((apiResponse) => this.setState({ msg: apiResponse }));
        }
	}

	
	render() {
		const {internal_meetingid, month, year, status, date, lbRegion, msg} = this.state
		
		return(
			<div className="rs-inbox">
			
				<NavbarRS />
				<h3>Rs-Inbox</h3>
                <br/>

                <form onSubmit={this.submitHandler} className="container">

					<div className="form-group row">
						<label className="col-2 col-form-label">Status (only enter if you want to reprocess all failed):</label>
						<div className="col-10">
							<select name="status" className="form-control" id="status"  value={status} onChange={this.changeHandler}>
								<option value="" selected disabled hidden>choose status (optional)</option>
								<option>failed</option>
								<option>success</option>
							</select>
						</div>
					</div>

					<div className="form-group row">
						<label className="col-2 col-form-label">Load balancer region</label>
						<div className="col-10">
							<select name="lbRegion" className="form-control" id="lbRegion"  value={lbRegion} onChange={this.changeHandler}>
								<option value="" selected disabled hidden>LB region</option>
								<option>rna1</option>
								<option>rna2</option>
								<option>reu1</option>
								<option>roc2</option>
							</select>
						</div>
					</div>
					<p></p>
                    <div className="form-group row">
						<label className="col-2 col-form-label">Internal Meeting Id (Optional):</label>
						<div className="col-10">
							<input type="text" name="internal_meetingid" className="form-control" id="internal_meetingid" placeholder="Enter internal_meetingid eg. 260e3823bfc16d7787b8d986896e9b64c6890028-1662757995726" value={internal_meetingid} onChange={this.changeHandler}></input>
						</div>
					</div>
					 
					<div className="form-group row">
					  <label className="col-2 col-form-label">Reprocess by month (Select any date in the month you want to reprocess:- Optional):</label>
					  <div className="col-10">
						  <input name="date" className="form-control" type="date" value={date} id="date" onChange={this.changeHandler}></input>
					  </div>
					</div>
					
					<button type="submit" className="btn btn-primary">Submit</button>

				</form>
				
				{msg && <p className="alert alert-success"> Response: {msg}</p>}
			</div>
		);
	}
}

export default RsInbox;
