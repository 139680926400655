import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class UpdateAutoscale extends Component {
	state = {
			id: this.props.location.state.id,
			customer: this.props.location.state.customer,
			version: this.props.location.state.version,
			prodGroup: this.props.location.state.prodGroup,
			lbRegion: this.props.location.state.lbRegion,
			cloudRegion: this.props.location.state.cloudRegion,
			cloudProvider: this.props.location.state.cloudProvider,
			image: this.props.location.state.image,
			tableName: "autoscales",
			cap: this.props.location.state.cap,
			minLimit: this.props.location.state.minLimit,
			autoscaleThreshold: this.props.location.state.autoscaleThreshold,
			downscaleThreshold: this.props.location.state.downscaleThreshold,
			scaleupNumbers: this.props.location.state.scaleupNumbers,
			submit: false
	}
	
	changeHandler = (e) => {

		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		//console.log(e.target.name);
		this.setState(stateObject);
		
		console.log(this.state)
	}
	
	submitHandler = (e) => {
		e.preventDefault();
		
		if(this.state.cloudProvider == 'aws') {
			this.setState({ image: null })
		}
		
		//console.log(this.state)
		this.setState({
			submit: true
		})
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/edit-tabledata';
		const url = api_url + "edit-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/edit-tabledata';
		//axios.post(url, this.state)
		axios.post(url, this.state, axiosConfig)
		.then(response => {

			response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: JSON.stringify(response['data']['data']) });
			
			
		})
		
	}
	
	render() {
		//console.log(this.props);
		const {customer, version, prodGroup, lbRegion, cloudRegion, cloudProvider, image, cap, minLimit, autoscaleThreshold, downscaleThreshold, scaleupNumbers} = this.state
		return(
			<div className="pageBody">
				<Navbar />
				<h3>Update entry for Autoscale Schedule</h3>
				<p>{this.props.location.search}</p>
				
				<form onSubmit={this.submitHandler} className="container">
				  
				  	  <div className="form-group">
						<label>Customer</label>
						<select name="customer" className="form-control" id="customer"  value={customer} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose customer (bn = blindsidenetworks)</option>
							<option>bn</option>
						</select>
					  </div>
					  
					  <div className="form-group">
						<label>Version</label>
						<select name="version" className="form-control" id="version"  value={version} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose BBB-build version</option>
							<option>2_5</option>
							<option>2_6</option>
							<option>2_7</option>
						</select>
					  </div>
					  
					  <div className="form-group">
						<label>Region</label>
						<select name="lbRegion" className="form-control" id="lbRegion"  value={lbRegion} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose LB</option>
							<option>rna1</option>
							<option>reu1</option>
							<option>roc2</option>
							<option>rna2</option>
						</select>
					  </div>
					  
					  <div className="form-group">
						<label>Cloud Provider</label>
						<select name="cloudProvider" className="form-control" id="cloudProvider"  value={cloudProvider} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose cloud provider</option>
							<option>do</option>
							<option>aws</option>
						</select>
					  </div>
					  
					  {this.state.lbRegion === "rna1" && this.state.cloudProvider === "do" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>nyc3</option>
							<option>nyc1</option>
							<option>sfo1</option>
							<option>sfo2</option>
							<option>sfo3</option>
							<option>tor1</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "rna1" && this.state.cloudProvider === "aws" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>us-east-2</option>
							<option>us-west-2</option>
						</select>
					  </div>}

					  {this.state.lbRegion === "reu1" && this.state.cloudProvider === "do" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>ams3</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "reu1" && this.state.cloudProvider === "aws" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>eu-west-2</option>
							<option>eu-central-1</option>
						</select>
					  </div>}				  

					  {this.state.lbRegion === "roc2" && this.state.cloudProvider === "do" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>sgp1</option>
							<option>blr1</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "roc2" && this.state.cloudProvider === "aws" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>ap-southeast-1</option>
							<option>ap-southeast-2</option>
						</select>
					  </div>}

					  {this.state.lbRegion === "rna2" && this.state.cloudProvider === "do" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>tor1</option>
						</select>
					  </div>}

					  {this.state.lbRegion === "rna2" && this.state.cloudProvider === "aws" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>ca-central-1</option>
						</select>
					  </div>}

					  <div className="form-group">
						<label>Group</label>
						<input type="text" name="prodGroup" className="form-control" id="prodGroup" placeholder="Group name" value={prodGroup} onChange={this.changeHandler}></input>
					  </div>
					  
					  {this.state.cloudProvider === "do" && <div className="form-group">
						<label>Image</label>
						<input type="text" name="image" className="form-control" id="image" placeholder="Image name if using latest leave blank" value={image} onChange={this.changeHandler}></input>
					  </div>}
					  
					  <div className="form-group">
						<label>Cap</label>
						<input type="text" name="cap" className="form-control" id="cap" placeholder="Cap (Max limit)" value={cap} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Min Limit</label>
						<input type="text" name="minLimit" className="form-control" id="minLimit" placeholder="Min Limit" value={minLimit} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Autoscale Threshold</label>
						<input type="text" name="autoscaleThreshold" className="form-control" id="autoscaleThreshold" placeholder="Autoscale Threshold" value={autoscaleThreshold} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Downscale Threshold</label>
						<input type="text" name="downscaleThreshold" className="form-control" id="downscaleThreshold" placeholder="Downscale Threshold" value={downscaleThreshold} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Number of servers to laucnh for Scaling</label>
						<input type="text" name="scaleupNumbers" className="form-control" id="scaleupNumbers" placeholder="Number of servers to laucnh for Scaling" value={scaleupNumbers} onChange={this.changeHandler}></input>
					  </div>					  
					  

					{customer, version, prodGroup && lbRegion && cloudRegion && cloudProvider && cap && minLimit && <button type="submit" className="btn btn-primary">Submit</button>}

				</form>
				
				{this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
			</div>
		);
	}
}

export default UpdateAutoscale;
