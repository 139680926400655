import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class CreateMoveUser extends Component {
	state = {
			lbRegion: '',
			userIds: '',
			source: '',
			destination: '',
			moveTime: '',
			timezone: '',
			tableName: "users",
			days: {
			    Monday: false,
			    Tuesday: false,
			    Wednesday: false,
			    Thursday: false,
			    Friday: false,
			    Saturday: false,
			    Sunday: false,
			},
			submit: false
	}
	
	changeHandler = (e) => {
	  const { name, value, type, checked } = e.target;

	  if (type === 'checkbox') {
	    this.setState((prevState) => ({
	      ...prevState,
	      days: {
	        ...prevState.days,
	        [value]: checked,
	      },
	    }), () => {
	      // console.log(this.state);
	    });
	  } else {
	    this.setState({
	      [name]: value,
	    }, () => {
	      // console.log(this.state);
	    });
	  }
	}
	
	submitHandler = (e) => {
		e.preventDefault();
		
		//console.log(this.state)
		this.setState({
			submit: true
		})
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/create-tabledata';
		const url = api_url + "create-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/create-tabledata';
		//axios.post(url, this.state)
		axios.post(url, this.state, axiosConfig)
		.then(response => {

			response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: response['data']['data'] });
			
			
		})
		
		this.setState({
			lbRegion: '',
			userIds: '',
			source: '',
			destination: '',
			moveTime: '',
			timezone: '',
			tableName: "users",
			days: {
			    Monday: false,
			    Tuesday: false,
			    Wednesday: false,
			    Thursday: false,
			    Friday: false,
			    Saturday: false,
			    Sunday: false,
			},
			submit: false
		})
		
	}
	
	render() {
		const {lbRegion, userIds, source, destination, moveTime, timezone, days} = this.state
		return(
			<div className="pageBody">
				<Navbar />
				<h3>Create new entry for the Move User Schedule</h3>

				<form onSubmit={this.submitHandler} className="container">
					  
					  <div className="form-group">
						<label>Region</label>
						<select name="lbRegion" className="form-control" id="lbRegion"  value={lbRegion} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose LB</option>
							<option>rna1</option>
							<option>reu1</option>
							<option>roc2</option>
							<option>rna2</option>
						</select>
					  </div>

					  <div className="form-group">
						<label>User Ids</label>
						<input type="text" name="userIds" className="form-control" id="userIds" placeholder="Please enter comma separated values( no spaces )." value={userIds} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Source</label>
						<input type="text" name="source" className="form-control" id="source" placeholder="Source of moving users" value={source} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Destination</label>
						<input type="text" name="destination" className="form-control" id="destination" placeholder="Destination for moving users." value={destination} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Move Time</label>
						<input type="text" name="moveTime" className="form-control" id="moveTime" placeholder="move time in UTC eg. 05:00" value={moveTime} onChange={this.changeHandler}></input>
					  </div>
					  
					  {this.state.lbRegion === "rna1" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>America/New_York</option>
							<option>America/Los_Angeles</option>
							<option>America/Mexico_City</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "reu1" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>Europe/London</option>
							<option>Europe/Paris</option>
							<option>Europe/Amsterdam</option>
							<option>Europe/Athens</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "roc2" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>Asia/Manila</option>
							<option>Asia/Singapore</option>
							<option>Australia/Sydney</option>
							<option>Australia/Perth</option>
							<option>Australia/Adelaide</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "rna2" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>America/Toronto</option>
							<option>America/Halifax</option>
							<option>America/Vancouver</option>
						</select>
					  </div>}

					  <div className="form-group">
						<label className="mx-5">Days</label>
						<label className="mx-2">
					        <input
					          type="checkbox"
					          value="Monday"
					          checked={days['Monday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Monday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Tuesday"
					          checked={days['Tuesday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Tuesday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Wednesday"
					          checked={days['Wednesday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Wednesday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Thursday"
					          checked={days['Thursday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Thursday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Friday"
					          checked={days['Friday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Friday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Saturday"
					          checked={days['Saturday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Saturday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Sunday"
					          checked={days['Sunday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Sunday
					    </label>

					  </div>


					{userIds && source && destination && lbRegion && <button type="submit" className="btn btn-primary">Submit</button>}

				</form>
				
				{this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
			</div>
		);
	}
}

export default CreateMoveUser;
