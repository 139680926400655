import React, { Component } from 'react';
import axios from 'axios';
import {
  Link,
} from "react-router-dom";

class OvhSchedule extends Component {
	state = {
		api_data: [],
	}

	async fetchData(){
		var tableObj = {tableName: "ovh_move"}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/get-tabledata';
		const url = api_url + "get-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/get-tabledata';
    	//const apiData = await axios.post(url, tableObj)
		const apiData = await axios.post(url, tableObj, axiosConfig)
      	.then((data) =>
			// console.log(data['data'])
			//JSON.parse(data['data']['body'])
			data['data']
		).then((apiData) => this.setState({ api_data: apiData}));
		
	}

	async componentDidMount() {
		try {
			await this.fetchData();
		} catch (error) {
            console.error(error);
        }
		
	}

	deleteEntry(id) {
		var deleteObj = {
			tableName: "ovh_move",
			id: id
		}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/delete-tabledata';
		const url = api_url + "delete-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/delete-tabledata';
		//axios.post(url, deleteObj)
		axios.post(url, deleteObj, axiosConfig)
		.then(response => {
			//console.log(response)
			response['status'] != 200 ? this.setState({ response: false,responseText: response['status'] }) : this.setState({ response: true, responseText: response['data']['data'] });
			var index = this.state.api_data.findIndex(x => x.id === id);
			  
			if (index >= 0) {
			  var array = this.state.api_data.splice(index, 1);
			  this.setState({api_data: this.state.api_data})
			}			
		})
	}

	async editEntry(id, lb_group, lb_region, move_time, is_enable, timezone, servers, days) {
		if (is_enable == "true") {
			is_enable = "false"
		} else {
			is_enable = "true"
		}
		var editObj = {
			tableName: "ovh_move",
			id: id,
			lb_group: lb_group,
			lb_region: lb_region,
			move_time: move_time,
			is_enable: is_enable,
			timezone: timezone,
			servers: servers,
			days: days
		}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;
			
		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		const url = api_url + 'edit-tabledata';
		
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/edit-tabledata';
		//axios.post(url, editObj)
		axios.post(url, editObj, axiosConfig)
		.then(response => {
			// console.log(response['data']['data']);
			this.fetchData();
		})		
	}
	
	getDays(daysString){
		const selectedDaysArray = daysString.split(',').map(day => day.trim());
		// Define an array of all days of the week
		const allDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

		// Create an object with all days initialized to false
		const daysObject = allDays.reduce((acc, day) => {
		  acc[day] = false;
		  return acc;
		}, {});

		// Set the selected days to true
		selectedDaysArray.forEach(selectedDay => {
		  if (daysObject.hasOwnProperty(selectedDay)) {
		    daysObject[selectedDay] = true;
		  }
		});

		// Now daysObject contains the desired structure
		return daysObject;
	}

	getLocalTime(t) {
		var today = new Date()
		var dd = today.getDate()

		var mm = today.getMonth()+1
		var yyyy = today.getFullYear()
		if(dd<10) 
		{
			dd='0'+dd
		} 

		if(mm<10) 
		{
			mm='0'+mm
		} 
		today = yyyy+'-'+mm+'-'+dd
		
		var formatted_time = `${today} ${t} UTC`
		
		var local_time = new Date(formatted_time)
		
		
		var currentHours = ('0'+local_time.getHours()).substr(-2);
		var mins = ('0'+local_time.getMinutes()).slice(-2);
		
		var ampm = "am";
		var hr = currentHours
		if( hr >= 12 ) {
			//hr -= 12;
			ampm = "pm";
		}
		
		var display_time = `${currentHours}:${mins} ${ampm}`
		
		return display_time
	}
	
    render() {
		
        return(
            <div className="ovh_schedule">
            
            	<h3>OVH Schedule</h3>
            
            	<a href="/create-ovh"><button type="button" className="btn btn-dark center-block dd-create">Create new entry</button></a>
            	
            	
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				  	<th scope="col">ID</th>
					  <th scope="col">GROUP</th>
					  <th scope="col">REGION</th>
					  <th scope="col">MOVE TIME (Local time)</th>
					  <th scope="col">DAYS</th>
					  <th scope="col">TIMEZONE</th>
					  <th scope="col">SERVERS</th>
					  <th scope="col">Enable?</th>

					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (<tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.lb_group}</td>
									<td className="col tr">{entry.lb_region}</td>
									<td className="col tr">{entry.move_time}</td>
									<td className="col tr">{entry.days}</td>
									<td className="col tr">{entry.timezone}</td>
									<td className="col tr">{entry.servers}</td>
									<td className="col tr">{entry.is_enable.toString()}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-ovh",
												state: {id: entry.id,
														lbGroup: entry.lb_group,
														lbRegion: entry.lb_region,
														launchTime: entry.move_time,
														isEnable: entry.is_enable,
														timezone: entry.timezone,
														servers: entry.servers,
														days: this.getDays(entry.days),
														tableName: "ovh_move"}
										  }}

								 >Edit</Link> </button> </td>
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm(entry.is_enable == "true" ? 'Disable the entry?' : 'Enable the entry?')){this.editEntry(entry.id, entry.lb_group, entry.lb_region, entry.move_time, entry.is_enable, entry.timezone, entry.servers, this.getDays(entry.days))};}}>{entry.is_enable == "true" ? 'Disable' : 'Enable'}</button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)
						 
					})}
					
					</tbody>
				</table>           
       </div>
        );
    }
}

export default OvhSchedule;
