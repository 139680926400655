import React from "react";
import Navbar from '../components/Nav'
import AutoscaleSchedule from '../components/AutoscaleSchedule'

const AutoscaleSchedulePage = (props) => {
	return(
		<div className="pageBody">
			<Navbar />
			
			<div className="container">
				<AutoscaleSchedule />
			</div>
			
			
		</div>
	)
}

export default AutoscaleSchedulePage;