import React, { Component, Link, useState } from 'react';
import NavbarRS from '../../components/NavRS'
import axios from 'axios';

class AnalyticsQuery extends Component {
	state = {
		internal_meetingid: null,
		external_meetingid: null,
		api_data: []
	}
	
	async componentDidMount() {
		
	}
	
	changeHandler = (e) => {
		let val = e.target.value;
		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		this.setState(stateObject);
	}
	
	submitHandler = async(e) => {
		e.preventDefault();
            let apiObj = {
                internal_meetingid: this.state.internal_meetingid,
                external_meetingid: this.state.external_meetingid
            }

            let api_key = process.env.REACT_APP_ANALYTICS_CALLBACK_API_KEY;
            
            let axiosConfig = {
                headers: {
                    "Content-Type": "application/json",
                    "X-API-Key": api_key
                },
            }
            axios.defaults.headers.common = {
                "X-API-Key": api_key,
            }

            const url = 'https://kwzkakp229.execute-api.ca-central-1.amazonaws.com/Prod/query-callback';

            const apiData = await axios.post(url, apiObj, axiosConfig)
            .then((data) =>
                // console.log(data['data'])
                data['data']
            )//.then((apiData) => this.setState({ api_data: apiData }));
        	this.setState({ api_data: apiData })
        	console.log(this.state.api_data)
	}
	
	render() {
		const {internal_meetingid, external_meetingid, api_data} = this.state
		
		return(
			<div className="analytics-callback-query">
			
				<NavbarRS />
				<h3>Analytics Callback Query</h3>
                <br/>

                <form onSubmit={this.submitHandler} className="container">
                	{!external_meetingid &&
	                    <div className="form-group row">
							<label className="col-2 col-form-label">Internal Meeting Id :</label>
							<div className="col-10">
								<input type="text" name="internal_meetingid" className="form-control" id="internal_meetingid" placeholder="Enter internal meetingid eg. 260e3823bfc16d7787b8d986896e9b64c6890028-1662757995726" value={internal_meetingid} onChange={this.changeHandler}></input>
							</div>
						</div>
					}
					{!internal_meetingid &&  
						<div className="form-group row">
							<label className="col-2 col-form-label">External Meeting Id :</label>
							<div className="col-10">
								<input type="text" name="external_meetingid" className="form-control" id="external_meetingid" placeholder="Enter external meetingid eg. b45149ee-515e-4eee-9686-4f8a92b08365" value={external_meetingid} onChange={this.changeHandler}></input>
							</div>
						</div>
					}

					{(internal_meetingid || external_meetingid) &&            
						<button type="submit" className="btn btn-primary">Submit</button>
					}
					<p></p>
				</form>
				<div>
					{api_data && api_data.map((entry, index) => {
								if (index == 0){
									return (
										<div>
											<p> Customer: {entry.bn_userid} </p>
											<p> Meeting date: {entry.creation_date} </p>
											<p> Meeting Name: {entry.meeting_name} </p>
											<p> Internal MeetingId: {entry.internal_meetingid} </p>
											<p> External MeetingId: {entry.external_meetingid} </p>
											<p> Step: {entry.step} </p>
											<p> Status: {entry.status} </p>
											<p> Hostname: {entry.hostname} </p>
											<p> Callback URL: {entry.callback_url} </p>
										</div>
									)}	

					})}

				
						<div>
							<h3> Retries </h3>
							<table className="table table-striped table-hover table-gap container">
							  <thead className="table-dark">
								<tr>
							      <th className="date" scope="col">Timestamp (UTC)</th>									     
								  <th scope="col">Status</th>
								  <th scope="col">Error</th>
								  <th scope="col">Failed steps</th>
								</tr>
							  </thead>
							  
								<tbody>
								{api_data && api_data.map((entry, index) => {
									return(
									 	index > 0 && <tr>
												<td className="col tr date">{entry.timestamp}</td>
												<td className="col tr">{entry.status}</td>
												<td className="col tr">{entry.errormsg}</td>
												<td className="col tr">{entry.step}</td>
										   </tr>
									)})}
								</tbody>
							</table>	
						</div>
				</div>
			</div>
		);
	}
}

export default AnalyticsQuery;
