import React, { Component, useState } from 'react';
import NavbarRP from '../../components/NavRP'
import axios from 'axios';

class rpStatus extends Component {
	state = {
		status: "",
		date: "",
		time: "",
		submit: false,
	}
	
	componentDidMount() {
		
	}
	
	changeHandler = (e) => {

		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		//console.log(e.target.name);
		this.setState(stateObject);
	}
	
	submitHandler = async (e) => {
		e.preventDefault();
		
		this.setState({
			submit: true
		})
		
		if(!this.state.date && !this.state.time) {
			const url = `https://i2vyu2o5al.execute-api.us-east-2.amazonaws.com/staging/recording/status?status=${this.state.status}`;
		
			let axiosConfig = {
			  headers: {
				  "X-API-Key": `${process.env.REACT_APP_API_GATEWAY}`,
			  }
			};

			await axios.get(url, axiosConfig)
			.then(response => {
				//console.log(response["data"])

				response['data'].hasOwnProperty('Items') ? this.setState({ response: true, responseText: response['data']['Items']} ) : this.setState({ response: false, responseText: 'Error' });


			})

			//console.log(this.state)
		} else if(this.state.date && !this.state.time) {
			let date = this.state.date;
			
			const url = `https://i2vyu2o5al.execute-api.us-east-2.amazonaws.com/staging/recording/status?status=${this.state.status}&date=${date}`;
		
			let axiosConfig = {
			  headers: {
				  "X-API-Key": `${process.env.REACT_APP_API_GATEWAY}`,
			  }
			};

			await axios.get(url, axiosConfig)
			.then(response => {
				//console.log(response["data"])

				response['data'].hasOwnProperty('Items') ? this.setState({ response: true, responseText: response['data']['Items']} ) : this.setState({ response: false, responseText: 'Error' });


			})

			//console.log(this.state)
		} else if(this.state.date && this.state.time) {
			let date = this.state.date;
			date = `${date}T${this.state.time}`
			
			const url = `https://i2vyu2o5al.execute-api.us-east-2.amazonaws.com/staging/recording/status?status=${this.state.status}&date=${date}`;
		
			let axiosConfig = {
			  headers: {
				  "X-API-Key": `${process.env.REACT_APP_API_GATEWAY}`,
			  }
			};

			await axios.get(url, axiosConfig)
			.then(response => {
				//console.log(response["data"])

				response['data'].hasOwnProperty('Items') ? this.setState({ response: true, responseText: response['data']['Items']} ) : this.setState({ response: false, responseText: 'Error' });


			})

			//console.log(this.state)
		}
		
		/*this.setState({
			recordId: "",
			submit: false,
			response: false,
			responseText: null
		})*/
		
	}
	
	render() {
		const {status, date, time} = this.state
		
		return(
			<div className="rpStatus">
				<NavbarRP />
				
				<h3>Find by Status and/or datetime</h3>
				<br></br>
				<form onSubmit={this.submitHandler} className="container">

					<div className="form-group row">
						<label className="col-2 col-form-label">Status:</label>
						<div className="col-10">
							<select name="status" className="form-control" id="status"  value={status} onChange={this.changeHandler}>
								<option value="" selected disabled hidden>choose status</option>
								<option>FAILED</option>
								<option>ARCHIVED</option>
								<option>PROCESSED</option>
								<option>PUBLISHED</option>
								<option>NO_RECORDING</option>
							</select>
						</div>
					  </div>
					 
					<div className="form-group row">
					  <label className="col-2 col-form-label">Date (Optional):</label>
					  <div className="col-10">
						  <input name="date" className="form-control" type="date" value={date} id="date" onChange={this.changeHandler}></input>
					  </div>
					</div>
					
					<div className="form-group row">
						<label className="col-2 col-form-label">Time (Optional):</label>
						<div className="col-10">
							<input type="text" name="time" className="form-control" id="time" placeholder="Enter UTC time to the accuracy you seek eg. 17:00:00 or 17:00 or 17" value={time} onChange={this.changeHandler}></input>
						</div>
					</div>
					
					<button type="submit" className="btn btn-primary">Submit</button>

				</form>
				
				
				<div className="container">
					<table className="table table-striped table-hover table-gap">
					  <thead className="table-dark">
						<tr>
						  <th scope="col">Rec ID</th>
						  <th scope="col">Last Status</th>
						  <th scope="col">Last Timestamp</th>
						  <th scope="col">Meeting Name</th>  
						</tr>
					  </thead>

					  <tbody>		

						{this.state.response && this.state.responseText.map((entry) => {

							return(<tr key={entry.pk}>
								<td className="col tr">{entry.pk.replace("REC_ID#", "")}</td>
								<td className="col tr">{entry.lastStatus.replace("REC_STATUS#", "")}</td>
								<td className="col tr">{entry.lastStatusTimestamp}</td>
								<td className="col tr">{entry.metadata.meetingName}</td>	
							</tr>)

						})}

						</tbody>
					</table>
				</div>
				
				
				
			</div>
		);
	}
}

export default rpStatus;