import React, { Component } from 'react';
import Navbar from '../components/Nav';
import Chart from 'chart.js';
import axios from 'axios';

class GroupAnalyticsSummaryPage extends Component {
  constructor(props) {
    super(props);

    this.chartRef = React.createRef();

    this.state = {
      submit: false,
      region: null,
      group: null,
      history: 1,
      data: {
        labels: [1, 2, 3, 4, 5],
        datasets: [
          {
          label: "Avg. Load %",
          borderColor: "rgba(255, 0, 255, 0.75)",
          backgroundColor: "rgba(255, 0, 255, 0)",
          data: []
          },
          {
          label: "Avg. Users",
          borderColor: "rgba(255, 0, 0, 0.75)",
          backgroundColor: "rgba(255, 0, 0, 0)",
          data: [],
          hidden: true
          },
          {
          label: "Avg. Meetings",
          borderColor: "rgba(0, 0, 255, 0.75)",
          backgroundColor: "rgba(0, 0, 255, 0)",
          data: [],
          hidden: true
          },
          {
          label: "Num. of Servers",
          borderColor: "rgba(0, 255, 0, 0.75)",
          backgroundColor: "rgba(0, 255, 0, 0)",
          data: [],
          hidden: true
          },
          {
          label: "Total Users",
          borderColor: "rgba(0, 0, 0, 0.75)",
          backgroundColor: "rgba(0, 0, 0, 0)",
          data: [],
          hidden: true
          },
          {
          label: "Total Meetings",
          borderColor: "rgba(0, 255, 175, 0.75)",
          backgroundColor: "rgba(0, 255, 175, 0)",
          data: [],
          hidden: true
          }
        ],
      },
    };
  }

  changeHandler = (e) => {
    var stateObject = {};
    stateObject[e.target.name] = e.target.value;
    stateObject['data'] = {
        labels: [1, 2, 3, 4, 5],
        datasets: [
          {
          label: "Avg. Load %",
          borderColor: "rgba(255, 0, 255, 0.75)",
          backgroundColor: "rgba(255, 0, 255, 0)",
          data: []
          },
          {
          label: "Avg. Users",
          borderColor: "rgba(255, 0, 0, 0.75)",
          backgroundColor: "rgba(255, 0, 0, 0)",
          data: [],
          hidden: true
          },
          {
          label: "Avg. Meetings",
          borderColor: "rgba(0, 0, 255, 0.75)",
          backgroundColor: "rgba(0, 0, 255, 0)",
          data: [],
          hidden: true
          },
          {
          label: "Num. of Servers",
          borderColor: "rgba(0, 255, 0, 0.75)",
          backgroundColor: "rgba(0, 255, 0, 0)",
          data: [],
          hidden: true
          },
          {
          label: "Total Users",
          borderColor: "rgba(0, 0, 0, 0.75)",
          backgroundColor: "rgba(0, 0, 0, 0)",
          data: [],
          hidden: true
          },
          {
          label: "Total Meetings",
          borderColor: "rgba(0, 255, 175, 0.75)",
          backgroundColor: "rgba(0, 255, 175, 0)",
          data: [],
          hidden: true
          }
        ],
      };
    this.setState(stateObject);
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  renderChart() {
    const chartElement = this.chartRef.current;
    const ctx = chartElement.getContext('2d');

    new Chart(ctx, {
      type: 'line',
      data: this.state.data,
    });
  }

  updateChartData = async (e) => {
    // Modify the chart data as needed
    e.preventDefault();
    var rgn = this.state.region
    var grp = this.state.group
    var analyticsObj = {region: rgn, group: grp}
    let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
    let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": api_key
      },
    }
    axios.defaults.headers.common = {
      "X-API-Key": api_key,
    }

    const url = api_url + "get-analytics"
    const apiData = await axios.post(url, analyticsObj, axiosConfig)
        .then((data) =>     
      data['data']
    );
    
    // console.log(apiData);
    var group;
    var num_group;
    var history_check = parseInt(this.state.history)*120;
    
    if(apiData.group.length === 0){
      group = 0;
      num_group = 0;  
    } else {
      group = apiData.group;
      num_group = apiData.group.length;
      
      if(num_group > history_check) {
        group = group.slice(0, history_check);
      }
    }
    
    var labels = [];
    var avg_load = [];
    var avg_users = [];
    var avg_meetings = [];
    var num_servers = [];
    var total_users = [];
    var total_meetings = [];
      
    for (const ele of group) {
      labels.push(ele["created_at"]);
      avg_load.push(ele["avg_load"]);
      avg_users.push(ele["avg_users"]);
      avg_meetings.push(ele["avg_meetings"]);
      num_servers.push(ele["servers"]);
      total_users.push(ele["total_users"]);
      total_meetings.push(ele["total_meetings"]);
    }
    

    this.setState({submit: true,
              data: {
              labels: labels.reverse(),
              datasets: [
                {
                label: "Avg. Load %",
                borderColor: "rgba(255, 0, 255, 0.75)",
                backgroundColor: "rgba(255, 0, 255, 0)",
                data: avg_load.reverse()
                },
                {
                label: "Avg. Users",
                borderColor: "rgba(255, 0, 0, 0.75)",
                backgroundColor: "rgba(255, 0, 0, 0)",
                data: avg_users.reverse(),
                hidden: true
                },
                {
                label: "Avg. Meetings",
                borderColor: "rgba(0, 0, 255, 0.75)",
                backgroundColor: "rgba(0, 0, 255, 0)",
                data: avg_meetings.reverse(),
                hidden: true
                },
                {
                label: "Num. of Servers",
                borderColor: "rgba(0, 255, 0, 0.75)",
                backgroundColor: "rgba(0, 255, 0, 0)",
                data: num_servers.reverse(),
                hidden: true
                },
                {
                label: "Total Users",
                borderColor: "rgba(0, 0, 0, 0.75)",
                backgroundColor: "rgba(0, 0, 0, 0)",
                data: total_users.reverse(),
                hidden: true
                },
                {
                label: "Total Meetings",
                borderColor: "rgba(0, 255, 175, 0.75)",
                backgroundColor: "rgba(0, 255, 175, 0)",
                data: total_meetings.reverse(),
                hidden: true
                }
              ]
            }
            })
    console.log(this.state)
  };

  render() {
    return (
      <div className="pageBody">
        <Navbar />
        <div className="container">
          <h1>Group Analytics Summary</h1>
          
          <form onSubmit={this.updateChartData} className="container">
            <div className="form-group">
              <label>Load Balancer region</label>
              <select name="region" className="form-control" id="region"  value={this.state.region} onChange={this.changeHandler}>
                <option value="" selected disabled hidden>choose LB</option>
                <option>rna1</option>
                <option>reu1</option>
                <option>roc2</option>
                <option>rna2</option>
              </select>
            </div>

            <div className="form-group">
              <label>Group</label>
              <input type="text" name="group" className="form-control" id="group" placeholder="Enter group name. Leave blank if you want to see region only." value={this.state.group} onChange={this.changeHandler}></input>
            </div>
            
            <div className="form-group">
              <label>History to show in days</label>
              <select name="history" className="form-control" id="history"  value={this.state.history} onChange={this.changeHandler}>
                <option value="" selected disabled hidden>choose history in days</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
              </select>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
            <div className="chart">
              <h4>{this.state.region} : {this.state.group}</h4>
              <canvas ref={this.chartRef} />
            </div>
            
        </div>
      </div>
    );
  }
}

export default GroupAnalyticsSummaryPage;
