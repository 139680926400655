import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';
import AWS from 'aws-sdk';
// const request = require("request-promise");

class LaunchServerPage extends Component {
	
	state = {
		image: '',
		customer: '',
		version: '',
		serverType: '',
		numberOfServers: '',
		cloudRegion: '',
		cloudProvider: '',
		lbRegion: '',
		group: '',
		prodGroup: '',
		submit: false,
		responseText: '',
		response: '',
		environment: 'dev',
		bnEmail: this.props.bnEmail,
		imagesList: []
	}

changeHandler = (e) => {
  const { name, value } = e.target;
  this.setState({ [name]: value }, () => {
    const { cloudProvider, lbRegion, version } = this.state;

    if (name === 'cloudProvider' || name === 'lbRegion') {
      if (cloudProvider === 'do') {
        this.getDOImages(lbRegion, version);
      } else if (cloudProvider === 'aws') {
        this.getAWSImages(lbRegion, version);
      }
    }
  });
};

	submitHandler = (e) => {
		e.preventDefault();
		this.setState({
			submit: true
		})
		
		let job = `launch_${this.state.cloudProvider}`
		let sendData = {
			job: job,
			image: this.state.image,
			customer: this.state.customer,
			version: this.state.version,
			server_type: this.state.serverType,
			number_of_servers: this.state.numberOfServers,
			cloud_region: this.state.cloudRegion,
			cloud_provider: this.state.cloudProvider,
			lb_region: this.state.lbRegion,
			lb_group: this.state.group,
			prod_group: this.state.prodGroup,
			environment: this.state.environment,
			bn_email: this.state.bnEmail
		}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_WORKER_API_URL;
		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  }
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}
	  const url = api_url + "spinner-serverless"
		axios.post(url, sendData, axiosConfig)
		.then(response => {
			response['status'] != 200 ? this.setState({ response: false,responseText: response['status'].toString() }) : this.setState({ response: true, responseText: response['status'].toString() });
		})

		this.setState({
			image: '',
			customer: '',
			version: '',
			serverType: '',
			numberOfServers: '',
			cloudRegion: '',
			cloudProvider: '',
			lbRegion: '',
			group: '',
			environment: 'dev',
			prodGroup: '',
			imagesList: []
		})		
	}	
	
	getDOImages(region, version) {
	  let latest_image_1, latest_image_2, latest_image_3, test_image;
	  axios.get('https://api.digitalocean.com/v2/images?page=1&per_page=1000&private=true', {
	    headers: {
	      'Authorization': `Bearer ${process.env.REACT_APP_DO_TOKEN}`
	    },
	    rejectUnauthorized: false
	  }).then(response => {
	    const images = response.data.images;
	    let max = 0;
	    
	    images.forEach(function (image) {
	      if (image.name.includes(`bbb-${version}-${region}`)) {
	        const name = image.name;
	        const number = parseInt(name.split("-")[3]);
	        if (number > max) {
	          max = number;
	          latest_image_1 = name;
	        }
	      } else {
	      }
	    });
	    
	    latest_image_2 = `bbb-${version}-${region}-${max - 1}`;
	    latest_image_3 = `bbb-${version}-${region}-${max - 2}`;
	    
	    let max_test = 0;
	    
	    images.forEach(function (image) {
	      if (image.name.includes(`test-${version}-${region}`)) {
	        const name = image.name;
	        const number = parseInt(name.split("-")[3]);
	        if (number > max_test) {
	          max_test = number;
	          test_image = name;
	        }
	      } else {
	        console.log("no test image found")
	      }
	    });
	    
	    this.setState({ imagesList: [latest_image_1, latest_image_2, latest_image_3, test_image] });
	  })
	  .catch(error => {
	    console.error(error);
	  });
	}

	getAWSImages(lbRegion, version) {
  const getRegionFromLbRegion = (lb_region) => {
    switch (lbRegion) {
      case 'rna1':
        return 'us-east-2';
      case 'rna2':
        return 'ca-central-1';
      case 'reu1':
        return 'eu-west-2';
      case 'roc2':
        return 'ap-southeast-1';
      default:
        return 'us-east-1';  // Default region if lb_region doesn't match
    }
  };

  const region = getRegionFromLbRegion(lbRegion);
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: region
  });

  const ec2 = new AWS.EC2();
  const params = {
    Filters: [
      {
        Name: 'name',
        Values: [`bbb-${version}-${lbRegion}-*`, `test-${version}-${lbRegion}-*`]
      }
    ]
  };

  ec2.describeImages(params, (err, data) => {
    if (err) {
      console.error('Error fetching AMIs:', err);
    } else {
    	const imageNames = data.Images.map(image => image.Name);
    	this.setState({ imagesList: imageNames });

    }
  });
}

	render() {
		
		const {customer, version, serverType, numberOfServers, cloudProvider, cloudRegion,lbRegion, image, group, prodGroup, imageList, environment, bnEmail} = this.state
		return (
		
			<div>
				<Navbar bnEmail={bnEmail}/>
				<form onSubmit={this.submitHandler} className="container">
				  
				  <div className="form-group">
					<label>Customer</label>
					<select name="customer" className="form-control" id="customer"  value={customer} onChange={this.changeHandler}>
						<option value="" selected disabled hidden>choose customer</option>
						<option>blindsidenetworks</option>
					</select>
				  </div>

				  <div className="form-group">
					<label>Version</label>
					<select name="version" className="form-control" id="version"  value={version} onChange={this.changeHandler}>
						<option value="" selected disabled hidden>choose BBB-build version</option>					
						<option>2_6</option>
						<option>2_7</option>
						<option>3_0</option>
					</select>
				  </div>

				  <div className="form-group">
					<label>Number of servers (INT)</label>
					<input type="text" name="numberOfServers" className="form-control" id="numberOfServers" placeholder="Enter number of servers e.g 1" value={numberOfServers} onChange={this.changeHandler}></input>
				  </div>
				  
				  <div className="form-group">
					<label>Load Balancer region</label>
					<select name="lbRegion" className="form-control" id="lbRegion"  value={lbRegion} onChange={this.changeHandler}>
						<option value="" selected disabled hidden>choose LB</option>
					    <option>rna1</option>
					    <option>reu1</option>
					    <option>roc2</option>
					    <option>rna2</option>
						<option>staging</option>
					</select>
				  </div>

				  <div className="form-group">
					<label>Cloud Provider</label>
					<select name="cloudProvider" className="form-control" id="cloudProvider" value={cloudProvider} onChange={this.changeHandler}>
					    <option value="" selected disabled hidden>select provider</option>
					    <option>do</option>
					    <option>aws</option>
					</select>
				  </div>

				  <div className="form-group">
					<label>Server type</label> 
					<select name="serverType" className="form-control" id="serverType" value={serverType} onChange={this.changeHandler}>
					    <option value="" selected disabled hidden>choose server type</option>
					    <option value="dyn">dyn - these servers get destroyed every day</option>
					    <option value="perm">perm - these servers get replaced on Monday night and Thursday night</option>
					    <option value="whitelistperm">whitelistperm - these servers get replaced on Monday night and Thursday night</option>
					    <option value="whitelistdyn">whitelistdyn - these servers get destroyed every day</option>
					</select>
				  </div>
				  
				  {this.state.lbRegion === "rna1" && this.state.cloudProvider === "do" &&
				  <div className="form-group">
					<label>Cloud Region</label>
					<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
					    <option value="" selected disabled hidden>choose cloud region</option>
					    <option>nyc3</option>
					    <option>nyc1</option>
						<option>sfo1</option>
						<option>sfo2</option>
					    <option>sfo3</option>
					    <option>tor1</option>
					</select>
			  	  </div>}

			  	  {this.state.lbRegion === "rna1" && this.state.cloudProvider === "aws" &&
				  <div className="form-group">
					<label>Cloud Region</label>
					<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
					    <option value="" selected disabled hidden>choose cloud region</option>
					    <option>us-east-2</option>
					</select>
			  	  </div>}

				  {this.state.lbRegion === "reu1" && this.state.cloudProvider === "do" &&
				  <div className="form-group">
					<label>Cloud Region</label>
					<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
					    <option value="" selected disabled hidden>choose cloud region</option>
					    <option>ams3</option>
					    <option>fra1</option>
					</select>
			  	  </div>}

			  	  {this.state.lbRegion === "reu1" && this.state.cloudProvider === "aws" &&
				  <div className="form-group">
					<label>Cloud Region</label>
					<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
					    <option value="" selected disabled hidden>choose cloud region</option>
					    <option>eu-west-2</option>
					</select>
			  	  </div>}			  	  				  

				  {this.state.lbRegion === "roc2" && this.state.cloudProvider === "do" &&
				  <div className="form-group">
					<label>Cloud Region</label>
					<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
					    <option value="" selected disabled hidden>choose cloud region</option>
					    <option>sgp1</option>
					    <option>blr1</option>
					</select>
			  	  </div>}
			  	  
			  	  {this.state.lbRegion === "roc2" && this.state.cloudProvider === "aws" &&
				  <div className="form-group">
					<label>Cloud Region</label>
					<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
					    <option value="" selected disabled hidden>choose cloud region</option>
					    <option>ap-southeast-1</option>
					</select>
			  	  </div>}

				  {this.state.lbRegion === "rna2" && this.state.cloudProvider === "do" &&
				  <div className="form-group">
					<label>Cloud Region</label>
					<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
					    <option value="" selected disabled hidden>choose cloud region</option>
					    <option>tor1</option>
					</select>
			  	  </div>}

			  	  {this.state.lbRegion === "rna2" && this.state.cloudProvider === "aws" &&
				  <div className="form-group">
					<label>Cloud Region</label>
					<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
					    <option value="" selected disabled hidden>choose cloud region</option>
					    <option>ca-central-1</option>
					</select>
			  	  </div>}

				  {this.state.lbRegion === "staging" && this.state.cloudProvider === "do" &&
				  <div className="form-group">
					<label>Cloud Region</label>
					<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
					    <option value="" selected disabled hidden>choose cloud region</option>
					    <option>nyc3</option>
					    <option>nyc1</option>
					    <option>sfo2</option>
					    <option>tor1</option>
					</select>
			  	  </div>}				

				  <div className="form-group">
					<label>Group</label>
					<select name="group" className="form-control" id="group" value={group} onChange={this.changeHandler}>
						<option value="" selected disabled hidden>choose inital group</option>
						<option>parthik</option>
						<option>rahul</option>
					</select>
				  </div>				  
				  
				  
				  <div className="form-group">
					<label>Image</label>
					<select name="image" className="form-control" id="image" value={this.state.image} onChange={this.changeHandler}>
						<option value="" selected disabled hidden>choose image</option>
						{this.state.imagesList.map(image => (
						  <option key={image} value={image}>
							{image}
						  </option>
						))}	
					</select>
				  </div>

				  <div className="form-group">
					<label>Prod Group</label>
					<input type="text" name="prodGroup" className="form-control" id="prodGroup" placeholder="final group for placement eg. BBB 2.2" value={prodGroup} onChange={this.changeHandler}></input>
				  </div>

				  <div className="form-group">
					<label>Environment</label>
					<select name="environment" className="form-control" id="environment"  value={environment} onChange={this.changeHandler}>
						<option value="" selected disabled hidden>Choose env to launch in?</option>
						<option>dev</option>
						<option>prod</option>
					</select>
				  </div>
				  
				  {this.state.customer && this.state.version && this.state.lbRegion && this.state.cloudProvider === "do" && this.state.numberOfServers && this.state.cloudRegion && this.state.image && this.state.serverType && this.state.group && this.state.prodGroup && this.state.serverType !== "perm" &&
				  	<button type="submit" className="btn btn-primary margin-bottom">Launch DO servers</button>}	
				  	
				  {this.state.customer && this.state.version && this.state.lbRegion && this.state.cloudProvider === "do" && this.state.numberOfServers && this.state.cloudRegion && this.state.image && this.state.serverType && this.state.group && this.state.prodGroup && this.state.serverType === "perm" && 
				  	<button type="submit" className="btn btn-primary margin-bottom">Launch DO servers</button>}
				  				  			  			  
				  {this.state.customer && this.state.version && this.state.lbRegion && this.state.cloudProvider === "aws" && this.state.numberOfServers && this.state.prodGroup && this.state.serverType !== "perm" &&
				  	<button type="submit" className="btn btn-primary margin-bottom">Launch AWS servers</button>}
				  	
				  {this.state.customer && this.state.version && this.state.lbRegion && this.state.cloudProvider === "aws" && this.state.numberOfServers && this.state.prodGroup && this.state.serverType == "perm" && 
				  	<button type="submit" className="btn btn-primary margin-bottom">Launch AWS servers</button>}

				  	
				</form>
				
				
				{this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
				
			</div>)

	}
}

export default LaunchServerPage;
