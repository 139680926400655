import React, { Component, Link, useState } from 'react';
import NavbarLH from '../../components/NavLH'
import axios from 'axios';

class Lighthouse extends Component {
	state = {
		filter: "",
		api_data: [],
		number_of_servers: 0,
		meetings: 0,
		users: 0,
		live_meeting_data: []
	}
	
	async componentDidMount() {
		try {
			await this.fetchData();
		} catch (error) {
            console.error(error);
        }
		
	}
	
	async fetchData(){
		let api_key = process.env.REACT_APP_LIGHTHOUSE_API_KEY;
		
		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		const url = 'https://wc5sskblh0.execute-api.us-west-2.amazonaws.com/lighthouse/get-hostname';
		
		const apiData = await axios.post(url, {}, axiosConfig)
      	.then((data) => 
			//console.log(data['data']['body'])
			data['data']
			 
		).then((apiData) => this.setState({ 
			api_data: JSON.parse(apiData['body']),
			number_of_servers: JSON.parse(apiData['body']).length,
			meetings: JSON.parse(apiData['summary'])['meetings'],
			users: JSON.parse(apiData['summary'])['users']			

		}));
	}
	
	changeHandler = (e) => {
		let val = e.target.value;
		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		this.setState(stateObject);
		
		if(this.state.api_data.includes(val)){
			this.getServer(val);
		}
		
		if(val == ""){
			this.fetchData();
			this.setState({ live_meeting_data: [] });
		}
	}
	
	getServer = async(hostname) => {
		let val = hostname;
		this.setState({filter: val});
		
		let apiObj = {hostname: val}
		
		if(this.state.api_data.map(name => name.hostname).includes(val)){
			let api_key = process.env.REACT_APP_LIGHTHOUSE_API_KEY;
		
			let axiosConfig = {
			  headers: {
				  "Content-Type": "application/json",
				  "X-API-Key": api_key
			  },
			}
			axios.defaults.headers.common = {
			  "X-API-Key": api_key,
			}

			const url = 'https://wc5sskblh0.execute-api.us-west-2.amazonaws.com/lighthouse/get-hostname-meeting-data';

			const apiData = await axios.post(url, apiObj, axiosConfig)
			.then((data) =>
				//console.log(data['data']['body'])
				JSON.parse(data['data']['body'])
			).then((apiData) => this.setState({ live_meeting_data: apiData }));
		}
		
		this.setState({api_data: []})
	}
	
	getTotal = (arr, k) => {	
		return arr.map( v => v[k] ).reduce((a, b) => ( parseInt(a) + parseInt(b) )).toString();
	}
	
	submitHandler = async(e) => {
		e.preventDefault();
		
	}
	
	render() {
		const {filter} = this.state
		
		return(
			<div className="lighthouse">
			
				<NavbarLH />
				<h3>Lighthouse</h3>
				
				<br/>
				<p>Monitoring {this.state.number_of_servers} remote BigBlueButton servers: {this.state.meetings} meetings, {this.state.users} users</p>
				<br/>
				
				<label>Filter by Hostname: &nbsp;</label>
				<input type="text" name="filter" value={filter} onChange={(e) => this.changeHandler(e)} />
				<ul className="lighthouse-hostnames">
				
				{this.state.api_data && this.state.api_data.map(name => name.hostname).filter(name => name.includes(this.state.filter)).map((hostname, index) => {
						
						return ( 
							<div className="row" key={index}>
								<li className="offset-3 col-1" key={index+1}>{index+1}</li>
								<li className="col-4" key={hostname}><a href={`#${hostname}`} onClick={() => {this.getServer(hostname)}}>{hostname}</a></li>
							</div>
						)
						 
					})}
				</ul>
				
				{this.state.live_meeting_data && this.state.live_meeting_data.length > 0 &&
				<h6><strong><em>{this.state.filter}</em> {`has meetings: ${this.state.live_meeting_data.length} with users: ${this.getTotal(this.state.live_meeting_data, "participant_count")}, chat: ${this.getTotal(this.state.live_meeting_data, "chat")}, streams: ${this.getTotal(this.state.live_meeting_data, "video_count")}`}</strong></h6>}
				
				{this.state.live_meeting_data && this.state.live_meeting_data.length > 0 &&
				<div className="container">
				<table className="table table-striped table-hover table-gap">
				  <thead className="thead-dark">
					<tr>
		  	  	      <th scope="col">Index</th>
			  	  	  <th scope="col">Meeting ID</th>
				  	  <th scope="col">Meeting name</th>
				  	  <th scope="col">Customer</th>
				  	  <th scope="col">Start</th>
					  <th scope="col">Users</th>
					  <th scope="col">Moderators</th>
					  <th scope="col">Chat</th>
					  <th scope="col">Streams</th>
					  <th scope="col">Build</th>
					  <th scope="col">Join Meeting</th>
					</tr>
				  </thead>
				  
				  <tbody>		
					
					{this.state.live_meeting_data.map((entry, i) => {
						
						return (<tr key={entry.meetingid}>
									<td className="col tr">{i+1}</td>
									<td className="col tr">{entry.meetingid}</td>
									<td className="col tr">{entry.meeting_name}</td>
									<td className="col tr">{entry.customer}</td>
									<td className="col tr">{new Date(parseInt(entry.start_time)).toString()}</td>
									<td className="col tr">{entry.participant_count}</td>
									<td className="col tr">{entry.full_name}</td>
									<td className="col tr">{entry.chat}</td>
									<td className="col tr">{entry.video_count}</td>
									<td className="col tr">{entry.build}</td>
									<td className="col tr"><a href={entry.join_url} target="_blank"><button type="button" className="btn btn-dark">Join!</button></a></td>
							   </tr>)
						 
					})}
					
					</tbody>
				</table>
				</div>
				}
				
			</div>
		);
	}
}

export default Lighthouse;