import React from 'react'

function Navbar(props){
	return(
		<nav className="navbar navbar-expand-lg navbar-light bg-light">
		  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon"></span>
		  </button>
		  <div className="collapse navbar-collapse" id="navbarSupportedContent">
			<ul className="navbar-nav mr-auto">
			  <li className="nav-item">
				<a className="nav-link" href="/home">Home <span className="sr-only"></span></a>
			  </li>
			  
			  <li className="nav-item dropdown">
				<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				  Server events
				</a>
				<div className="dropdown-menu" aria-labelledby="navbarDropdown">
				  <a className="dropdown-item" href="/launchserver">Launch Server</a>
				  <a className="dropdown-item" href="/moveserver">Moveall Server</a>
				  <a className="dropdown-item" href="/destroyserver">Destroy Server</a>
				  <a className="dropdown-item" href="/moveuser">Move User</a>
				  <a className="dropdown-item" href="/create-image">Create Server image</a>
				</div>
			  </li>

			  
			  <li className="nav-item dropdown">
				<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				  Schedules
				</a>
				<div className="dropdown-menu" aria-labelledby="navbarDropdown">
				  <a className="dropdown-item" href="/schedule">Daily Schedule</a>
				  <a className="dropdown-item" href="/moveschedule">Move Schedule</a>
				  <a className="dropdown-item" href="/moveuserschedule">Move User Schedule</a>
				  <a className="dropdown-item" href="/permschedule">Perm Schedule</a>
				  <a className="dropdown-item" href="/autoscaleschedule">Autoscale Schedule</a>
				  <a className="dropdown-item" href="/asgschedule">Asg Schedule</a>
				  <a className="dropdown-item" href="/ovhschedule">OVH Schedule</a>
					{/*<a className="dropdown-item" href="/dailycheck">Daily Check Table</a>*/}
				</div>
			  </li>
			  
			  <li className="nav-item dropdown">
				<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				  Analytics
				</a>
				<div className="dropdown-menu" aria-labelledby="navbarDropdown">
			  	  <a className="dropdown-item" href="/analytics-summary">Analytics Summary</a>
				  <a className="dropdown-item" href="/group-analytics-summary">Group Analytics Summary</a>
				  <a className="dropdown-item" href="/server-analytics-summary">Server Analytics Summary</a>
				</div>
			  </li>
			  
			  <li className="nav-item dropdown">
				<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				  Dangling servers
				</a>
				<div className="dropdown-menu" aria-labelledby="navbarDropdown">
				  <a className="dropdown-item" href="/danglingAwsSpinner">Aws - Spinner</a>
				  <a className="dropdown-item" href="/danglingDoSpinner">DO - Spinner</a>
				  <a className="dropdown-item" href="/danglingDoExtra">DO - Extra</a>
				  <a className="dropdown-item" href="/staleServers"> Stale Servers</a>
				  <a className="dropdown-item" href="/zabbixFailed"> Zabbix Failed</a>
				  <a className="dropdown-item" href="/zabbixDisabled"> Zabbix Disabled</a>
				</div>
			  </li>

			  {/*<li className="nav-item">
				<a className="nav-link" href="/status">Status</a>
			  </li>*/}
			  
			  <li className="nav-item">
				<a className="nav-link" href="/clear-dns">Extra DNS entries</a>
			  </li>
			  
			</ul>
		  </div>

		  <li className="nav-item">
			<a className="nav-link" href="/help">Help</a>
		  </li>

		  <li className="nav-item">
			<a className="nav-link" href="#">{props.bnEmail}</a>
		  </li>		  
		</nav>
	);
}

export default Navbar;