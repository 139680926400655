import React from "react";
import Navbar from '../../components/Nav'

const NotFoundPage = () => {
	return(
		<div className="pageBody">
			<Navbar />
			<h3>404 Error. Page Not Found</h3>
		</div>
	);
}

export default NotFoundPage;
