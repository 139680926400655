import React, { Component } from 'react'

class HelpComponent extends Component {
	
    render() {
        return(
            <div className="help_component">
            
            	<div className="row">
            		<div className="col-3">
            			<div className="fixed">
							<ul>
								<p>Server Events</p>
								<li>
									<a href="#server_events#servernaming">Server naming scheme &amp; meaning</a>
								</li>
								<li>
									<a href="#server_events#launch">launch servers</a>
								</li>
								<li>
									<a href="#server_events#move">move servers</a>
								</li>
								<li>
									<a href="#server_events#destroy">force destroy servers</a>
								</li>
								
								<br></br>
								<p>Schedules</p>
								<li>
									<a href="#schedules#add">add</a>
								</li>
								<li>
									<a href="#schedules#edit">edit</a>
								</li>
								<li>
									<a href="#schedules#delete">delete</a>
								</li>
								
								<br></br>
								<p>Analytics</p>
								<li>
									<a href="#analytics#info">info</a>
								</li>
								
								<br></br>
								<p>Dangling</p>
								<li>
									<a href="#dangling#delete">delete</a>
								</li>
								
								<br></br>
								<p>Recording Process</p>
								<li>
									<a href="#rec#recid">rec-id</a>
								</li>
								<li>
									<a href="#rec#recstatus">rec-status</a>
								</li>
								
							</ul>
          				
           				</div>
            		</div>
            		<div className="col-8">
            			<div className="card" id="server_events#servernaming"> 
							  <div className="card-body">
								<h5 className="card-title">Sever Naming: Meaning 📢</h5>

								<strong><p className="card-text">Note: The naming scheme extends to all groups except whitelist. Whitelist group will still use the old format eg mXXXXXX.rna1 for rna1 or aXXXXXX.roc2 for roc2 and so on.</p></strong>

								<p className="card-text">The new naming scheme has 4 alphabets before the number. eg:- myaaXXXXXX.rna1.blindsidenetworks.com
								  <br></br>First letter represents the region. (m = rna1, e = reu1, a = roc2, c = rna2)
								  <br></br>Second letter represents whether it is a dynamic or permanent server. (y = dynamic, x = permanent)
								  <br></br>Third letter represents the cloud provider. (d = digitalocean, a = aws) 
								  <br></br>Fourth letter represents the BBB-build version. (a = 2.2, b = 2.3)
								  <br></br>Whitelist server example:- m001047.rna1.blindsidenetworks.com (number between 1000-1100 is whitelist-perm &amp; 1101-1200 is whitelist-dyn)
								  <br></br>Other server example:- myaa083701.rna1.blindsidenetworks.com</p>
							  </div>
						</div>
            		
						<div className="card" id="server_events#launch"> 
							  <div className="card-body">
								<h5 className="card-title">Sever Events: Launch 🚀</h5>
								
								<strong><p className="card-text">Note: The submit button will not appear until all info is filled appropriately</p></strong>
								
								<p className="card-text">For do servers you will have to pick an image from the dropdown however for Aws no image needs to be provided(it will use latest).</p>
								
								<p className="card-text">The initial group is where the servers sits while configuring itself, make sure no users are pointed at the group you enter in the field. Final group is the actual group you want the servers to be used for aka production.</p>
							  </div>
						</div>
           		
           				<div className="card" id="server_events#move"> 
							  <div className="card-body">
								<h5 className="card-title">Sever Events: Move 🤸‍♀️</h5>
								
								<p className="card-text">There are only two pieces of info needed the group you want the servers to move to and the list of servers. </p>
								
								<p>Default group is set to drain-then-destroy. Move any servers you want to detsroy in this group.</p>
								
								<p className="card-text">The list of servers can be copy pasted from the lb or you can make your own list in a text editor. Only requirement is each servername be on a new line in full.
								  <br></br>Example:-
								  <br></br>m001047.rna1.blindsidenetworks.com
								  <br></br>m001147.rna1.blindsidenetworks.com
								  <br></br>myaa083701.rna1.blindsidenetworks.com	BBB 2.2	running	7	70	52.5%	200	 Edit  Panic
								  <br></br>mxda087604.rna1.blindsidenetworks.com	BBB 2.2	running	4	64	42.0%	200	 Edit  Panic</p>
							  </div>
						</div>
           		
						<div className="card" id="server_events#destroy"> 
							  <div className="card-body">
								<h5 className="card-title">Sever Events: Force Destroy 💣</h5>

								<strong><p className="card-text">Note: This is a force destroy that will bypass any checks. Any users on these servers will experience a sudden disconnect. If you want to destroy servers normally just move them to drain-then-destroy group using the move tool.</p></strong>

								<p className="card-text">The list of servers can be copy pasted from the lb or you can make your own list in a text editor. Only requirement is each servername be on a new line in full.
								  <br></br>Example:-
								  <br></br>m001047.rna1.blindsidenetworks.com
								  <br></br>m001147.rna1.blindsidenetworks.com
								  <br></br>myaa083701.rna1.blindsidenetworks.com	BBB 2.2	running	7	70	52.5%	200	 Edit  Panic
								  <br></br>mxda087604.rna1.blindsidenetworks.com	BBB 2.2	running	4	64	42.0%	200	 Edit  Panic</p>
							  </div>
						</div>
           		
           				<div className="card" id="schedules#add"> 
							  <div className="card-body">
								<h5 className="card-title">Schedules: Add ➕</h5>
								
								<strong><p className="card-text">Note: The submit button will not appear until all info is filled appropriately. Also any time that needs to be entered should be in UTC format eg. 17:00 or 02:00 (XX:XX). Only display times are in your local time.</p></strong>
								
								<p className="card-text">To add a new entry in a schedule click on the schedule you want and click the create new entry button at the top</p>

								<p className="card-text">Once you click the button you can enter all the info asked and hit submit.</p>
							  </div>
						</div>
           		
           				<div className="card" id="schedules#edit"> 
							  <div className="card-body">
								<h5 className="card-title">Schedules: Edit 📝</h5>
								
								<strong><p className="card-text">Note: Any time that needs to be entered should be in UTC format eg. 17:00 or 02:00 (XX:XX). Only display times are in your local time.</p></strong>
								
								<p className="card-text">Click edit on the entry you want to modify. It should have all the original info already entered. Edit info as you need and hit submit</p>
							  </div>
						</div>
           		
           				<div className="card" id="schedules#delete"> 
							  <div className="card-body">
								<h5 className="card-title">Schedules: Delete ❌</h5>
								
								<p className="card-text">Idk its a one button thing you cannot mess this up. 🐒</p>
							  </div>
						</div>
           		
           				<div className="card" id="analytics#info"> 
							  <div className="card-body">
								<h5 className="card-title">Analytics: Info ℹ️</h5>
								
								<strong><p className="card-text">Note: Info can be seen for a maximum of 7 days. Above the graph there are boxes for the individual graph info, you can hide/show this individual info by clicking the appropriate box.</p></strong>
								
								<p className="card-text">Enter the info asked and hit submit.</p>
							  </div>
						</div>
           		
           				<div className="card" id="dangling#delete"> 
							  <div className="card-body">
								<h5 className="card-title">Dangling: Delete 🗑️</h5>
								
								<strong><p className="card-text">Note: Do - extra are servers not managed by spinner. Do not delete those servers unless you know what you are doing.</p></strong>
								
								<p className="card-text">Idk its a one button thing you cannot mess this up. 🐒</p>
							  </div>
						</div>
           		
           				<div className="card" id="rec#recid"> 
							  <div className="card-body">
								<h5 className="card-title">Recording Process: Rec Id</h5>
								
								<p className="card-text">You can only search by recording id.</p>
							  </div>
						</div>
           		
           				<div className="card" id="rec#recstatus"> 
							  <div className="card-body">
								<h5 className="card-title">Recording Process: Rec Status</h5>
								
								<strong><p className="card-text">Note: Date and Time are both optional, but if you enter a time you have to enter a date. Time that needs to be entered should be in UTC format eg. 17 or 17:00 or 17:00:00. Enter time to the specificity you seek</p></strong>
								
								<p className="card-text">On entering either status or status and date or status and date and time hit submit.</p>
							  </div>
						</div>
            		</div>
            	</div>
            
            
				
           
           		
            </div>
        );
    }
}

export default HelpComponent;
