import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class UpdateDailyDyn extends Component {
	state = {
			id: this.props.location.state.id,
			customer: this.props.location.state.customer,
			version: this.props.location.state.version,
			group: this.props.location.state.group,
			lbRegion: this.props.location.state.lbRegion,
			cloudProvider: this.props.location.state.cloudProvider,
			cloudRegion: this.props.location.state.cloudRegion,
			launchTime:this.props.location.state.launchTime,
			number: this.props.location.state.number,
			image: this.props.location.state.image,
			environment: this.props.location.state.environment,
			isEnable: this.props.location.state.isEnable,
			timezone: this.props.location.state.timezone,
			days: this.props.location.state.days,
			tableName: "daily_dyns",
			submit: false
	}
	
	changeHandler = (e) => {
	  const { name, value, type, checked } = e.target;

	  if (type === 'checkbox') {
	    this.setState((prevState) => ({
	      ...prevState,
	      days: {
	        ...prevState.days,
	        [value]: checked,
	      },
	    }), () => {
	      // console.log(this.state);
	    });
	  } else {
	    this.setState({
	      [name]: value,
	    }, () => {
	      // console.log(this.state);
	    });
	  }
	}
	
	submitHandler = (e) => {
		e.preventDefault();
		
		//console.log(this.state)
		this.setState({
			submit: true
		})
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/edit-tabledata';
		const url = api_url + "edit-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/edit-tabledata';
		//axios.post(url, this.state)
		axios.post(url, this.state, axiosConfig)
		.then(response => {

			response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: JSON.stringify(response['data']['data']) });
			
			
		})
		
		
	}
	
	render() {
		//console.log(this.props);
		const {lbRegion, customer, version, cloudProvider, cloudRegion, launchTime, number, group, image, environment, isEnable, timezone, days} = this.state
		return(
			<div className="pageBody">
				<Navbar />
				<h3>Create new entry for daily dyn</h3>
				<p>{this.props.location.search}</p>
				<form onSubmit={this.submitHandler} className="container">
					  
					  <div className="form-group">
						<label>Customer</label>
						<select name="customer" className="form-control" id="customer"  value={customer} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose customer</option>
							<option>blindsidenetworks</option>
						</select>
					  </div>
					  
					  <div className="form-group">
						<label>Version</label>
						<select name="version" className="form-control" id="version"  value={version} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose BBB-build version</option>
							<option>2_6</option>
							<option>2_7</option>
							<option>3_0</option>
						</select>
					  </div>

					  <div className="form-group">
						<label>Group</label>
						<input type="text" name="group" className="form-control" id="group" placeholder="Group name" value={group} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Number</label>
						<input type="text" name="number" className="form-control" id="number" placeholder="how many servers eg. 1" value={number} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Region</label>
						<select name="lbRegion" className="form-control" id="lbRegion"  value={lbRegion} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>rna1</option>
							<option>reu1</option>
							<option>roc2</option>
							<option>rna2</option>
						</select>
					  </div>
					  
					  <div className="form-group">
						<label>Launch Time</label>
						<input type="text" name="launchTime" className="form-control" id="launchTime" placeholder="launch time in UTC eg. 10:15" value={launchTime} onChange={this.changeHandler}></input>
					  </div>
					  
					 {this.state.lbRegion === "rna1" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>America/New_York</option>
							<option>America/Los_Angeles</option>
							<option>America/Mexico_City</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "reu1" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>Europe/London</option>
							<option>Europe/Paris</option>
							<option>Europe/Amsterdam</option>
							<option>Europe/Athens</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "roc2" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>Asia/Manila</option>
							<option>Asia/Singapore</option>
							<option>Australia/Sydney</option>
							<option>Australia/Perth</option>
							<option>Australia/Adelaide</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "rna2" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>America/Toronto</option>
							<option>America/Halifax</option>
							<option>America/Vancouver</option>
						</select>
					  </div>}

					  <div className="form-group">
						<label>Cloud Provider</label>
						<select name="cloudProvider" className="form-control" id="cloudProvider" value={cloudProvider} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>select provider</option>
							<option>do</option>
							<option>aws</option>
						</select>
					  </div>


					  {this.state.lbRegion === "rna1" && this.state.cloudProvider === "aws" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>us-east-2</option>
							<option>us-west-2</option>
						</select>
					  </div>}

					  {this.state.lbRegion === "reu1" && this.state.cloudProvider === "aws" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>eu-central-1</option>
							<option>eu-west-2</option>
						</select>
					  </div>}

					  {this.state.lbRegion === "roc2" && this.state.cloudProvider === "aws" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>ap-southeast-1</option>
							<option>ap-southeast-2</option>
						</select>
					  </div>}

					  {this.state.lbRegion === "rna2" && this.state.cloudProvider === "aws" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>ca-central-1</option>
							<option>us-east-2</option>
						</select>
					  </div>}


					  {this.state.lbRegion === "rna1" && this.state.cloudProvider === "do" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>nyc3</option>
							<option>nyc1</option>
							<option>sfo1</option>
							<option>sfo2</option>
							<option>sfo3</option>
							<option>tor1</option>
						</select>
					  </div>}

					  {this.state.lbRegion === "reu1" && this.state.cloudProvider === "do" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>ams3</option>
						</select>
					  </div>}				  

					  {this.state.lbRegion === "roc2" && this.state.cloudProvider === "do" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>sgp1</option>
							<option>blr1</option>
						</select>
					  </div>}

					  {this.state.lbRegion === "rna2" && this.state.cloudProvider === "do" &&
					  <div className="form-group">
						<label>Cloud Region</label>
						<select name="cloudRegion" className="form-control" id="cloudRegion" value={cloudRegion} onChange={this.changeHandler}>
							<option value="" selected disabled>choose cloud region</option>
							<option>tor1</option>
						</select>
					  </div>}
					  
					  <div className="form-group">
						<label>Image (Optional)</label>
						<input type="text" name="image" className="form-control" id="image" placeholder="Leave blank to use latest" value={image} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Environment</label>
						<select name="environment" className="form-control" id="environment"  value={environment} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>Choose env to launch in?</option>
							<option>dev</option>
							<option>prod</option>
						</select>
					  </div>
					  
					  <div className="form-group">
						<label>Enable</label>
						<select name="isEnable" className="form-control" id="isEnable"  value={isEnable} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>enable this launch?</option>
							<option>true</option>
							<option>false</option>
						</select>
					  </div>

					  <div className="form-group">
						<label className="mx-5">Days</label>
						<label className="mx-2">
					        <input
					          type="checkbox"
					          value="Monday"
					          checked={days['Monday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Monday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Tuesday"
					          checked={days['Tuesday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Tuesday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Wednesday"
					          checked={days['Wednesday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Wednesday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Thursday"
					          checked={days['Thursday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Thursday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Friday"
					          checked={days['Friday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Friday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Saturday"
					          checked={days['Saturday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Saturday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Sunday"
					          checked={days['Sunday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Sunday
					    </label>

					  </div>


					{group && customer && version && lbRegion && cloudProvider && number && launchTime && timezone && <button type="submit" className="btn btn-primary">Submit</button>}

				</form>
				
				{this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
			</div>
		);
	}
}

export default UpdateDailyDyn;
