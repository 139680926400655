import React, { Component } from 'react';
import axios from 'axios';
import {
  Link,
} from "react-router-dom";

class DailyCheck extends Component {
	state = {
		api_data: []
	}
	
	async fetchData(){
		var tableObj = {tableName: "daily_checks"}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/get-tabledata';
		const url = api_url + "get-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/get-tabledata';
    	//const apiData = await axios.post(url, tableObj)
		const apiData = await axios.post(url, tableObj, axiosConfig)
      	.then((data) =>
			//console.log(data['data']['body'])	
			//JSON.parse(data['data']['body'])
			data['data']
		).then((apiData) => this.setState({ api_data: apiData}));
		
		
	}
	
	async componentDidMount() {
		try {
			await this.fetchData();
		} catch (error) {
            console.error(error);
        }
	}

	deleteEntry(id) {
		var deleteObj = {
			tableName: "daily_checks",
			id: id
		}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/delete-tabledata';
		const url = api_url + "delete-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/delete-tabledata';
		//axios.post(url, deleteObj)
		axios.post(url, deleteObj, axiosConfig)
		.then(response => {
			response['status'] != 200 ? this.setState({ response: false,responseText: response['status'] }) : this.setState({ response: true, responseText: response['data']['data'] });
			var index = this.state.api_data.findIndex(x => x.id === id);
			  
			if (index >= 0) {
			  var array = this.state.api_data.splice(index, 1);
			  this.setState({api_data: this.state.api_data})
			}			
		})
	}

	async edEntry(id, lbRegion, group, number, isEnable) {
		isEnable = !isEnable;
		var editObj = {
			tableName: "daily_checks",
			id: id,
			group: group,
			lbRegion: lbRegion,
			number: number,
			isEnable: isEnable
		}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
			
		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/edit-tabledata';
		
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/edit-tabledata';
		//axios.post(url, editObj)
		axios.post(url, editObj, axiosConfig)
		.then(response => {

			response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: response['data']['body'] });
			
			
		})
		
		try {
			await this.fetchData();
		} catch (error) {
            console.error(error);
        }
		
	}
	
    render() {
		
        return(
            <div className="daily_check">
            
            	<h3>Daily Check Table</h3>
            
            	<a href="/create-daily-check"><button type="button" className="btn btn-dark center-block dd-create">Create new entry</button></a>
            	
            	
            	
            	<h4 className="text-left">Rna1</h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="thead-dark">
					<tr>
				      <th scope="col">Id</th>
					  <th scope="col">Region</th>
					  <th scope="col">Group</th>
					  <th scope="col">Number</th>
					  <th scope="col">Enable</th>
					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.lb_region === 'rna1' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.lb_region}</td>
									<td className="col tr">{entry.prod_group}</td>
									<td className="col tr">{entry.number}</td>
									<td className="col tr">{entry.is_enable.toString()}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-daily-check",
												state: {id: entry.id, 
														group: entry.prod_group,
														lbRegion: entry.lb_region,
														number: entry.number,
														isEnable: entry.is_enable,
														tableName: "daily_check"}
										    }}

								 >Edit</Link> </button> </td>
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm(entry.is_enable ? 'Disable the entry?' : 'Enable the entry?')){this.edEntry(entry.id, entry.lb_region, entry.prod_group, entry.number, entry.is_enable)};}}>{entry.is_enable ? 'Disable' : 'Enable'}</button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
           	
           		<h4 className="text-left">Rna2</h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="thead-dark">
					<tr>
				      <th scope="col">Id</th>
					  <th scope="col">Region</th>
					  <th scope="col">Group</th>
					  <th scope="col">Number</th>
					  <th scope="col">Enable</th>
					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.lb_region === 'rna2' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.lb_region}</td>
									<td className="col tr">{entry.prod_group}</td>
									<td className="col tr">{entry.number}</td>
									<td className="col tr">{entry.is_enable.toString()}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-daily-check",
												state: {id: entry.id, 
														group: entry.prod_group,
														lbRegion: entry.lb_region,
														number: entry.number,
														isEnable: entry.is_enable,
														tableName: "daily_check"}
										    }}

								 >Edit</Link> </button> </td>
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm(entry.is_enable ? 'Disable the entry?' : 'Enable the entry?')){this.edEntry(entry.id, entry.lb_region, entry.prod_group, entry.number, entry.is_enable)};}}>{entry.is_enable ? 'Disable' : 'Enable'}</button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
            	
            	<h4 className="text-left">Reu1</h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="thead-dark">
					<tr>
				      <th scope="col">Id</th>
					  <th scope="col">Region</th>
					  <th scope="col">Group</th>
					  <th scope="col">Number</th>
					  <th scope="col">Enable</th>
					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.lb_region === 'reu1' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.lb_region}</td>
									<td className="col tr">{entry.prod_group}</td>
									<td className="col tr">{entry.number}</td>
									<td className="col tr">{entry.is_enable.toString()}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-daily-check",
												state: {id: entry.id, 
														group: entry.prod_group,
														lbRegion: entry.lb_region,
														number: entry.number,
														isEnable: entry.is_enable,
														tableName: "daily_check"}
										    }}

								 >Edit</Link> </button> </td>
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm(entry.is_enable ? 'Disable the entry?' : 'Enable the entry?')){this.edEntry(entry.id, entry.lb_region, entry.prod_group, entry.number, entry.is_enable)};}}>{entry.is_enable ? 'Disable' : 'Enable'}</button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
           	
           		<h4 className="text-left">Roc2</h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="thead-dark">
					<tr>
				      <th scope="col">Id</th>
					  <th scope="col">Region</th>
					  <th scope="col">Group</th>
					  <th scope="col">Number</th>
					  <th scope="col">Enable</th>
					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.lb_region === 'roc2' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.lb_region}</td>
									<td className="col tr">{entry.prod_group}</td>
									<td className="col tr">{entry.number}</td>
									<td className="col tr">{entry.is_enable.toString()}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-daily-check",
												state: {id: entry.id, 
														group: entry.prod_group,
														lbRegion: entry.lb_region,
														number: entry.number,
														isEnable: entry.is_enable,
														tableName: "daily_check"}
										    }}

								 >Edit</Link> </button> </td>
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm(entry.is_enable ? 'Disable the entry?' : 'Enable the entry?')){this.edEntry(entry.id, entry.lb_region, entry.prod_group, entry.number, entry.is_enable)};}}>{entry.is_enable ? 'Disable' : 'Enable'}</button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
            	
            	
            	
            </div>
        );
    }
}

export default DailyCheck;