import React from "react";
import Navbar from '../components/Nav'
import AsgSchedule from '../components/AsgSchedule'

const AsgSchedulePage = (props) => {
	return(
		<div className="pageBody">
			<Navbar />
			
			<div className="container">
				<AsgSchedule />
			</div>
			
			
		</div>
	)
}

export default AsgSchedulePage;