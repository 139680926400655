import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';
// import { Line } from 'react-chartjs-2';
import Chart from 'chart.js';

class ServerAnalyticsSummaryPage extends Component {
	constructor(props) {
	    super(props);
	    this.chartRef = React.createRef();
		this.state = {
			submit: false,
			server: null,
			history: 1,
			data: {
				labels: [1, 2, 3, 4, 5],
				datasets: [
					{
					label: "Load %",
					borderColor: "rgba(255, 0, 255, 0.75)",
					backgroundColor: "rgba(255, 0, 255, 0)",
					data: []
					},
					{
					label: "Users",
					borderColor: "rgba(255, 0, 0, 0.75)",
					backgroundColor: "rgba(255, 0, 0, 0)",
					data: [],
					hidden: true
					},
					{
					label: "Meetings",
					borderColor: "rgba(0, 0, 255, 0.75)",
					backgroundColor: "rgba(0, 0, 255, 0)",
					data: [],
					hidden: true
					},
					{
					label: "state",
					borderColor: "rgba(100, 100, 255, 0.75)",
					backgroundColor: "rgba(150, 0, 150, 0)",
					data: []
					}
				]
			}
		}
	}

	changeHandler = (e) => {

		var stateObject = {};
		stateObject[e.target.name] = e.target.value;
		stateObject['data'] = {
				labels: [1, 2, 3, 4, 5],
				datasets: [
					{
					label: "Load %",
					borderColor: "rgba(255, 0, 255, 0.75)",
					backgroundColor: "rgba(255, 0, 255, 0)",
					data: []
					},
					{
					label: "Users",
					borderColor: "rgba(255, 0, 0, 0.75)",
					backgroundColor: "rgba(255, 0, 0, 0)",
					data: [],
					hidden: true
					},
					{
					label: "Meetings",
					borderColor: "rgba(0, 0, 255, 0.75)",
					backgroundColor: "rgba(0, 0, 255, 0)",
					data: [],
					hidden: true
					},
					{
					label: "state",
					borderColor: "rgba(100, 100, 255, 0.75)",
					backgroundColor: "rgba(150, 0, 150, 0)",
					data: []
					}
				]
			};
		this.setState(stateObject);
	}
	
	componentDidMount() {
	    this.renderChart();
	  }

	componentDidUpdate() {
    	this.renderChart();
  	}

	renderChart() {
	    const chartElement = this.chartRef.current;
	    const ctx = chartElement.getContext('2d');

	    new Chart(ctx, {
	      type: 'line',
	      data: this.state.data,
	    });
	  }

	submitHandler = async(e) => {
		e.preventDefault();
		var srv = this.state.server
		var tableObj = {hostname: srv}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		
		const url = api_url + "get-analytics"
		
		const apiData = await axios.post(url, tableObj, axiosConfig)
      	.then((data) =>
			data['data']
		);
		
		console.log(apiData);
		
		var server_data;
		var num_server;
		var history_check = parseInt(this.state.history)*120;
		
		if(apiData.server_data.length === 0){
			server_data = null;
			num_server = 0;	
		} else {
			server_data = apiData.server_data;
			num_server = apiData.server_data.length;
			if(num_server > history_check) {
				server_data = server_data.slice(0, history_check);
			}
		}		
		
		var labels = [];
		var load = [];
		var users = [];
		var meetings = [];
		var state = [];

		for (const ele of server_data) {
			labels.push(ele["created_at"]);
			load.push(ele["load"]);
			users.push(ele["users"]);
			meetings.push(ele["meetings"]);
			if(ele["server_state"] == 'running'){
				state.push(1);	
			} else {
				state.push(0);
			}
		}
		
		this.setState({submit: true, data: { 
							labels: labels.reverse(),
							datasets: [
								{
								label: "Load %",
								borderColor: "rgba(255, 0, 255, 0.75)",
								backgroundColor: "rgba(255, 0, 255, 0)",
								data: load.reverse()
								},
								{
								label: "Users",
								borderColor: "rgba(255, 0, 0, 0.75)",
								backgroundColor: "rgba(255, 0, 0, 0)",
								data: users.reverse(),
								hidden: true
								},
								{
								label: "Meetings",
								borderColor: "rgba(0, 0, 255, 0.75)",
								backgroundColor: "rgba(0, 0, 255, 0)",
								data: meetings.reverse(),
								hidden: true
								},
								{
								label: "state",
								borderColor: "rgba(100, 100, 255, 0.75)",
								backgroundColor: "rgba(255, 0, 255, 0)",
								hidden: true,
								data: state.reverse()
								}
							]
						}
		})
	}
	
	render() {
		const {server, history} = this.state;
		
		return(
			<div className="pageBody">
				<Navbar />

				<div className="container">
					<h1>Server Analytics Summary</h1>
					
					<form onSubmit={this.submitHandler} className="container">
						<div className="form-group">
							<label>Server Name</label>
							<input type="text" name="server" className="form-control" id="server" placeholder="Enter full server name. eg. myaa85768.rna1.blindsidenetworks.com or axdb87654.roc2.blindsidenetworks.com" value={server} onChange={this.changeHandler}></input>
						</div>
					
						<div className="form-group">
							<label>History to show in days</label>
							<select name="history" className="form-control" id="history"  value={history} onChange={this.changeHandler}>
								<option value="" selected disabled hidden>choose history in days</option>
								<option>1</option>
								<option>2</option>
								<option>3</option>
								<option>4</option>
								<option>5</option>
								<option>6</option>
								<option>7</option>
							</select>
						</div>
						<button type="submit" className="btn btn-primary">Submit</button>
					</form>
					
					
					<div className="chart">
						<h4>Server : {this.state.server}</h4>
						<canvas ref={this.chartRef} />		
					</div>
					
					
				</div>


			</div>
		)
	}
}

export default ServerAnalyticsSummaryPage;