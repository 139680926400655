import React, { Component } from 'react';
import NavbarRP from '../../components/NavRP'
import axios from 'axios';

class rpMeetingId extends Component {
	state = {
		meetingId: "",
		submit: false
	}
	
	changeHandler = (e) => {

		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		//console.log(e.target.name);
		this.setState(stateObject);

	}
	
	submitHandler = async (e) => {
		e.preventDefault();
		
		//console.log(this.state)
		this.setState({
			submit: true
		})
		//const url = `https://i2vyu2o5al.execute-api.us-east-2.amazonaws.com/staging/recording/status/${this.state.recordId}`;
		const url = `https://qve3jgkr0f.execute-api.us-west-2.amazonaws.com/prod/get-status`
		
		let axiosConfig = {
		  headers: {
			  "X-API-Key": `${process.env.REACT_APP_RECORDING_STATUS}`
		  }
		};
		
		await axios.post(url, {"meetingid": this.state.meetingId}, axiosConfig)
		.then(response => {
			//console.log(response["data"])
			
			//response['data'].hasOwnProperty('Item') ? this.setState({ response: true, responseText: response['data']['Item']} ) : this.setState({ response: false, responseText: 'Error' });
			response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: response['data']['body'] });
			
			
		})
		
		//console.log(this.state)
		
		/*this.setState({
			recordId: "",
			submit: false,
			response: false,
			responseText: null
		})*/
		
	}
	
	render() {
		const {meetingId} = this.state
		return(
			<div className="rpRecId">
				<NavbarRP />
				<h3>Find by Meeting Id</h3>

				<form onSubmit={this.submitHandler} className="container">

					<div className="form-group">
						<label>Meeting Id</label>
						<input type="text" name="meetingId" className="form-control" id="meetingId" placeholder="Enter meetingId" value={meetingId} onChange={this.changeHandler}></input>
					</div>

					<button type="submit" className="btn btn-primary">Submit</button>

				</form>
				
				<div className="container">
					<table className="table table-striped table-hover table-gap">
					  <thead className="table-dark">
						<tr>
						  <th scope="col">Meeting Id</th>
						  <th scope="col">Hostname</th>
						  <th scope="col">Status</th>
						</tr>
					  </thead>

					  <tbody>		

						{this.state.response && 

							<tr key={this.state.responseText.pk}>
								<td className="col tr">{this.state.responseText.pk.replace("#REC_STATUS#MEETINGID#", "")}</td>
								{/*<td className="col tr">{this.state.responseText.lastStatus.replace("REC_STATUS#", "")}</td>*/}
								<td className="col tr">{this.state.responseText.host}</td>
								<td className="col tr">{JSON.stringify(this.state.responseText.status)}</td>
							</tr>

							}

						</tbody>
					</table>
					
					{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText}</p>}
					
				</div>
				
				
				
			</div>
		);
	}
}

export default rpMeetingId;