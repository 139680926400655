import React from "react";
import Navbar from '../components/Nav'
import Schedule from '../components/Schedule'

const SchedulePage = (props) => {
	return(
		<div className="pageBody">
			<Navbar />
			
			<div className="container">
				<Schedule />
			</div>
			
			
		</div>
	)
}

export default SchedulePage;