import React, { Component } from 'react';
import axios from 'axios';
import {
  Link,
} from "react-router-dom";

class Schedule extends Component {
	state = {
		api_data: [],
	}

	async fetchData(){
		var tableObj = {tableName: "daily_dyns"}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/get-tabledata';
		const url = api_url + "get-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/get-tabledata';
    	//const apiData = await axios.post(url, tableObj)
		const apiData = await axios.post(url, tableObj, axiosConfig)
      	.then((data) =>
			//console.log(data['data']['body'])
			//JSON.parse(data['data']['body'])
			data['data']
		).then((apiData) => this.setState({ api_data: apiData}));
		
	}

	async componentDidMount() {
		try {
			await this.fetchData();
		} catch (error) {
            console.error(error);
        }
		
	}

	deleteEntry(id) {
		var deleteObj = {
			tableName: "daily_dyns",
			id: id
		}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/delete-tabledata';
		const url = api_url + "delete-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/delete-tabledata';
		//axios.post(url, deleteObj)
		axios.post(url, deleteObj, axiosConfig)
		.then(response => {
			//console.log(response)
			response['status'] != 200 ? this.setState({ response: false,responseText: response['status'] }) : this.setState({ response: true, responseText: response['data']['data'] });
			var index = this.state.api_data.findIndex(x => x.id === id);
			  
			if (index >= 0) {
			  var array = this.state.api_data.splice(index, 1);
			  this.setState({api_data: this.state.api_data})
			}			
		})
	}

	async edEntry(id, customer, version, group, lbRegion, cloudRegion, cloudProvider, number, launchTime, image, environment, isEnable, timezone, days) {
		isEnable = !isEnable;
		var editObj = {
			tableName: "daily_dyns",
			id: id,
			customer: customer,
			version: version,
			group: group,
			lbRegion: lbRegion,
			cloudProvider: cloudProvider,
			cloudRegion: cloudRegion,
			launchTime: launchTime,
			number: number,
			image: image,
			isEnable: isEnable,
			timezone: timezone,
			environment: environment,
			days: days
		}
		
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
			
		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		const url = api_url + 'edit-tabledata';
		axios.post(url, editObj, axiosConfig)
		.then(response => {
			// console.log(response['data']['data']);
			this.fetchData();
		})
		return
		try {
			await this.fetchData();
		} catch (error) {
            console.error(error);
        }
		
	}
	
	getDays(daysString){
		let daysObject = {
			    Monday: false,
			    Tuesday: false,
			    Wednesday: false,
			    Thursday: false,
			    Friday: false,
			    Saturday: false,
			    Sunday: false,
			}
		if (daysString == null){
			return daysObject
		}
		const selectedDaysArray = daysString.split(',').map(day => day.trim());
		// Define an array of all days of the week
		const allDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

		// Create an object with all days initialized to false
		daysObject = allDays.reduce((acc, day) => {
		  acc[day] = false;
		  return acc;
		}, {});

		// Set the selected days to true
		selectedDaysArray.forEach(selectedDay => {
		  if (daysObject.hasOwnProperty(selectedDay)) {
		    daysObject[selectedDay] = true;
		  }
		});

		// Now daysObject contains the desired structure
		return daysObject;
	}

	getLocalTime(t) {
		var today = new Date()
		var dd = today.getDate()

		var mm = today.getMonth()+1
		var yyyy = today.getFullYear()
		if(dd<10) 
		{
			dd='0'+dd
		} 

		if(mm<10) 
		{
			mm='0'+mm
		} 
		today = yyyy+'-'+mm+'-'+dd
		
		var formatted_time = `${today} ${t} UTC`
		
		var local_time = new Date(formatted_time)
		
		
		var currentHours = ('0'+local_time.getHours()).substr(-2);
		var mins = ('0'+local_time.getMinutes()).slice(-2);
		
		var ampm = "am";
		var hr = currentHours
		if( hr >= 12 ) {
			//hr -= 12;
			ampm = "pm";
		}
		
		var display_time = `${currentHours}:${mins} ${ampm}`
		
		return display_time
	}
	
    render() {
		
        return(
            <div className="daily_schedule">
            
            	<h3>Daily Schedule</h3>
            
            	<a href="/create-daily-dyn"><button type="button" className="btn btn-dark center-block dd-create">Create new entry</button></a>
            	
            	<h4 className="text-left">ROC2 Schedule </h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				  	  <th scope="col">ID</th>
				  	  <th scope="col">CUSTOMER</th>
				  	  <th scope="col">VERSION</th>
					  <th scope="col">GROUP</th>
					  <th scope="col">REGION</th>
					  <th scope="col">LOCAL REGION</th>
					  <th scope="col">PROVIDER</th>
					  <th scope="col">NUMBER</th>
					  <th scope="col">LAUNCH TIME (Local time)</th>
					  <th scope="col">days</th>
					  <th scope="col">TIMEZONE</th>
					  <th scope="col">Image (Leave nil to use latest)</th>
					  <th scope="col">Env</th>
					  <th scope="col">Enable</th>
					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.region === 'roc2' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.customer}</td>
									<td className="col tr">{entry.version}</td>
									<td className="col tr">{entry.group}</td>
									<td className="col tr">{entry.region}</td>
									<td className="col tr">{entry.local_region}</td>
									<td className="col tr">{entry.provider}</td>
									<td className="col tr">{entry.number}</td>
									{/*<td className="col tr">{this.getLocalTime(entry.launch_time)}</td>*/}
									<td className="col tr">{entry.launch_time}</td>
									<td className="col tr">{entry.days}</td>
									<td className="col tr">{entry.timezone}</td>
									<td className="col tr">{entry.image}</td>
									<td className="col tr">{entry.environment}</td>
									<td className="col tr">{entry.is_enable.toString()}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-daily-dyn",
												state: {id: entry.id,
														customer: entry.customer,
														version: entry.version,
														group: entry.group,
														lbRegion: entry.region,
														cloudProvider:entry.provider,
														cloudRegion: entry.local_region,
														launchTime: entry.launch_time,
														number: entry.number,
														image: entry.image,
														environment: entry.environment,
														isEnable: entry.is_enable,
														timezone: entry.timezone,
														days: this.getDays(entry.days),
														tableName: "dailydyn"}
										  }}

								 >Edit</Link> </button> </td>
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm(entry.is_enable ? 'Disable the entry?' : 'Enable the entry?')){this.edEntry(entry.id, entry.customer, entry.version, entry.group, entry.region, entry.local_region, entry.provider, entry.number, entry.launch_time, entry.image, entry.environment, entry.is_enable, entry.timezone, this.getDays(entry.days))};}}>{entry.is_enable ? 'Disable' : 'Enable'}</button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)
						 
					})}
					
					</tbody>
				</table>

				
				

				<h4 className="text-left">REU1 Schedule </h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				  	  <th scope="col">Id</th>
				  	  <th scope="col">CUSTOMER</th>
				  	  <th scope="col">VERSION</th>
					  <th scope="col">GROUP</th>
					  <th scope="col">REGION</th>
					  <th scope="col">LOCAL REGION</th>
					  <th scope="col">PROVIDER</th>
					  <th scope="col">NUMBER</th>
					  <th scope="col">LAUNCH TIME (Local time)</th>
					  <th scope="col">days</th>
					  <th scope="col">TIMEZONE</th>
					  <th scope="col">Image (Leave nil to use latest)</th>
					  <th scope="col">Env</th>
					  <th scope="col">Enable</th>
					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.region === 'reu1' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.customer}</td>
									<td className="col tr">{entry.version}</td>
									<td className="col tr">{entry.group}</td>
									<td className="col tr">{entry.region}</td>
									<td className="col tr">{entry.local_region}</td>
									<td className="col tr">{entry.provider}</td>
									<td className="col tr">{entry.number}</td>
									<td className="col tr">{entry.launch_time}</td>
									<td className="col tr">{entry.days}</td>
									<td className="col tr">{entry.timezone}</td>
									<td className="col tr">{entry.image}</td>
									<td className="col tr">{entry.environment}</td>
									<td className="col tr">{entry.is_enable.toString()}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-daily-dyn",
												state: {id: entry.id,
														customer: entry.customer,
														version: entry.version,
														group: entry.group,
														lbRegion: entry.region,
														cloudProvider:entry.provider,
														cloudRegion: entry.local_region,
														launchTime: entry.launch_time,
														number: entry.number,
														image: entry.image,
														environment: entry.environment,
														isEnable: entry.is_enable,
														timezone: entry.timezone,
														days: this.getDays(entry.days),
														tableName: "dailydyn"}
										  }}

								 >Edit</Link> </button> </td>
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm(entry.is_enable ? 'Disable the entry?' : 'Enable the entry?')){this.edEntry(entry.id, entry.customer, entry.version, entry.group, entry.region, entry.local_region, entry.provider, entry.number, entry.launch_time, entry.image, entry.environment, entry.is_enable, entry.timezone)};}}>{entry.is_enable ? 'Disable' : 'Enable'}</button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)
						 
					})}
					
					</tbody>
				</table>

				
				
				

				<h4 className="text-left">RNA1 Schedule </h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				  	  <th scope="col">Id</th>
				  	  <th scope="col">CUSTOMER</th>
				  	  <th scope="col">VERSION</th>
					  <th scope="col">GROUP</th>
					  <th scope="col">REGION</th>
					  <th scope="col">LOCAL REGION</th>
					  <th scope="col">PROVIDER</th>
					  <th scope="col">NUMBER</th>
					  <th scope="col">LAUNCH TIME (Local time)</th>
					  <th scope="col">days</th>
					  <th scope="col">TIMEZONE</th>
					  <th scope="col">Image (Leave nil to use latest)</th>
					  <th scope="col">Env</th>
					  <th scope="col">Enable</th>
					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.region === 'rna1' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.customer}</td>
									<td className="col tr">{entry.version}</td>
									<td className="col tr">{entry.group}</td>
									<td className="col tr">{entry.region}</td>
									<td className="col tr">{entry.local_region}</td>
									<td className="col tr">{entry.provider}</td>
									<td className="col tr">{entry.number}</td>
									<td className="col tr">{entry.launch_time}</td>
									<td className="col tr">{entry.days}</td>
									<td className="col tr">{entry.timezone}</td>
									<td className="col tr">{entry.image}</td>
									<td className="col tr">{entry.environment}</td>
									<td className="col tr">{entry.is_enable.toString()}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-daily-dyn",
												state: {id: entry.id,
														customer: entry.customer,
														version: entry.version,
														group: entry.group,
														lbRegion: entry.region,
														cloudProvider:entry.provider,
														cloudRegion: entry.local_region,
														launchTime: entry.launch_time,
														number: entry.number,
														image: entry.image,
														environment: entry.environment,
														isEnable: entry.is_enable,
														timezone: entry.timezone,
														days: this.getDays(entry.days),
														tableName: "dailydyn"}
										  }}

								 >Edit</Link> </button> </td>
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm(entry.is_enable ? 'Disable the entry?' : 'Enable the entry?')){this.edEntry(entry.id, entry.customer, entry.version, entry.group, entry.region, entry.local_region, entry.provider, entry.number, entry.launch_time, entry.image, entry.environment, entry.is_enable, entry.timezone)};}}>{entry.is_enable ? 'Disable' : 'Enable'}</button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)

						 
					})}
					
					</tbody>
				</table>
           
           
           
           
           		<h4 className="text-left">RNA2 Schedule </h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				  	  <th scope="col">Id</th>
				  	  <th scope="col">CUSTOMER</th>
				  	  <th scope="col">VERSION</th>
					  <th scope="col">GROUP</th>
					  <th scope="col">REGION</th>
					  <th scope="col">LOCAL REGION</th>
					  <th scope="col">PROVIDER</th>
					  <th scope="col">NUMBER</th>
					  <th scope="col">LAUNCH TIME (Local time)</th>
					  <th scope="col">days</th>
					  <th scope="col">TIMEZONE</th>
					  <th scope="col">Image (Leave nil to use latest)</th>
					  <th scope="col">Env</th>
					  <th scope="col">Enable</th>
					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.region === 'rna2' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.customer}</td>
									<td className="col tr">{entry.version}</td>
									<td className="col tr">{entry.group}</td>
									<td className="col tr">{entry.region}</td>
									<td className="col tr">{entry.local_region}</td>
									<td className="col tr">{entry.provider}</td>
									<td className="col tr">{entry.number}</td>
									<td className="col tr">{entry.launch_time}</td>
									<td className="col tr">{entry.days}</td>
									<td className="col tr">{entry.timezone}</td>
									<td className="col tr">{entry.image}</td>
									<td className="col tr">{entry.environment}</td>
									<td className="col tr">{entry.is_enable.toString()}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-daily-dyn",
												state: {id: entry.id,
														customer: entry.customer,
														version: entry.version,
														group: entry.group,
														lbRegion: entry.region,
														cloudProvider:entry.provider,
														cloudRegion: entry.local_region,
														launchTime: entry.launch_time,
														number: entry.number,
														image: entry.image,
														isEnable: entry.is_enable,
														timezone: entry.timezone,
														days: this.getDays(entry.days),
														tableName: "dailydyn"}
										  }}

								 >Edit</Link> </button> </td>
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm(entry.is_enable ? 'Disable the entry?' : 'Enable the entry?')){this.edEntry(entry.id, entry.customer, entry.version, entry.group, entry.region, entry.local_region, entry.provider, entry.number, entry.launch_time, entry.image, entry.environment, entry.is_enable, entry.timezone)};}}>{entry.is_enable ? 'Disable' : 'Enable'}</button> </td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)

						 
					})}
					
					</tbody>
				</table>
           
           
           
            </div>
        );
    }
}

export default Schedule;
