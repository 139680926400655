import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class ClearDns extends Component {
	
	state = {
		api_data: [],
		entries: []
	}
	
	changeHandler = (entry, e) => {
		//e.preventDefault();
		
		let checked = e.target.checked;
		let name = e.target.name
		
		if(checked) {
		  this.setState(prevState => ({
			entries: [...prevState.entries, entry]
		  }));
		} else {
		  this.setState(prevState => ({
			entries: prevState.entries.filter(one => one.id !== entry.id)
		  }));
		}
		
		//console.log(this.state.entries);

	}

	async componentDidMount() {
		var tableObj = {tableName: "dangling_dns"}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/get-tabledata';
		const url = api_url + "get-tabledata"
		const apiData = await axios.post(url, tableObj, axiosConfig)
      	.then((data) =>	
			//console.log(data['data']['body'])
			//JSON.parse(data['data']['body'])
			data['data']
		);
		this.setState({ api_data: apiData})
		
		//console.log(this.state.api_data)
	}

	deleteEntries(e) {
		e.preventDefault();
		
		console.log("called delete for");
		console.log(this.state.entries);
		
		if (window.confirm("Confirm deletion of entries?")) {
			let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
			let api_url = process.env.REACT_APP_CONTROLLER_API_URL;
			let axiosConfig = {
			  headers: {
				  "Content-Type": "application/json",
				  "X-API-Key": api_key
			  },
			}
			axios.defaults.headers.common = {
			  "X-API-Key": api_key,
			}

			//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/delete-dangling-dns';
			const url = api_url + "delete-dangling-dns"
			axios.post(url, this.state.entries, axiosConfig)
			.then(response => {
				console.log(response);
				//response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: response['data']['body'] });
			})
		}
		
		this.state.entries.forEach((ele) => { 
			var index = this.state.api_data.findIndex(x => x.id == ele.id);
			if (index >= 0) {
				var array = this.state.api_data.splice(index, 1);
				this.setState({api_data: this.state.api_data})
			}
		});
		
		this.setState({entries: []})
		
	}

	render() {
		
		return (
		
			<div className="clear_dns">
				<Navbar />
				<h4> Clear extra DNS entries </h4>
				
				<button type="button" className="btn btn-dark center-block dd-create" onClick={(e) => this.deleteEntries(e)}>Delete all selected entries</button>
				
				<table className="table table-striped table-hover table-gap container">
				  <thead className="table-dark">
					<tr>
		      	  	  <th scope="col">Select</th>
			      	  <th scope="col">Id</th>
			      	  <th scope="col">Region</th>
				      <th scope="col">Hostname</th>
				      <th scope="col">Ip</th>
				      <th scope="col">Hosted Zone Id</th>
				      <th scope="col">Record type</th>
				      <th scope="col">Ttl</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					  {this.state.api_data && this.state.api_data.map((entry) => {
						
						return (<tr key={entry.id}>
									<td className="col tr">
										<input
											name={`dns_${entry.id}`}
											type="checkbox"
											//checked={this.state.changeHandler}
											onChange={(e) => this.changeHandler(entry, e)} />
									</td>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.region}</td>
									<td className="col tr">{entry.hostname}</td>
									<td className="col tr">{entry.ip}</td>
									<td className="col tr">{entry.hosted_zone_id}</td>
									<td className="col tr">{entry.record_type}</td>
									<td className="col tr">{entry.ttl}</td>
									
								{/*<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id, entry.server_id, entry.hostname)};}}>Delete</button> </td>*/}
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
			
			</div>)

	}
}

export default ClearDns;