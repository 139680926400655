import React, { Component } from 'react';
import Navbar from '../../components/Nav'
import HelpComponent from '../../components/Help'

class HelpPage extends Component {
	state = {
		
	}
	
	async componentDidMount() {
		
	}
	
	render() {
		const {difference, status} = this.state
		return(
			<div className="pageBody">
				<Navbar />
				
				<HelpComponent />
			</div>
		);
	}
}

export default HelpPage;