import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class MoveServerPage extends Component {
	
	componentDidMount() {
		//this.getDOImages(this.state.lbRegion)
	}
	
	state = {
		submit: false,
		responseText: '',
		response: '',
		serverList: "",
		bnEmail: this.props.bnEmail,
		group: "drain-then-destroy"
	}

	changeHandler = (e) => {

		var stateObject = {};
		stateObject[e.target.name] = e.target.value;
		//console.log(e.target.name);
		this.setState(stateObject);
	}

	submitHandler = async(e) => {
		e.preventDefault();
		
		if (window.confirm("Confirm move all servers?")) {
			let newlines = this.state.serverList.split("\n");

			let serverArray = []
			newlines.forEach(function (item, index) {
			  let servername = item.split(" ")[0];

			  servername = servername.split("	")[0]
			  serverArray.push(servername)
			});

			//console.log(serverArray);

			this.setState({
				submit: true
			})
			
			let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
			let api_url = process.env.REACT_APP_WORKER_API_URL;

			let axiosConfig = {
			  headers: {
				  "Content-Type": "application/json",
				  "X-API-Key": api_key
			  },
			}
			axios.defaults.headers.common = {
			  "X-API-Key": api_key,
			}

			//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/spinner-serverless';
			const url = api_url + "spinner-serverless"
			//axios.post(url, {data: serverArray, group: this.state.group})
			axios.post(url, {"job": "moveall_server", "servers": serverArray, "group": this.state.group, "bn_email": this.state.bnEmail}, axiosConfig)
			.then(response => {
				console.log('printing your response')
				console.log(response)
				//response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: response['data']['body'] });
				response['status'] != 200 ? this.setState({ response: false,responseText: response['status'].toString() }) : this.setState({ response: true, responseText: response['status'].toString() });


			})

			this.setState({
				serverList: "",
				group: "drain-then-destroy"
			})
		}
		
	}
	
	
	

	render() {
		
		const {serverList, group, bnEmail} = this.state
		return (
		
			<div>
				<Navbar bnEmail={bnEmail} />
				
				<form onSubmit={this.submitHandler} className="container">
					
				  <div className="form-group">
					<label>Group</label>
					<input type="text" name="group" className="form-control" id="group" placeholder="Enter target group" value={group} onChange={this.changeHandler}></input>
				  </div>

				  <div className="form-group">
					<label>Move Servers</label>
					<textarea className="form-control" name="serverList" id="serverList" placeholder="Copy list of servers into textarea from LB" value={serverList} rows="7" onChange={this.changeHandler}></textarea>
				  </div>
				  
				  
				  <button type="submit" className="btn btn-primary">Move Servers</button>

				  	
				</form>
				
				
				{this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
				
			</div>)

	}
}

export default MoveServerPage;