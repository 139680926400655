import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class DanglingDOSpinner extends Component {
	
	state = {
		api_data: []
	}

	async componentDidMount() {
		var tableObj = {tableName: "dangling_servers"}
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/get-tabledata';
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/get-tabledata';
		const url = api_url + "get-tabledata"
    	//const apiData = await axios.post(url, tableObj)
		const apiData = await axios.post(url, tableObj, axiosConfig)
      	.then((data) =>
			//console.log(data['data']['body'])	
			//JSON.parse(data['data']['body'])
			data['data']
		);
		this.setState({ api_data: apiData})
	}


	deleteEntry(id, serverId, cloudRegion, ipv4, hostname) {
		var deleteObj = {
			serverId: serverId
		}
		
//		var deleteObj = {
//			job: "destroy",
//			hostname: hostname
//		}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/delete-dangling-server';
		const url = api_url + "delete-dangling-server"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/delete-dangling-server';
		//axios.post(url, deleteObj)
		axios.post(url, deleteObj, axiosConfig)
		.then(response => {
			response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: response['data']['data'] });
			var index = this.state.api_data.findIndex(x => x.id === id);
			  
			if (index >= 0) {
			  var array = this.state.api_data.splice(index, 1);
			  this.setState({api_data: this.state.api_data})
			}
		})
	}

	render() {
		
		return (
		
			<div className="dangling_server">
				<Navbar />
				<h4> Digitalocean dangling servers</h4>
				<table className="table table-striped table-hover table-gap container">
				  <thead className="table-dark">
					<tr>
				      <th scope="col">Hostname</th>
				      <th scope="col">Ipv4</th>
				      <th scope="col">Ipv6</th>
				      <th scope="col">DropletId</th>
					  <th scope="col">Image</th>
					  <th scope="col">Creation Date</th>
					  <th scope="col">DO Region</th>
					  <th scope="col">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.cloud_provider === 'do' && entry.owner === 'spinner' && <tr key={entry.id}>
									<td className="col tr">{entry.hostname}</td>
									<td className="col tr">{entry.ipv4}</td>
									<td className="col tr">{entry.ipv6}</td>
									<td className="col tr">{entry.server_id}</td>
									<td className="col tr">{entry.image}</td>
									<td className="col tr">{entry.created_on}</td>
									<td className="col tr">{entry.cloud_region}</td>
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id, entry.server_id, entry.cloud_region, entry.ipv4, entry.hostname)};}}>Delete</button> </td>
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
			
			</div>)

	}
}

export default DanglingDOSpinner;