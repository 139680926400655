import React, { Component, Link, useState } from 'react';
// import { SRLWrapper } from "simple-react-lightbox";
import NavbarST from '../../components/NavST'
import axios from 'axios';
// import ReactPaginate from "react-paginate";

class LaunchTest extends Component {
	state = {
		number_of_meetings: "",
		number_of_tasks: "",
		users_per_task: "",
		meetingid: "",
		test_file: "",
		block_number: "",
		bbb_server: "",
		bbb_secret: "",
		algorithm: "SHA1",
		skip_audio: false,
		screen_share: false,
		new_meeting: false,
		moderators: false,
		join_url: [],
		mod_url: []
	}
	
	changeHandler = (e) => {
		let val = e.target.value;
		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		this.setState(stateObject);
		console.log(stateObject);
	}


	checkboxHandler = (e) => {
		let val = e.target.value;
		var stateObject = {};
		let name = e.target.name;
		stateObject[e.target.name] = !this.state[name];

		this.setState(stateObject, () => {
			console.log(this.state[name]);	
		});
	}
	
	submitHandler = async(e) => {
		e.preventDefault();
		console.log(this.state)
		let api_key = process.env.REACT_APP_STESTING_API_KEY;
		let api_obj = this.state
		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		const url = 'https://15dkkchofb.execute-api.us-east-2.amazonaws.com/Prod/launch-test';
		const apiData = await axios.post(url, api_obj, axiosConfig)
      	.then((data) => 
			data['data']
		).then((apiData) => 			
			this.setState({join_url: apiData['join_urls'], mod_url: apiData["mod_urls"]})
		);
		
		console.log(this.state)
	}
	render() {
		const {number_of_meetings, users_per_task, number_of_tasks, meetingid, test_file, block_number, bbb_server, bbb_secret, skip_audio, screen_share, new_meeting, moderators, join_url, mod_url, algorithm} = this.state
		
		return(
			<div className="stest-launch">
			
				<NavbarST />
				<h3>Launch Test</h3>
				
				<form onSubmit={this.submitHandler} className="container">

					  <div className="form-group">
						<label>BBB server</label>
						<input type="text" name="bbb_server" className="form-control" id="bbb_server" placeholder="BBB Server" value={bbb_server} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>BBB Server Secret</label>
						<input type="text" name="bbb_secret" className="form-control" id="bbb_secret" placeholder="BBB Server Secret" value={bbb_secret} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Meeting ID</label>
						<input type="text" name="meetingid" className="form-control" id="meetingid" placeholder="Meeting ID" value={meetingid} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Number of Meetings</label>
						<input type="text" name="number_of_meetings" className="form-control" id="number_of_meetings" placeholder="Total Meetings" value={number_of_meetings} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Users per Task</label>
						<input type="text" name="users_per_task" className="form-control" id="users_per_task" placeholder="Number of users in each task (integer between 1 to 10)" value={users_per_task} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Total Tasks per Meeting</label>
						<input type="text" name="number_of_tasks" className="form-control" id="number_of_tasks" placeholder="Number of tasks per meeting" value={number_of_tasks} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Batch Number</label>
						<input type="text" name="block_number" className="form-control" id="block_number" placeholder="number of batches you launched in same meeting" value={block_number} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Test File</label>
						<input type="text" name="test_file" className="form-control" id="test_file" placeholder="s3 script name" value={test_file} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-check">
						<input className="form-check-input" name="new_meeting" type="checkbox" id="new_meeting" onChange={this.checkboxHandler} ></input>
						<label className="form-check-label" htmlFor="new_meeting">
						  New Meeting
						</label>
					  </div>
					
					  <div className="form-check">
						<input className="form-check-input" name="skip_audio" type="checkbox" id="skip_audio" onChange={this.checkboxHandler}></input>
						<label className="form-check-label" htmlFor="skip_audio">
						  Skip Audio
						</label>
					  </div>

					  <div className="form-check">
						<input className="form-check-input" name="screen_share" type="checkbox" id="screen_share" onChange={this.checkboxHandler}></input>
						<label className="form-check-label" htmlFor="screen_share">
						  Screen Share
						</label>
					  </div>

					  <div className="form-check">
						<input className="form-check-input" name="moderators" type="checkbox" id="moderators" onChange={this.checkboxHandler} ></input>
						<label className="form-check-label" htmlFor="moderators">
						  All Moderators
						</label>
					  </div>					  					  
					  <br/>
					  <h3 className="col-sm-1">
					  		Algorithm
					  </h3>
					  <div className="form-check">
						<input className="form-check-input" name="algorithm" type="radio" 
							value="SHA1" id="sha1" checked={algorithm === "SHA1"} 
							onChange={this.changeHandler}>
						</input>
						<label className="form-check-label" htmlFor="sha1">
						  SHA1
						</label>
					  </div>
					  
					  <div className="form-check">
						<input className="form-check-input" name="algorithm" type="radio" 
							value="SHA256" id="sha256" checked={algorithm === "SHA256"}
							onChange={this.changeHandler} >
						</input>
						<label className="form-check-label" htmlFor="sha256">
						  SHA256
						</label>
					  </div>
					  
					  <div className="form-check">
						<input className="form-check-input" name="algorithm" type="radio"
						 	value="SHA512" id="sha512" checked={algorithm === "SHA512"}
						 	onChange={this.changeHandler} >						 		
						 </input>
						<label className="form-check-label" htmlFor="sha512">
						  SHA512
						</label>
					  </div>					  					  
					  <br/>

					{<button type="submit" className="btn btn-primary">Submit</button>}

				</form>
				
				<br/>
				
				<table className="table table-striped table-hover table-gap">
				  <thead className="thead-dark">
					<tr>
				  	  <th scope="col">Moderator join link</th>
					</tr>
				  </thead>
				  
				  <tbody>
				
				{this.state.mod_url && this.state.mod_url.map((entry) => {
						
						return (<tr key={entry} className="container">
							   	  <td className="col tr"><a href={entry} target="_blank">{entry}</a></td>
							   </tr>)
						 
				})}
				
				  </tbody>
				</table>
				
		    
		    	<table className="table table-striped table-hover table-gap">
				  <thead className="thead-dark">
					<tr>
				  	  <th scope="col">Attendee join link</th>
					</tr>
				  </thead>
				  
				  <tbody>
			    {this.state.join_url && this.state.join_url.map((entry) => {
						
						return (<tr key={entry} className="container">
								  <td className="col tr"><a href={entry} target="_blank">{entry}</a></td>
							   </tr>)
						 
				})}
					
				  </tbody>
				</table>
						
				
			</div>
		);
	}
}

export default LaunchTest;
