import React, { Component, Link, useState } from 'react';
import NavbarRS from '../../components/NavRS'
import axios from 'axios';

class GetEladCustomers extends Component {
	state = {
		api_data: [],
		response: ''
	}
	
	async componentDidMount() {
		var tableData = {tableName: "elad_customers"}
        let api_key = process.env.REACT_APP_ANALYTICS_CALLBACK_API_KEY;
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                "X-API-Key": api_key
            },
        }
        axios.defaults.headers.common = {
            "X-API-Key": api_key,
        }
        let acb_url = process.env.REACT_APP_ANALYTICS_CALLBACK_API_URL;
        const url = acb_url + 'get-elad-customer';
        const apiData = await axios.post(url, tableData, axiosConfig)
        .then((data) =>
            data['data']  
         )
        this.setState({ api_data: apiData})
        console.log(this.state)
    }
	
	deleteEntry(id) {
		var deleteObj = {
			tableName: "elad_customers",
			id: id
		}
		
		let api_key = process.env.REACT_APP_ANALYTICS_CALLBACK_API_KEY;
		let api_url = process.env.REACT_APP_ANALYTICS_CALLBACK_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		
		const url = api_url + 'delete-elad-customer';
		axios.post(url, deleteObj, axiosConfig)
		.then(response => {
			if(response['status'] == 200)
			{
				var index = this.state.api_data.findIndex(x => x.id === id);			  
				if (index >= 0) {
				  var array = this.state.api_data.splice(index, 1);
				  this.setState({api_data: this.state.api_data})
				}
			}
		})
	}
	render() {
		return(
			<div className="analytics-callback">
			
				<NavbarRS />
				<div className="container">
					<h3>ELAD Customers</h3>	           
	                <a href="/rs/create-elad-customer">
	                	<button type="button" className="btn btn-dark center-block dd-create">Create new entry</button>
	                </a>
	                <table className="table table-striped table-hover table-gap container">
					  <thead className="table-dark">
					    <tr>
					      <th scope="col">ID</th>
					      <th scope="col">Customer</th>
					      <th scope="col" colSpan="2">Actions</th>
					    </tr>
					  </thead>
					  
					  <tbody>
					    {this.state.api_data && this.state.api_data.map((entry) => {
					      return (
					        <tr key={entry.id}>
					          <td className="col tr">{entry.id}</td>
					          <td className="col-sm-9 tr">{entry.customer}</td>
					          <td className="col tr">
								  <button
								    className="btn btn-dark btn-md"
								    onClick={() => this.props.history.push({
								      pathname: "/rs/edit-elad-customer",
								      state: { id: entry.id, customer: entry.customer }
								    })}
								  >
								    Edit
								  </button>
								</td>
	  

					          <td className="col tr">					         
					            <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button>
					          </td>
					        </tr>
					      );
					    })}
					  </tbody>
					</table>
				</div>
			</div>
		);
	}
}
export default GetEladCustomers;
