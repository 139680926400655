import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class UpdateMoveDyn extends Component {
	state = {
			id: this.props.location.state.id,
			group: this.props.location.state.group,
			lbRegion: this.props.location.state.lbRegion,
			moveTime:this.props.location.state.moveTime,
			timezone: this.props.location.state.timezone,
			tableName: "move_schedules",
			submit: false
	}
	
	changeHandler = (e) => {

		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		//console.log(e.target.name);
		this.setState(stateObject);

	}
	
	submitHandler = (e) => {
		e.preventDefault();
		
		//console.log(this.state)
		this.setState({
			submit: true
		})
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/edit-tabledata';
		const url = api_url + "edit-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/edit-tabledata';
		//axios.post(url, this.state)
		axios.post(url, this.state, axiosConfig)
		.then(response => {

			response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: JSON.stringify(response['data']['data']) });
			
			
		})
		
	}
	
	render() {
		//console.log(this.props);
		const {lbRegion, moveTime, group, timezone} = this.state
		return(
			<div className="pageBody">
				<Navbar />
				<h3>Update entry for Move Schedule</h3>
				<p>{this.props.location.search}</p>
				
				<form onSubmit={this.submitHandler} className="container">
					  
					  <div className="form-group">
						<label>Region</label>
						<select name="lbRegion" className="form-control" id="lbRegion"  value={lbRegion} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose LB</option>
							<option>rna1</option>
							<option>reu1</option>
							<option>roc2</option>
							<option>rna2</option>
						</select>
					  </div>
					  
					  {this.state.lbRegion === "rna1" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>America/New_York</option>
							<option>America/Los_Angeles</option>
							<option>America/Mexico_City</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "reu1" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>Europe/London</option>
							<option>Europe/Paris</option>
							<option>Europe/Amsterdam</option>
							<option>Europe/Athens</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "roc2" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>Asia/Manila</option>
							<option>Asia/Singapore</option>
							<option>Australia/Sydney</option>
							<option>Australia/Perth</option>
							<option>Australia/Adelaide</option>
						</select>
					  </div>}
					  
					  {this.state.lbRegion === "rna2" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>America/Toronto</option>
							<option>America/Halifax</option>
							<option>America/Vancouver</option>
						</select>
					  </div>}

					  <div className="form-group">
						<label>Group</label>
						<input type="text" name="group" className="form-control" id="group" placeholder="Group name" value={group} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Move Time</label>
						<input type="text" name="moveTime" className="form-control" id="moveTime" placeholder="Move time in UTC eg. 05:00" value={moveTime} onChange={this.changeHandler}></input>
					  </div>

					{group && lbRegion && timezone && <button type="submit" className="btn btn-primary">Submit</button>}

				</form>
				
				{this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
			</div>
		);
	}
}

export default UpdateMoveDyn;