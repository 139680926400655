import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class UpdateOvhMove extends Component {
	state = {
			id: this.props.location.state.id,
			lb_group: this.props.location.state.lbGroup,
			lb_region: this.props.location.state.lbRegion,
			move_time:this.props.location.state.launchTime,
			is_enable: this.props.location.state.isEnable,
			timezone: this.props.location.state.timezone,
			servers: this.props.location.state.servers,
			days: this.props.location.state.days,
			tableName: "ovh_move",
			submit: false,
	}
	
	changeHandler = (e) => {
	  const { name, value, type, checked } = e.target;

	  if (type === 'checkbox') {
	    this.setState((prevState) => ({
	      ...prevState,
	      days: {
	        ...prevState.days,
	        [value]: checked,
	      },
	    }), () => {
	      // console.log(this.state);
	    });
	  } else {
	    this.setState({
	      [name]: value,
	    }, () => {
	      // console.log(this.state);
	    });
	  }
	}
	
	submitHandler = (e) => {
		e.preventDefault();
		this.setState({
			submit: true
		})
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		const url = api_url + "edit-tabledata"
		axios.post(url, this.state, axiosConfig)
		.then(response => {
			response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: JSON.stringify(response['data']['data']) });
		})		
	}
	
	render() {
		const {lb_region, move_time, lb_group, is_enable, timezone, servers, days} = this.state
		return(
			<div className="pageBody">
				<Navbar />
				<h3>Edit entry for OVH schedule</h3>
				<p>{this.props.location.search}</p>
				<form onSubmit={this.submitHandler} className="container">
					  
					  <div className="form-group">
						<label>Group</label>
						<input type="text" name="lb_group" className="form-control" id="lb_group" placeholder="Group name" value={lb_group} onChange={this.changeHandler}></input>
					  </div>

					  <div className="form-group">
						<label>Servers</label>
						<input type="textarea" name="servers" className="form-control" id="servers" placeholder="servers within curly braces with comma separated values; example: {server1.rna1.vlindsidenetworks.com,server2.rna1.blindsidenetworks.com}" value={servers} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Region</label>
						<select name="lb_region" className="form-control" id="lb_region"  value={lb_region} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose Region</option>
							<option>rna1</option>
							<option>reu1</option>
							<option>roc2</option>
							<option>rna2</option>
						</select>
					  </div>
					  
					  <div className="form-group">
						<label>Move Time</label>
						<input type="text" name="move_time" className="form-control" id="move_time" placeholder="move time in UTC eg. 10:15" value={move_time} onChange={this.changeHandler}></input>
					  </div>
					  
					 {this.state.lb_region === "rna1" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>America/New_York</option>
							<option>America/Los_Angeles</option>
							<option>America/Mexico_City</option>
						</select>
					  </div>}
					  
					  {this.state.lb_region === "reu1" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>Europe/London</option>
							<option>Europe/Paris</option>
							<option>Europe/Amsterdam</option>
							<option>Europe/Athens</option>
						</select>
					  </div>}
					  
					  {this.state.lb_region === "roc2" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>Asia/Manila</option>
							<option>Asia/Singapore</option>
							<option>Australia/Sydney</option>
							<option>Australia/Perth</option>
							<option>Australia/Adelaide</option>
						</select>
					  </div>}
					  
					  {this.state.lb_region === "rna2" && <div className="form-group">
						<label>Timezone</label>
						<select name="timezone" className="form-control" id="timezone"  value={timezone} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose timezone</option>
							<option>America/Toronto</option>
							<option>America/Halifax</option>
							<option>America/Vancouver</option>
						</select>
					  </div>}
					  
					  <div className="form-group">
						<label>Enable</label>
						<select name="is_enable" className="form-control" id="is_enable"  value={is_enable} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>enable this launch?</option>
							<option>true</option>
							<option>false</option>
						</select>
					  </div>

					  <div className="form-group">
						<label className="mx-5">Days</label>
						<label className="mx-2">
					        <input
					          type="checkbox"
					          value="Monday"
					          checked={days['Monday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Monday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Tuesday"
					          checked={days['Tuesday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Tuesday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Wednesday"
					          checked={days['Wednesday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Wednesday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Thursday"
					          checked={days['Thursday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Thursday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Friday"
					          checked={days['Friday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Friday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Saturday"
					          checked={days['Saturday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Saturday
					    </label>
					    <label className="mx-2">
					        <input
					          type="checkbox"
					          value="Sunday"
					          checked={days['Sunday']}
					          onChange={this.changeHandler}
					          className="mx-2"
					        />
					        Sunday
					    </label>

					  </div>

					{lb_group && lb_region && move_time && timezone && servers && <button type="submit" className="btn btn-primary">Submit</button>}

				</form>
				
				{this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
			</div>
		);
	}
}

export default UpdateOvhMove;
