import React, { Component, Link, useState } from 'react';
// import { SRLWrapper } from "simple-react-lightbox";
import NavbarST from '../../components/NavST'
import axios from 'axios';
// import ReactPaginate from "react-paginate";

class Stesting extends Component {
	state = {
		filter: "",
		bucket_name: "",
		test_name: "",
		api_data: [],
		list_data: [],
	}
	
	async componentDidMount() {
		/*try {
			await this.fetchData();
		} catch (error) {
            console.error(error);
        }*/
		
	}
	
	async fetchData(entry){
		
		let api_key = process.env.REACT_APP_STESTING_API_KEY;
		let api_obj = {bucket_name: this.state.bucket_name, meetingid: entry, action: "screenshots"}
		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		const url = 'https://15dkkchofb.execute-api.us-east-2.amazonaws.com/Prod/get-screenshots';
		
		const apiData = await axios.post(url, api_obj, axiosConfig)
      	.then((data) => 
			//console.log(data)
			data['data']
			 
		).then((apiData) => this.setState({
			list_data: [],
			api_data: apiData,	
			
		}));
	}
	
	changeHandler = (e) => {
		let val = e.target.value;
		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		this.setState(stateObject);
		
		/*if(this.state.api_data.includes(val)){
			this.getServer(val);
		}*/
		
		/*if(val == ""){
			this.fetchData();
			this.setState({ live_meeting_data: [] });
		}*/
	}
	
	submitHandler = async(e) => {
		e.preventDefault();
		
		let api_key = process.env.REACT_APP_STESTING_API_KEY;
		let api_obj = {bucket_name: this.state.bucket_name, meetingid: this.state.test_name, action: "list"}
		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		const url = 'https://15dkkchofb.execute-api.us-east-2.amazonaws.com/Prod/get-screenshots';
		
		const apiData = await axios.post(url, api_obj, axiosConfig)
      	.then((data) => 
			//console.log(data)
			data['data']
			 
		).then((apiData) => this.setState({ 
			list_data: apiData,	
			
		}));
	}
	
	render() {
		const {filter, test_name, bucket_name} = this.state
		
		return(
			<div className="stest-screenshot">
			
				<NavbarST />
				<h3>Stress testing</h3>
				
				<br/>
				
				<form onSubmit={this.submitHandler} className="container">
					  
					  <div className="form-group">
						<label>Bucket name</label>
						<select name="bucket_name" className="form-control" id="bucket_name"  value={bucket_name} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose bucket name</option>
							<option>stest-result</option>
						</select>
					  </div>

					  <div className="form-group">
						<label>Test name</label>
						<input type="text" name="test_name" className="form-control" id="test_name" placeholder="Test name" value={test_name} onChange={this.changeHandler}></input>
					  </div>

					{bucket_name && <button type="submit" className="btn btn-primary">Submit</button>}

				</form>
				
				<br/>
				
				<div className="row">
				{this.state.list_data && this.state.list_data.map((entry, i) => {
					return (<div className="col-12 text-center">	
								<a key={i} onClick={() => this.fetchData(entry)} className="link-primary">{entry}</a>
							</div>
					)
				})}
				</div>
				
				{this.state.api_data.length != 0 && <div><label>Filter: &nbsp;</label>
				<input type="text" name="filter" value={filter} onChange={(e) => this.changeHandler(e)} /></div>}
				
				{/*<SRLWrapper>*/}
					<div className="row">

						{this.state.api_data && this.state.api_data.filter(name => name.includes(this.state.filter)).map((entry, i) => {
							return(<div className="col-3">
								<div className="card">
									<a href={entry} id="pop">
										<img src={entry} alt={entry} className="card-img-top img-responsive"></img>
									</a>
									<div className="card-body">
										<p className="card-text">{entry}</p>
									</div>
								</div>
							</div>)
						})}

					</div>
				{/*</SRLWrapper>*/}
			</div>
		);
	}
}

export default Stesting;
