import React from 'react'

function NavbarST(){
	return(
		<nav className="navbar navbar-expand-lg navbar-light bg-light">
		  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon"></span>
		  </button>
		  <div className="collapse navbar-collapse" id="navbarSupportedContent">
			<ul className="navbar-nav mr-auto">
			  <li className="nav-item">
				<a className="nav-link" href="/home">Spinner <span className="sr-only"></span></a>
			  </li>
			  
			  <li className="nav-item">
				<a className="nav-link" href="/stesting/home">Stress-testing</a>
			  </li>

			  <li className="nav-item">
				<a className="nav-link" href="/stesting/launch-test">Launch Test</a>
			  </li>

			  <li className="nav-item">
				<a className="nav-link" href="/stesting/upload">Upload</a>
			  </li>

			  <li className="nav-item">
				<a className="nav-link" href="/stesting/logs">Logs</a>
			  </li>

			</ul>
		  </div>
		  
		  <li className="nav-item">
			<a className="nav-link" href="/help">Help</a>
		  </li>
		</nav>
	);
}

export default NavbarST;