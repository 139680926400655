import React, { Component, Link, useState } from 'react';
// import { SRLWrapper } from "simple-react-lightbox";
import NavbarST from '../../components/NavST'
import axios from 'axios';
// import ReactPaginate from "react-paginate";

class Logs extends Component {
	state = {
		filter: "",
		bucket_name: "",
		test_name: "",
		api_data: []
	}
		
	changeHandler = (e) => {
		let val = e.target.value;
		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		this.setState(stateObject);
	}
	
	submitHandler = async(e) => {
		e.preventDefault();
		
		let api_key = process.env.REACT_APP_STESTING_API_KEY;
		let api_obj = {bucket_name: this.state.bucket_name, meetingid: this.state.test_name, action: "list"}
		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		const url = 'https://15dkkchofb.execute-api.us-east-2.amazonaws.com/Prod/get-logs';
		
		const apiData = await axios.post(url, api_obj, axiosConfig)
      	.then((data) => 
			// console.log(data['data'])
			data['data']
			 
		).then((apiData) => this.setState({
			api_data: apiData
		}));
	}
	
	render() {
		const {filter, test_name, bucket_name} = this.state
		
		return(
			<div className="stest-logs">
			
				<NavbarST />
				<h3>Logs</h3>
				<h6>MeetingID: {test_name} </h6>
				
				<br/>
				
				<form onSubmit={this.submitHandler} className="container">
					  
					  <div className="form-group">
						<label>Bucket name</label>
						<select name="bucket_name" className="form-control" id="bucket_name"  value={bucket_name} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose bucket name</option>
							<option>stest-result</option>
						</select>
					  </div>

					  <div className="form-group">
						<label>Test name</label>
						<input type="text" name="test_name" className="form-control" id="test_name" placeholder="Test name" value={test_name} onChange={this.changeHandler}></input>
					  </div>

					{bucket_name && <button type="submit" className="btn btn-primary">Submit</button>}

				</form>
				
				<br/>
				
				{this.state.api_data.length != 0 && <div><label>Filter: &nbsp;</label>
				<input type="text" name="filter" value={filter} onChange={(e) => this.changeHandler(e)} /></div>}
				
				
				
				<div >
					{this.state.api_data && this.state.api_data.filter(name => name.includes(this.state.filter)).map((entry, i) => {
						
						return(<div className="col-12">
							<div >
								<a href={entry} id="pop">
									<p>{entry}</p>
								</a>
							</div>
						</div>)
						
					})}
				</div>
				
				
			</div>
		);
	}
}

export default Logs;
