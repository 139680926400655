import React from "react";
import Navbar from '../components/Nav'
import Schedule from '../components/Schedule'
import MoveSchedule from '../components/MoveSchedule'

const MoveSchedulePage = (props) => {
	return(
		<div className="pageBody">
			<Navbar />
			
			<div className="container">
				<MoveSchedule />
			</div>
			
			
		</div>
	)
}

export default MoveSchedulePage;