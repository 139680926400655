import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class MoveUserPage extends Component {
	
	componentDidMount() {
		//this.getDOImages(this.state.lbRegion)
	}
	
	state = {
		submit: false,
		responseText: '',
		response: '',
		region: "",
		users: "",
		bnEmail: this.props.bnEmail,
		group: ""
	}

	changeHandler = (e) => {

		var stateObject = {};
		stateObject[e.target.name] = e.target.value;
		//console.log(e.target.name);
		this.setState(stateObject);
	}

	submitHandler = (e) => {
		e.preventDefault();
		
		if (window.confirm("Confirm move all users?")) {

			//console.log(serverArray);

			this.setState({
				submit: true
			})
			
			let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
			let api_url = process.env.REACT_APP_WORKER_API_URL;

			let axiosConfig = {
			  headers: {
				  "Content-Type": "application/json",
				  "X-API-Key": api_key
			  }
			}
			axios.defaults.headers.common = {
			  "X-API-Key": api_key,
			}
			//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/spinner-serverless';
			const url = api_url + "spinner-serverless"
			axios.post(url, {job: "move_user", region: this.state.region, users: this.state.users, group: this.state.group, bn_email: this.state.bnEmail}, axiosConfig)
			.then(response => {
				console.log('printing your response')
				console.log(response)
				response['status'] != 200 ? this.setState({ response: false,responseText: response['status'].toString() }) : this.setState({ response: true, responseText: response['status'].toString() });


			})

			this.setState({
				users: "",
				region: "",
				group: ""
			})
		}
		
	}
	
	
	

	render() {
		
		const {region, users, group, bnEmail} = this.state
		return (
		
			<div>
				<Navbar bnEmail={bnEmail}/>
				
				<form onSubmit={this.submitHandler} className="container">
				  
				  <div className="form-group">
					<label>Load Balancer region</label>
					<select name="region" className="form-control" id="region"  value={region} onChange={this.changeHandler}>
						<option value="" selected disabled hidden>choose LB</option>
					    <option>rna1</option>
					    <option>reu1</option>
					    <option>roc2</option>
					    <option>rna2</option>
					</select>
				  </div>
				  
				  <div className="form-group">
					<label>Users</label>
					<input type="text" name="users" className="form-control" id="users" placeholder="Enter users id to move, eg 1,2,3" value={users} onChange={this.changeHandler}></input>
				  </div>
				  
				  <div className="form-group">
					<label>Group</label>
					<input type="text" name="group" className="form-control" id="group" placeholder="Enter target group" value={group} onChange={this.changeHandler}></input>
				  </div>	  
				  
				  <button type="submit" className="btn btn-primary">Move Servers</button>

				  	
				</form>
				
				
				{this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
				
			</div>)

	}
}

export default MoveUserPage;