import React, { Component } from 'react';
import NavbarRS from '../../components/NavRS'
import axios from 'axios';

class EditEladCustomer extends Component {
	state = {
		tableName: 'elad_customers',
		customer: this.props.location.state.customer,
		id: this.props.location.state.id,
		response: '',
		submit: false
	}
	
	changeHandler = (e) => {
		e.preventDefault();
		var stateObject = {};
		stateObject[e.target.name] = e.target.value;
		this.setState(stateObject);
	}
	
	submitHandler = (e) => {
		e.preventDefault();
		this.setState({
			submit: true
		})
		
		let api_key = process.env.REACT_APP_ANALYTICS_CALLBACK_API_KEY;
		let acb_url = process.env.REACT_APP_ANALYTICS_CALLBACK_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		const url = acb_url + "update-elad-customer"
		axios.post(url, this.state, axiosConfig)
		.then(response => {
			this.setState({ response: response['data']['msg'] });
		})
	}
	
	render() {
		const {customer} = this.state
		return(
			<div className="pageBody">
				<NavbarRS />
				<h3>Add new customer to ELAD</h3>

				<form onSubmit={this.submitHandler} className="container">			
					  <div className="form-group">
						<label>Customer</label>
						<input type="text" name="customer" className="form-control" id="customer" placeholder="customer name" value={customer} onChange={this.changeHandler}></input>
					  </div>
					  				
					{customer && <button type="submit" className="btn btn-primary">Submit</button>}
				</form>
				
				{this.state.response && <p className="alert"> {this.state.response}</p>}				
			</div>
		);
	}
}

export default EditEladCustomer;
