import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class CreateAsg extends Component {
	state = {
			start: "",
			total: '',
			refresh: '',
			serverType: '',
			tableName: "asgs",
			submit: false
	}
	
	changeHandler = (e) => {

		var stateObject = {};
		stateObject[e.target.name] = e.target.value;

		//console.log(e.target.name);
		this.setState(stateObject);

	}
	
	submitHandler = (e) => {
		e.preventDefault();
		
		//console.log(this.state)
		this.setState({
			submit: true
		})
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/create-tabledata';
		const url = api_url + "create-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/create-tabledata';
		//axios.post(url, this.state)
		axios.post(url, this.state, axiosConfig)
		.then(response => {
			response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: response['data']['data'] });
			
			
		})
		
		this.setState({
			start: "",
			total: '',
			refresh: '',
			serverType: '',
			tableName: "asgs",
			submit: false
		})
		
	}
	
	render() {
		const {start, total, refresh, serverType} = this.state
		return(
			<div className="pageBody">
				<Navbar />
				<h3>Create new entry for the Perm Schedule</h3>

				<form onSubmit={this.submitHandler} className="container">
					  
					  <div className="form-group">
						<label>Server Type</label>
						<select name="serverType" className="form-control" id="serverType"  value={serverType} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose server type</option>
							<option>dyn</option>
							<option>perm</option>
							<option>whitelistdyn</option>
							<option>whitelistperm</option>
						</select>
					  </div>

					  <div className="form-group">
						<label>Start</label>
						<input type="text" name="start" className="form-control" id="start" placeholder="Start number" value={start} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Total</label>
						<input type="text" name="total" className="form-control" id="total" placeholder="Total number allowed" value={total} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Refresh</label>
						<input type="text" name="refresh" className="form-control" id="refresh" placeholder="Refresh number" value={refresh} onChange={this.changeHandler}></input>
					  </div>

					{serverType && start && total && refresh && <button type="submit" className="btn btn-primary">Submit</button>}

				</form>
				
				{this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
			</div>
		);
	}
}

export default CreateAsg;