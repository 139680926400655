import React, { Component } from 'react';
import axios from 'axios';
import {
  Link,
} from "react-router-dom";

class MoveUserSchedule extends Component {
	
	componentWillMount() {
		
	}
	
	async componentDidMount() {
		var tableObj = {tableName: "users"}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/get-tabledata';
		const url = api_url + "get-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/get-tabledata';
    	//const apiData = await axios.post(url, tableObj)
		const apiData = await axios.post(url, tableObj, axiosConfig)
      	.then((data) =>
			//console.log(data['data']['body'])	
			//JSON.parse(data['data']['body'])
			data['data']
		);	
		
		this.setState({ api_data: apiData})
	}

	state = {
		api_data: [],
		region: "",
		submit: false,
		bnEmail: this.props.bnEmail
	}

	deleteEntry(id) {
		var deleteObj = {
			tableName: "users",
			id: id
		}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/delete-tabledata';
		const url = api_url + "delete-tabledata"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/delete-tabledata';
		//axios.post(url, deleteObj)
		axios.post(url, deleteObj, axiosConfig)
		.then(response => {
			response['status'] != 200 ? this.setState({ response: false,responseText: response['status'] }) : this.setState({ response: true, responseText: response['data']['data'] });
			var index = this.state.api_data.findIndex(x => x.id === id);
			  
			if (index >= 0) {
			  var array = this.state.api_data.splice(index, 1);
			  this.setState({api_data: this.state.api_data})
			}			
		})
	}

	moveEntry(region, ids, group) {
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_WORKER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}
		
		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/spinner-serverless';
		const url = api_url + "spinner-serverless"
		axios.post(url, {job: "move_user", region: region, users: ids, group: group, bn_email: this.state.bnEmail}, axiosConfig)
		.then(response => {
			console.log('printing your response')
			console.log(response)
			response['status'] != 200 ? this.setState({ region: region, submit:true, response: false,responseText: response['status'].toString() }) : this.setState({ region: region, submit:true, response: true, responseText: response['status'].toString() });


		})
	}

	getDays(daysString){
		const selectedDaysArray = daysString.split(',').map(day => day.trim());
		// Define an array of all days of the week
		const allDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

		// Create an object with all days initialized to false
		const daysObject = allDays.reduce((acc, day) => {
		  acc[day] = false;
		  return acc;
		}, {});

		// Set the selected days to true
		selectedDaysArray.forEach(selectedDay => {
		  if (daysObject.hasOwnProperty(selectedDay)) {
		    daysObject[selectedDay] = true;
		  }
		});

		// Now daysObject contains the desired structure
		return daysObject;
	}

	getLocalTime(t) {
		var today = new Date()
		var dd = today.getDate()

		var mm = today.getMonth()+1
		var yyyy = today.getFullYear()
		if(dd<10) 
		{
			dd='0'+dd
		} 

		if(mm<10) 
		{
			mm='0'+mm
		} 
		today = yyyy+'-'+mm+'-'+dd
		
		var formatted_time = `${today} ${t} UTC`
		
		var local_time = new Date(formatted_time)
		
		
		var currentHours = ('0'+local_time.getHours()).substr(-2);
		var mins = ('0'+local_time.getMinutes()).slice(-2);
		
		var ampm = "am";
		var hr = currentHours
		if( hr >= 12 ) {
			//hr -= 12;
			ampm = "pm";
		}
		
		var display_time = `${currentHours}:${mins} ${ampm}`
		
		return display_time
	}
	
    render() {
		
        return(
            <div className="move_user_schedule">
            	
            	<h3>Move User Schedule</h3>
            
            	<a href="/create-move-user"><button type="button" className="btn btn-dark center-block dd-create">Create new entry</button></a>
            	
            	{this.state.region == "roc2" && this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.region == "roc2" && this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
            	
            	<h4 className="text-left">ROC2 Move</h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				      <th scope="col">Id</th>
					  <th scope="col">Region</th>
					  <th scope="col">User Ids</th>
					  <th scope="col">Source</th>
					  <th scope="col">Destination</th>
					  <th scope="col">Move Time (Local Time)</th>
					  <th scope="col">Timezone</th>
					  <th scope="col">Days</th>
					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.region === 'roc2' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.region}</td>
									<td className="col tr">{entry.userids}</td>
									<td className="col tr">{entry.source}</td>
									<td className="col tr">{entry.destination}</td>
									{/*<td className="col tr">{this.getLocalTime(entry.move_time)}</td>*/}
									<td className="col tr">{entry.move_time}</td>
									<td className="col tr">{entry.timezone}</td>
									<td className="col tr">{entry.days}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-move-user",
												state: {id: entry.id, 
														lbRegion: entry.region,
														userIds: entry.userids,
														source: entry.source,
														destination: entry.destination,
														moveTime: entry.move_time,
														timezone: entry.timezone,
														days: this.getDays(entry.days),
														tableName: "move_user_schedule"}
										    }}

								 >Edit</Link> </button> </td>
								 	
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Move these users?')){this.moveEntry(entry.region, entry.userids, entry.destination)};}}>Move</button> </td>
								 
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
            	
            	{this.state.region == "reu1" && this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.region == "reu1" && this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
           		
           		<h4 className="text-left">REU1 Move</h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				      <th scope="col">Id</th>
					  <th scope="col">Region</th>
					  <th scope="col">User Ids</th>
					  <th scope="col">Source</th>
					  <th scope="col">Destination</th>
					  <th scope="col">Move Time (Local Time)</th>
					  <th scope="col">Timezone</th>
					  <th scope="col">Days</th>
					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.region === 'reu1' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.region}</td>
									<td className="col tr">{entry.userids}</td>
									<td className="col tr">{entry.source}</td>
									<td className="col tr">{entry.destination}</td>
									<td className="col tr">{entry.move_time}</td>
									<td className="col tr">{entry.timezone}</td>
									<td className="col tr">{entry.days}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-move-user",
												state: {id: entry.id, 
														lbRegion: entry.region,
														userIds: entry.userids,
														source: entry.source,
														destination: entry.destination,
														moveTime: entry.move_time,
														timezone: entry.timezone,
														days: this.getDays(entry.days),
														tableName: "move_user_schedule"}
										    }}

								 >Edit</Link> </button> </td>
								 
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Move these users?')){this.moveEntry(entry.region, entry.userids, entry.destination)};}}>Move</button> </td>
								 
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
          
          		{this.state.region == "rna1" && this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.region == "rna1" && this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
           
           		<h4 className="text-left">RNA1 Move</h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				      <th scope="col">Id</th>
					  <th scope="col">Region</th>
					  <th scope="col">User Ids</th>
					  <th scope="col">Source</th>
					  <th scope="col">Destination</th>
					  <th scope="col">Move Time (Local Time)</th>
					  <th scope="col">Timezone</th>
					  <th scope="col">Days</th>
					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.region === 'rna1' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.region}</td>
									<td className="col tr">{entry.userids}</td>
									<td className="col tr">{entry.source}</td>
									<td className="col tr">{entry.destination}</td>
									<td className="col tr">{entry.move_time}</td>
									<td className="col tr">{entry.timezone}</td>
									<td className="col tr">{entry.days}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-move-user",
												state: {id: entry.id, 
														lbRegion: entry.region,
														userIds: entry.userids,
														source: entry.source,
														destination: entry.destination,
														moveTime: entry.move_time,
														timezone: entry.timezone,
														days: this.getDays(entry.days),
														tableName: "move_user_schedule"}
										    }}

								 >Edit</Link> </button> </td>
								 
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Move these users?')){this.moveEntry(entry.region, entry.userids, entry.destination)};}}>Move</button> </td>
								 
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
           
           		{this.state.region == "rna2" && this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.region == "rna2" && this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
           		
           		<h4 className="text-left">RNA2 Move</h4>
				<table className="table table-striped table-hover table-gap">
				  <thead className="table-dark">
					<tr>
				      <th scope="col">Id</th>
					  <th scope="col">Region</th>
					  <th scope="col">User Ids</th>
					  <th scope="col">Source</th>
					  <th scope="col">Destination</th>
					  <th scope="col">Move Time (Local Time)</th>
					  <th scope="col">Timezone</th>
					  <th scope="col">Days</th>
					  <th scope="col" colSpan="3">Actions</th>
					</tr>
				  </thead>
				  
				  <tbody>		
				
					{this.state.api_data && this.state.api_data.map((entry) => {
						
						return (entry.region === 'rna2' && <tr key={entry.id}>
									<td className="col tr">{entry.id}</td>
									<td className="col tr">{entry.region}</td>
									<td className="col tr">{entry.userids}</td>
									<td className="col tr">{entry.source}</td>
									<td className="col tr">{entry.destination}</td>
									<td className="col tr">{entry.move_time}</td>
									<td className="col tr">{entry.timezone}</td>
									<td className="col tr">{entry.days}</td>
									<td className="col tr-edit"> <button type="button" className="btn btn-dark edit-btn"> <Link 
										 to={{
												pathname: "/update-move-user",
												state: {id: entry.id, 
														lbRegion: entry.region,
														userIds: entry.userids,
														source: entry.source,
														destination: entry.destination,
														moveTime: entry.move_time,
														timezone: entry.timezone,
														days: this.getDays(entry.days),
														tableName: "move_user_schedule"}
										    }}

								 >Edit</Link> </button> </td>
								 
								 	<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Move these users?')){this.moveEntry(entry.region, entry.userids, entry.destination)};}}>Move</button> </td>
								 
									<td className="col tr-delete"> <button type="button" className="btn btn-dark" onClick={() => {if(window.confirm('Delete the entry?')){this.deleteEntry(entry.id)};}}>Delete</button> </td>	
							   </tr>)		
						 
					})}
					
					</tbody>
				</table>
           		
            </div>
        );
    }
}

export default MoveUserSchedule;
