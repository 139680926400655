import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';
import Chart from 'chart.js';


class AnalyticsSummaryPage extends Component {
	constructor(props) {
    super(props);

    this.chartRef_rna1 = React.createRef();
    this.chartRef_rna2 = React.createRef();
    this.chartRef_reu1 = React.createRef();
    this.chartRef_roc2 = React.createRef();

    this.state = {
		submit: false,
		history: 1,
		data_rna1: {
			labels: [1, 2, 3, 4, 5],
			datasets: [
				{
				label: "Avg. Load %",
				borderColor: "rgba(255, 0, 255, 0.75)",
				backgroundColor: "rgba(255, 0, 255, 0)",
				data: []
				},
				{
				label: "Avg. Users",
				borderColor: "rgba(255, 0, 0, 0.75)",
				backgroundColor: "rgba(255, 0, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Avg. Meetings",
				borderColor: "rgba(0, 0, 255, 0.75)",
				backgroundColor: "rgba(0, 0, 255, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Num. of Servers",
				borderColor: "rgba(0, 255, 0, 0.75)",
				backgroundColor: "rgba(0, 255, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Total Users",
				borderColor: "rgba(0, 0, 0, 0.75)",
				backgroundColor: "rgba(0, 0, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Total Meetings",
				borderColor: "rgba(0, 255, 175, 0.75)",
				backgroundColor: "rgba(0, 255, 175, 0)",
				data: [],
				hidden: true
				}
			]
		},
		data_reu1: {
			labels: [1, 2, 3, 4, 5],
			datasets: [
				{
				label: "Avg. Load",
				borderColor: "rgba(255, 0, 255, 0.75)",
				backgroundColor: "rgba(255, 0, 255, 0)",
				data: []
				},
				{
				label: "Avg. Users",
				borderColor: "rgba(255, 0, 0, 0.75)",
				backgroundColor: "rgba(255, 0, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Avg. Meetings",
				borderColor: "rgba(0, 0, 255, 0.75)",
				backgroundColor: "rgba(0, 0, 255, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Num. of Servers",
				borderColor: "rgba(0, 255, 0, 0.75)",
				backgroundColor: "rgba(0, 255, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Total Users",
				borderColor: "rgba(0, 0, 0, 0.75)",
				backgroundColor: "rgba(0, 0, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Total Meetings",
				borderColor: "rgba(0, 255, 175, 0.75)",
				backgroundColor: "rgba(0, 255, 175, 0)",
				data: [],
				hidden: true
				}
			]
		},
		data_roc2: {
			labels: [1, 2, 3, 4, 5],
			datasets: [
				{
				label: "Avg. Load",
				borderColor: "rgba(255, 0, 255, 0.75)",
				backgroundColor: "rgba(255, 0, 255, 0)",
				data: []
				},
				{
				label: "Avg. Users",
				borderColor: "rgba(255, 0, 0, 0.75)",
				backgroundColor: "rgba(255, 0, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Avg. Meetings",
				borderColor: "rgba(0, 0, 255, 0.75)",
				backgroundColor: "rgba(0, 0, 255, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Num. of Servers",
				borderColor: "rgba(0, 255, 0, 0.75)",
				backgroundColor: "rgba(0, 255, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Total Users",
				borderColor: "rgba(0, 0, 0, 0.75)",
				backgroundColor: "rgba(0, 0, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Total Meetings",
				borderColor: "rgba(0, 255, 175, 0.75)",
				backgroundColor: "rgba(0, 255, 175, 0)",
				data: [],
				hidden: true
				}
			]
		},
		data_rna2: {
			labels: [1, 2, 3, 4, 5],
			datasets: [
				{
				label: "Avg. Load",
				borderColor: "rgba(255, 0, 255, 0.75)",
				backgroundColor: "rgba(255, 0, 255, 0)",
				data: []
				},
				{
				label: "Avg. Users",
				borderColor: "rgba(255, 0, 0, 0.75)",
				backgroundColor: "rgba(255, 0, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Avg. Meetings",
				borderColor: "rgba(0, 0, 255, 0.75)",
				backgroundColor: "rgba(0, 0, 255, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Num. of Servers",
				borderColor: "rgba(0, 255, 0, 0.75)",
				backgroundColor: "rgba(0, 255, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Total Users",
				borderColor: "rgba(0, 0, 0, 0.75)",
				backgroundColor: "rgba(0, 0, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Total Meetings",
				borderColor: "rgba(0, 255, 175, 0.75)",
				backgroundColor: "rgba(0, 255, 175, 0)",
				data: [],
				hidden: true
				}
			]
			}
		}
	}
	componentDidMount() {
	    this.renderChart_rna1();
	    this.renderChart_rna2();
	    this.renderChart_reu1();
	    this.renderChart_roc2();
	  }

	  componentDidUpdate() {
	    this.renderChart_rna1();
	    this.renderChart_rna2();
	    this.renderChart_reu1();
	    this.renderChart_roc2();
	  }

	  renderChart_rna1() {
	    const chartElement = this.chartRef_rna1.current;
	    const ctx = chartElement.getContext('2d');

	    new Chart(ctx, {
	      type: 'line',
	      data: this.state.data_rna1,
	    });
	  }

	  renderChart_reu1() {
	    const chartElement = this.chartRef_reu1.current;
	    const ctx = chartElement.getContext('2d');

	    new Chart(ctx, {
	      type: 'line',
	      data: this.state.data_reu1,
	    });
	  }

	  renderChart_rna2() {
	    const chartElement = this.chartRef_rna2.current;
	    const ctx = chartElement.getContext('2d');

	    new Chart(ctx, {
	      type: 'line',
	      data: this.state.data_rna2,
	    });
	  }

	  renderChart_roc2() {
	    const chartElement = this.chartRef_roc2.current;
	    const ctx = chartElement.getContext('2d');

	    new Chart(ctx, {
	      type: 'line',
	      data: this.state.data_roc2,
	    });
	  }

	changeHandler = (e) => {

		var stateObject = {};
		stateObject[e.target.name] = e.target.value;
		stateObject['data_rna2'] = stateObject['data_roc2'] = stateObject['data_reu1'] = stateObject['data_rna1'] = {
			labels: [1, 2, 3, 4, 5],
			datasets: [
				{
				label: "Avg. Load %",
				borderColor: "rgba(255, 0, 255, 0.75)",
				backgroundColor: "rgba(255, 0, 255, 0)",
				data: []
				},
				{
				label: "Avg. Users",
				borderColor: "rgba(255, 0, 0, 0.75)",
				backgroundColor: "rgba(255, 0, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Avg. Meetings",
				borderColor: "rgba(0, 0, 255, 0.75)",
				backgroundColor: "rgba(0, 0, 255, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Num. of Servers",
				borderColor: "rgba(0, 255, 0, 0.75)",
				backgroundColor: "rgba(0, 255, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Total Users",
				borderColor: "rgba(0, 0, 0, 0.75)",
				backgroundColor: "rgba(0, 0, 0, 0)",
				data: [],
				hidden: true
				},
				{
				label: "Total Meetings",
				borderColor: "rgba(0, 255, 175, 0.75)",
				backgroundColor: "rgba(0, 255, 175, 0)",
				data: [],
				hidden: true
				}
			]
		}
		this.setState(stateObject);

		
	}
	
	getData = async(rgn) => {
		var tableObj = {region: rgn}
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;

		let axiosConfig = {
		  headers: {
			  "Content-Type": 'application/json',
			  "X-API-Key": api_key,
			  //"Access-Control-Allow-Headers" : "*",
			  //"Access-Control-Allow-Origin" : "*",
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}

		//const url = 'https://den4v37b8f.execute-api.us-east-2.amazonaws.com/spinner2/controller/get-analytics';
		const url = api_url + "get-analytics"
		//const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/get-analytics';
    	//const apiData = await axios.post(url, tableObj)
		const apiData = await axios.post(url, tableObj, axiosConfig)
      	.then((data) =>
			//console.log(data['data']['body'])
			//JSON.parse(data['data']['body'])
			data['data']
		);
		
		console.log(apiData);
		
		var region;
		var num_region;
		var history_check = parseInt(this.state.history)*120;
		
		if(apiData.region.length === 0){
			region = null;
			num_region = 0;	
		} else {
			region = apiData.region;
			num_region = apiData.region.length;
			if(num_region > history_check) {
				region = region.slice(0, history_check);
			}
		}		
		
		var labels = [];
		var avg_load = [];
		var avg_users = [];
		var avg_meetings = [];
		var num_servers = [];
		var total_users = [];
		var total_meetings = [];

		for (const ele of region) {
			labels.push(ele["created_at"]);
			avg_load.push(ele["avg_load"]);
			avg_users.push(ele["avg_users"]);
			avg_meetings.push(ele["avg_meetings"]);
			num_servers.push(ele["servers"]);
			total_users.push(ele["total_users"]);
			total_meetings.push(ele["total_meetings"]);
		}
		
		var stateObject = {};
		var data_name = `data_${rgn}`;
		stateObject[data_name] = {
							labels: labels.reverse(),
							datasets: [
								{
								label: "Avg. Load %",
								borderColor: "rgba(255, 0, 255, 0.75)",
								backgroundColor: "rgba(255, 0, 255, 0)",
								data: avg_load.reverse()
								},
								{
								label: "Avg. Users",
								borderColor: "rgba(255, 0, 0, 0.75)",
								backgroundColor: "rgba(255, 0, 0, 0)",
								data: avg_users.reverse(),
								hidden: true
								},
								{
								label: "Avg. Meetings",
								borderColor: "rgba(0, 0, 255, 0.75)",
								backgroundColor: "rgba(0, 0, 255, 0)",
								data: avg_meetings.reverse(),
								hidden: true
								},
								{
								label: "Num. of Servers",
								borderColor: "rgba(0, 255, 0, 0.75)",
								backgroundColor: "rgba(0, 255, 0, 0)",
								data: num_servers.reverse(),
								hidden: true
								},
								{
								label: "Total Users",
								borderColor: "rgba(0, 0, 0, 0.75)",
								backgroundColor: "rgba(0, 0, 0, 0)",
								data: total_users.reverse(),
								hidden: true
								},
								{
								label: "Total Meetings",
								borderColor: "rgba(0, 255, 175, 0.75)",
								backgroundColor: "rgba(0, 255, 175, 0)",
								data: total_meetings.reverse(),
								hidden: true
								}
							]
						};
		
		this.setState(stateObject);
	}
	
	submitHandler = async(e) => {
		e.preventDefault();
		this.setState({
			submit: true
		})
		
		this.getData("rna1");
		
		this.getData("reu1");
		
		this.getData("roc2");
		
		this.getData("rna2");
	}
	
	render() {
		const {history} = this.state;
		
		return(
			<div className="pageBody">
				<Navbar />

				<div className="container-fluid">
					<h1>Region Analytics Summary</h1>
					
					<form onSubmit={this.submitHandler} className="container">
						<div className="form-group">
							<label>History to show in days</label>
							<select name="history" className="form-control" id="history"  value={history} onChange={this.changeHandler}>
								<option value="" selected disabled hidden>choose history in days</option>
								<option>1</option>
								<option>2</option>
								<option>3</option>
								<option>4</option>
								<option>5</option>
								<option>6</option>
								<option>7</option>
							</select>
						</div>
						<button type="submit" className="btn btn-primary">Submit</button>
					</form>
					
					<div className="chart">
						<div className="row row1">
						
							<div className="col-6 chart-rna1 border">
								<div className="chart">
					              <h4>RNA1</h4>
					              <canvas ref={this.chartRef_rna1} />
					            </div>
							</div>

							<div className="col-6 chart-reu1 border">
								<div className="chart">
					              <h4>REU1</h4>
					              <canvas ref={this.chartRef_reu1} />
					            </div>
							</div>
						</div>
						
						<div className="row row2">
							<div className="col-6 chart-roc2 border">
								<div className="chart">
					              <h4>ROC2</h4>
					              <canvas ref={this.chartRef_roc2} />
					            </div>
							</div>

							<div className="col-6 chart-rna2 border">
								<div className="chart">
								  <h4>Rna2</h4>
					              <canvas ref={this.chartRef_rna2} />
					            </div>
							</div>
						</div>
					</div>
					
				</div>


			</div>
		)
	}
}

export default AnalyticsSummaryPage;