import React, { Component } from 'react';
import Navbar from '../../components/Nav'
import axios from 'axios';

class StatusPage extends Component {
	state = {
		api_data: [],
		difference: null,
		status: null
	}
	
	async componentDidMount() {
		const url = 'https://12hb85n3h6.execute-api.us-east-2.amazonaws.com/test/status';
    	const apiData = await axios.post(url)
      	.then((data) =>
			//console.log(data['data']['body'])	
			JSON.parse(data['data']['body'])
		);	
		var data = JSON.parse(apiData);
		this.setState({ api_data: data});
		var data_time = this.state.api_data["updated_at"];
		var now = new Date().toISOString();
		console.log(data_time);
		console.log(now);
		
		var d1 = new Date(data_time);
		var d2 = new Date(now);
		var difference = (d2 - d1) / 60e3;
		
		if(difference > 15) {
			this.setState({
				difference: difference,
				status: false
			})
		} else {
			this.setState({
				difference: difference,
				status: true
			})
		}
		
	}
	
	render() {
		const {difference, status} = this.state
		return(
			<div className="pageBody">
				<Navbar />
				<h3 className="text-center">Status Page</h3>
				<hr></hr>
				<div className="container status-body">
					{this.state.status && 
						<div className="row">
							<div className="col-4"></div>
							<div className="col-1 alert alert-success">Running</div>
							
							<div className="col-3">
								<h5 className="text-left">Last updated {Math.floor(this.state.difference)} mins ago</h5>
							</div>
							<div className="col-4"></div>
						</div>
					}
					
					{!this.state.status && 
						<div className="row">
							<div className="col-4"></div>
							<div className="col-1 alert alert-danger">Down</div>
							
							<div className="col-3">
								<h5 className="text-left">Last updated {Math.floor(this.state.difference)} mins ago</h5>
							</div>
							<div className="col-4"></div>
						</div>
					}
				</div>
			</div>
		);
	}
}

export default StatusPage;