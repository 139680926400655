import React, { Component } from 'react';
import Navbar from '../components/Nav'
import axios from 'axios';

class CreateDailyCheck extends Component {
	state = {
			lbRegion: '',
			group: "",
			number: "",
			isEnable: true,
			tableName: "daily_checks",
			submit: false
	}
	
	changeHandler = (e) => {
		var stateObject = {};
		stateObject[e.target.name] = e.target.value;
		this.setState(stateObject);
	}
	
	submitHandler = (e) => {
		e.preventDefault();
		this.setState({
			submit: true
		})
		
		let api_key = process.env.REACT_APP_SPINNER2_API_KEY;
		let api_url = process.env.REACT_APP_CONTROLLER_API_URL;
		
		let axiosConfig = {
		  headers: {
			  "Content-Type": "application/json",
			  "X-API-Key": api_key
		  },
		}
		axios.defaults.headers.common = {
		  "X-API-Key": api_key,
		}
		const url = api_url + "create-tabledata"
		axios.post(url, this.state, axiosConfig)
		.then(response => {
			response['data']['errorMessage'] ? this.setState({ response: false,responseText: response['data']['errorMessage'] }) : this.setState({ response: true, responseText: response['data']['data'] });						
		})
		
		this.setState({
			lbRegion: '',
			group: "",
			number: "",
			isEnable: true,
			tableName: "daily_checks",
			submit: false
		})
		
	}
	
	render() {
		const {group, lbRegion, number, isEnable} = this.state
		return(
			<div className="pageBody">
				<Navbar />
				<h3>Create new entry for the Daily Check Table</h3>

				<form onSubmit={this.submitHandler} className="container">
					  
					  <div className="form-group">
						<label>Region</label>
						<select name="lbRegion" className="form-control" id="lbRegion"  value={lbRegion} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>choose LB</option>
							<option>rna1</option>
							<option>reu1</option>
							<option>roc2</option>
							<option>rna2</option>
						</select>
					  </div>

					  <div className="form-group">
						<label>Group</label>
						<input type="text" name="group" className="form-control" id="group" placeholder="Group name" value={group} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Number</label>
						<input type="text" name="number" className="form-control" id="number" placeholder="Number" value={number} onChange={this.changeHandler}></input>
					  </div>
					  
					  <div className="form-group">
						<label>Enable?</label>
						<select name="isEnable" className="form-control" id="isEnable"  value={isEnable} onChange={this.changeHandler}>
							<option value="" selected disabled hidden>enable this launch?</option>
							<option>true</option>
							<option>false</option>
						</select>
					  </div>

					{group && lbRegion && number && <button type="submit" className="btn btn-primary">Submit</button>}

				</form>
				
				{this.state.submit && this.state.response && <p className="alert alert-success"> Response: {this.state.responseText}</p>}
				{this.state.submit && !this.state.response && <p className="alert alert-danger"> Response: {this.state.responseText} ...try again in 60 seconds</p>}
			</div>
		);
	}
}

export default CreateDailyCheck;
